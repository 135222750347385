import { InputAdornment, SvgIcon, TextField, Dialog } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import filterIcon from "../../images/filter.svg"
import refreshIcon from "../../images/Refresh Data.svg";
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";

const FilterComponent = ({ count = 0, title = 'Test Cases', setSearchText, setFilterOpen, filter = false, refresh = false,
    filterComponent = null, closeDialog, children, clearInput }) => {

    const [open, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const {t} = useTranslation();

    const handleClose = () => {
        setFilterOpen && setFilterOpen(false);
        setOpen(false);
    }

    useEffect(() => {
        if (closeDialog) {
            setOpen(false);
        }

    }, [closeDialog]);

    useEffect(() => {
        setSearchText && setSearchText('')
        setSearchInput('');
    }, [clearInput])
    return (
        <div className={"toolbar"}>
            <TextField
                fullWidth
                placeholder={`${t("searchAmong")} ${count} ${title}`}
                size='small'
                type='search'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value?.toLowerCase())}
                InputProps={{
                    startAdornment: <InputAdornment position='start'>
                        <SvgIcon fontSize='small' color='action'>
                            <SearchIcon size={20} />
                        </SvgIcon>
                    </InputAdornment>,
                    endAdornment: <div style={{ display: "flex", alignItems: "center", cursor: 'pointer', paddingRight: '10px' }}>
                        {refresh && (
                            <img src={refreshIcon} width="40px" alt="pic" className='pointer'
                                onClick={() => setSearchText && setSearchText(searchInput)} />
                        )}

                        {filter && (
                            <img src={filterIcon} style={{ width: '16px', cursor: "pointer" }} onClick={() => { setFilterOpen && setFilterOpen(true); setOpen(true) }} />
                        )}

                        {filterComponent && (
                            <Dialog
                                onClose={handleClose}
                                aria-labelledby="simple-dialog-title"
                                open={open}
                                BackdropProps={{ style: { backgroundColor: "transparent" } }}
                                PaperProps={{ sx: { width: "450px", height: "345px", position: "fixed", top: 220, right: 120, m: 2, p: 2, maxWidth: 'md' } }}>
                                {filterComponent}
                            </Dialog>
                        )}
                    </div>
                }}

            />

            {!isMobile && (
                <>
                    {children}
                </>
            )}
        </div>
    )
}


export default FilterComponent; 
