export const ENABLE_REDUX_DEV_TOOLS = true;

export const DASHBOARD_SELECTIONS_ITEMS = {
    VOICE: 'Voice',
    DIGITAL: 'Digital'
}

export const CHANNELS = {
    VOICE : 'voice',
    DIGITAL : 'web',
}