import { Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch } from "react-redux";
import { toggleGlobalAlert } from "../../actions";
import { isMobile } from 'react-device-detect';

const SuccessAlert = ({ openAlert, setOpenAlert, statement, fail = "success", width = '90%', top = "65px" }) => {
    const dispatch = useDispatch();

    return (
        <Box sx={{ ml: isMobile ? '3%': 0, width: width, pl: isMobile ? 0 : 30, position: "absolute", top: top, zIndex: 99 }}>
            <Collapse in={openAlert}>
                <Alert
                    severity={fail}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenAlert && setOpenAlert(false);
                                toggleGlobalAlert('')(dispatch)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 0, color: fail === "success" ? "green" : "red" }}

                    iconMapping={{
                        success: <CheckCircleOutlineIcon fontSize="inherit"
                            style={{
                                width: "100px",
                                height: "45px",
                                backgroundColor: fail === "success" ? "green" : "red",
                                color: "lightgray",
                                margin: "-10px 0 -30px -15px",
                                padding: "5px"
                            }} />,
                        error: <CloseIcon style={{
                            width: "100px",
                            height: "45px",
                            backgroundColor: fail === "success" ? "green" : "red",
                            color: "lightgray",
                            margin: "-10px 0 -30px -15px",
                            padding: "5px"
                        }} />
                    }}
                >
                    <span style={{ fontWeight: "bold", textTransform: 'capitalize' }}>{fail}</span>
                    {fail === "success" ? (
                        <>
                            {statement}
                        </>
                    ) : (
                        <>
                            {(statement || []).map((m, index) => (
                                <div key={`error_${index}`}>{m}</div>
                            ))}
                        </>
                    )}
                </Alert>
            </Collapse>
        </Box>
    )
}

export default SuccessAlert