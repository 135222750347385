import { Grid } from "@mui/material";
import LeftBar from "../dashboard/LeftBar";
import Navbar from "../dashboard/Navbar";
import EditUser from "./Edit";
import { isMobile } from 'react-device-detect';

const UpsertUser = () => {
    const defaultPage = [0, 0, 0, 1]
    return (
        <div>
            <Navbar />
            <Grid container>
                {!isMobile && (
                    <Grid item>
                        <LeftBar page={defaultPage} />
                    </Grid>
                )}
                <Grid item>
                    <EditUser />
                </Grid>
            </Grid>
        </div>
    )

}

export default UpsertUser;