import React from "react";
import Navbar from "../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../dashboard/LeftBar";

const Setting = () => {
    const defaultPage = [0, 0, 0, 1]
    return (
        <div>
            <Navbar/>
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <div>
                        setting list
                    </div>

                </Grid>
            </Grid>
        </div>
    );
};

export default Setting;
