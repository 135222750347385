import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    "&.Mui-selected": {
                        backgroundColor: "#E8F3F4",
                    }
                }
            }
        }
    }
});
