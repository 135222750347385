import React from 'react';
import { makeStyles } from "@mui/styles";
import { Divider, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import _ from "lodash"
import CheckIcon from "@mui/icons-material/Check";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        }
    },
}))

const CustomFooter = (page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage) => {
    const allRows = typeof rows === 'number' ? rows : rows.length
    const pageCount = Math.ceil(allRows / rowPerPage)
    const classes = useStyles()
    const rowsPerPage = [10, 20, 50, 100]

    return (
        <Grid container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
            className={classes.root}>

            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item display={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: "14px", color: "#161616" }}>items per page: </Typography>
                    <Select
                        value={rowPerPage}
                        style={{ fontSize: 14 }}
                        onChange={(e) => {
                            setRowPerPage(e.target.value)
                            setPage(1)
                        }}
                    >
                        {rowsPerPage.map((t, i) => <MenuItem key={i} value={t}
                        >
                            {t}</MenuItem>)}
                    </Select>
                </Grid>


                <Divider sx={{ height: 28, mr: 0.5 }} orientation="vertical" />
                <Grid item>
                    <Typography sx={{ fontSize: "14px", color: "#BDBDBD" }}> {(page - 1) * rowPerPage + 1} - {page * rowPerPage > allRows ? allRows : page * rowPerPage} of {allRows} items </Typography>
                </Grid>
            </Grid>


            <Grid item>
                <Grid container alignItems='center'>

                    <>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl>
                                <Select
                                    style={{ fontSize: 14 }}
                                    value={page}
                                    onChange={(e) => handlePageChange(e, e.target.value)}
                                >
                                    {_.range(1, pageCount + 1).map((t, i) => <MenuItem key={i} value={t}>
                                        {t}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                            <Grid item>
                                <Typography sx={{ fontSize: "14px", color: "#161616" }}>of {pageCount} {pageCount > 1 ? "pages" : "page"}</Typography>
                            </Grid>
                        </Grid>
                    </>
                    <Grid item>
                        <ArrowLeftIcon style={{ cursor: "pointer" }} onClick={(e) => handlePageChange(e, page - 1 > 1 ? page - 1 : 1)} />
                        <ArrowRightIcon style={{ cursor: "pointer" }} onClick={(e) => handlePageChange(e, page < pageCount ? page + 1 : pageCount)} />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default CustomFooter
