import { Box, Typography, Grid, Chip, Button } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LeftBar from "../../dashboard/LeftBar";
import Navbar from "../../dashboard/Navbar";
import ContentHeader from "../../mui/ContentHeader";
import CustomFooter from "../../mui/CustomFooter";
import FilterComponent from "../../mui/FilterComponent";
import { useHistory } from "react-router-dom";
import { formatDate, formatDateTime, getDate } from "../../../helper/date";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile, getRuns } from "../../../actions";
import downloadIcon from "../../../images/Download Icon.svg";
import { useTranslation } from "react-i18next";

const DrillDownReport = () => {
    const { id, status, start, end, reportId, latency } = useParams();
    const dispatch = useDispatch();
    const defaultPage = [0, 1, 0, 0];
    const apiRef = useGridApiRef();
    const [rowPerPage, setRowPerPage] = useState(10);
    const [page, setPage] = useState(1);
    let history = useHistory();
    const runs = useSelector(state => state.reportReducer.runs);
    const statuses = useSelector(state => state.reportReducer.alertStatuses);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [closeFilter, setCloseFilter] = useState(false);
    const [fullTextSearch, setFullTextSearch] = useState('');
    const { t } = useTranslation();
    const convertor = {
        'success': 'Passed',
        'warning': 'Warning',
        'failure': 'Failed',
        [undefined]: 'Total',
    };

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const rows = useMemo(() => {
        let filter = filterStatus && filterStatus.length > 0 ? (runs || []).filter(r => filterStatus.map(s => s.id).includes(r.runStatusId)) :
            (status === "*" ? runs || [] : (runs || []).filter(r => status === 'TOTAL' || r.runStatusId === statuses?.find(s => s.code === status || s.code === status.replace('STOPPED','FAIL'))?.id));

        if (fullTextSearch) {
            filter = filter.filter(r => r.runId.toString().indexOf(fullTextSearch) !== -1 ||
                r.testCaseName.toLowerCase().indexOf(fullTextSearch) !== -1);
        }

        return filter.map(run => ({
            ...run,
            id: run.runId,
            runTime: formatDateTime(run.runTime, 'yyyy/MM/dd HH:mm:ss'),
            runDuration: `${(+run.runDuration)?.toFixed(2)} sec`,
            status: !latency ? statuses.find(s => s.id === +run.runStatusId)?.text : convertor[latency],
        }))


    }, [runs, status, filterStatus, fullTextSearch])

    const columns = [
        { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true },
        {
            field: "runId",
            headerName: t("runId.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "testCaseName",
            headerName: t("testCaseName.1"),
            editable: true,
            flex: 1,
            width: '200',
            disableColumnMenu: true,
            renderCell: params => (
                <div onClick={() => document.location.href = `/report/run/${params.row.id}`}>{params.row.testCaseName}</div>

            )

        },
        {
            field: "runTime",
            headerName: t("dateTime.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "runDuration",
            headerName: t("duration.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: t("detailedStatus"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "ACTIONS",
            editable: true,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => (
                <img src={downloadIcon} alt="Download" className="pointer" onClick={() => downloadFile(params.row.runId, 'wcr')} />
            )
        },
    ];


    const handleChangeType = (type) => {
        if (!selectedStatus.find(s => s.id === type.id)) {
            setSelectedStatus([...selectedStatus, type])
        } else {
            setSelectedStatus(selectedStatus.filter(s => s.id !== type.id));
        }
    };

    const Filter = () => {

        return (
            <>
                <Typography variant="subtitle1">
                    <div style={{ fontSize: 18, fontWeight: 500 }}>Filters</div>
                </Typography>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <Box>
                        <Typography variant="subtitle1">
                            <div style={{ fontSize: 14 }}>Status &nbsp;&nbsp;
                                <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {selectedStatus.length > 0 ? `${selectedStatus.length} SELECTED` : ''} </span>
                            </div>

                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '400px', marginTop: 1 }}>
                            {[{ name: 'Failure', id: 7 }, { name: 'Pass', id: 3 }, { name: 'Warning', id: 4 }].map((value, index) => (
                                <Chip key={index} label={value.name}
                                    sx={{
                                        fontWeight: "bold",
                                        background: selectedStatus.find(d => d.id === value.id) ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                        cursor: 'pointer'
                                    }} onClick={() => handleChangeType(value)} />
                            )
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box textAlign='left'
                    sx={{
                        width: "100%",
                        display: 'flex',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        sx={{ mt: 1, mb: 5, ml: 2 }}
                        style={{
                            borderRadius: 20,
                            background: '#3F929E1A 0% 0% no-repeat padding-box',
                            height: 38,
                            width: '93px',
                            color: '#1F9997',

                        }}
                        onClick={reset}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "#1f9997" }}>Reset</Typography>
                    </Button>

                    <Button
                        sx={{ mt: 1, mb: 5, mr: 2 }}
                        style={{
                            height: 40,
                            borderRadius: 20,
                            width: 135,
                            background: '#1F9997 0% 0% no-repeat padding-box',
                        }}
                        onClick={applyFilters}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "white" }}>Apply filters</Typography>
                    </Button>
                </Box>
            </>
        )
    }
    const reset = () => {
        setSelectedStatus([]);
        setFilterStatus([]);
    }

    const applyFilters = () => {
        setFilterStatus(selectedStatus);
        setCloseFilter(new Date().getTime());
    }

    const setSearchText = (input) => {
        setFullTextSearch(input);
    }

    useEffect(() => {
        if (id) {
            getRuns(id, getDate(start), getDate(end), reportId, latency)(dispatch);
        }

    }, [id]);

    return (
        <div>
            <Navbar />
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage} />
                </Grid>
                <Grid item>
                    <div style={{ position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
                        <ContentHeader title={t("reportsViewReportTestResults")} />

                        <Box textAlign='left'
                            sx={{
                                minWidth: "500px",
                                borderColor: 'lightgrey',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: '#1F9997',
                                marginBottom: '5px',
                                verticalAlign: 'middle'
                            }}
                        >
                            <Typography type="body2" className="edit-title">
                                {t("testCasePerformance.0")}
                            </Typography>

                        </Box>

                        <Box sx={{ mt: 5, ml: 3 }}>
                            <Grid container>
                                <Grid xs={4}>
                                    <Typography variant="h7">{t("testCaseID")}:</Typography>
                                    <Typography variant="h8">#{runs?.[0]?.testCaseId}</Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <Typography variant="h7">{t("testCaseName.0")}:</Typography>
                                    <Typography variant="h8">{runs?.[0]?.testCaseName}</Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("status")}:</Typography>
                                    <Typography variant="h8" className="capitalize">{status === '*' ? convertor[latency] : status.toLowerCase()}</Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("from")}:</Typography>
                                    <Typography variant="h8">{formatDate(start, 'yyyy/MM/dd')}</Typography>
                                    <Typography variant="h7" sx={{ ml: 1 }}>To:</Typography>
                                    <Typography variant="h8">{formatDate(end, 'yyyy/MM/dd')}</Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 2, textAlign: 'right' }}>
                                    <Typography variant="h7">{t("testCaseRuns")}:</Typography>
                                    <Typography variant="h8" className="capitalize">{rows?.length}</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <FilterComponent
                                setSearchText={setSearchText}
                                closeDialog={closeFilter} count={rows?.length} title={t("testRuns")} filter={status === 'TOTAL'} refresh={true}
                                filterComponent={(<Filter />)}></FilterComponent>
                        </Box>

                        <Box sx={{ mt: 1, height: "550px" }}>
                            <DataGrid rows={rows} columns={columns}
                                apiRef={apiRef}
                                sortingOrder={['desc', 'asc']}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                pageSize={rowPerPage}
                                page={page - 1}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'asc' }],
                                    },
                                }}
                                components={{
                                    Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                                }}
                                getCellClassName={(params) => {
                                    if (params.field === "testCaseName") {
                                        return 'underscore pointer'
                                    } else if (params.field === "status") {
                                        return params.formattedValue;
                                    }

                                }}

                            />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}

export default DrillDownReport;
