import {
    Grid,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        width: "100%",
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    dashboard: {
        color: "black",
        fontSize: "14px",
        fontFamily: "Verdana"
    },
}));


const Version = () => {
    const classes = useStyles();
    const version = process.env.REACT_APP_RELEASE_VERSION

    return (
        <div className={classes.container}>
            <Grid container className={classes.toolbar}>
                <Grid item>
                    <Grid item className={classes.dashboard}>
                        Version : {version}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Version;
