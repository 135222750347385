import {
    Grid, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import lensLogo from "../../images/Lens Logo Teal E.svg"
import dashboard from "../../images/dashboard_black_24dp.svg"
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
        width: "100%",
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    dashboard: {
        color: "#A1A1A1",
        fontSize: "12px",
        fontFamily: "Montserrat"
    },
    logo: {
        color: "#636364",
        opacity: 1,
        fontFamily: "Montserrat",
        display: 'flex',
        alignItems: 'center',
        paddingTop: '14px',
        paddingBottom: '30px',

        '& h3': {
            fontSize: '50px',
            height: '53.8px',
            //font: 'normal normal normal 50px/58px Montserrat',
            paddingLeft: '16px',
        }
    },
    textBottom: {
        position: 'relative',
        top: '7px',
    }
}));


const ContentHeader = ({ title, hidden = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            {!hidden && (
                <Grid container className={classes.toolbar}>
                    <Grid item xs={12} className={classes.dashboard}>
                        <img src={dashboard} width="14px" alt="dashboard" /> {title}
                    </Grid>
                    <Grid item xs={12} className={classes.logo}>
                        <img src={lensLogo} alt="Action Center" width={isMobile ? 45 : 108.12} height={isMobile ? 'auto' : 53.8} />
                        <Typography variant={isMobile ? "h5" : "h3"}
                            style={isMobile ? { paddingLeft: '8px' } : {}}>
                            {t("actionCenter")}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default ContentHeader;
