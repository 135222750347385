import React from "react";
import {makeStyles} from "@mui/styles";
import ContentHeader from "../../mui/ContentHeader";
import {useSelector} from "react-redux";
import {Box, IconButton, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import view from "../../../images/View Action Icon Component.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        "& .MuiDataGrid-row": {
            "&:nth-child(2n+1)": {backgroundColor: "#F9F9F9"},
            maxHeight: 'none !important',
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#FFFFFF",
            fontSize: 12
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important'
        },
        '& .MuiDataGrid-cell': {
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
            fontSize: "16px",
            padding: "15px !important"
        },
        '& .MuiDataGrid-root': {
            border: 'none !important'
        }
    },
    title: {
        color: "white",
        height: "40px",
        verticalAlign: "center",
        padding: "10px",
    },
    detail: {
        height: "60px",
        verticalAlign: "center",
        padding: "10px",
        fontSize: "18px"
    },
}));


const ViewTestResultContent = () => {
    const viewReport = "REPORTS / VIEW REPORT /TEST RESULTS"
    const classes = useStyles();

    const viewTestResult = useSelector(state => state.reportReducer.viewTestResult)
    console.log('view test result', viewTestResult)
    const columns = [

        {field: "id", headerName: "RUN ID", width: "120", disableColumnMenu: true},
        {
            field: "test_case_name",
            headerName: "TEST CASE NAME",
            width: "500",
            disableColumnMenu: true,
        },
        {
            field: "date",
            headerName: "DATE TIME",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "duration",
            headerName: "DURATION",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: "DETAILED STATUS",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "actions",
            headerName: 'ACTIONS',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div>
                        <IconButton component="span" size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleDownload(params.row.id)
                                    }
                                    }
                        >
                            <img src={view} alt="pic" width="30px"/>
                        </IconButton>

                    </div>

                </div>
            ),
        },
    ]

    const rows = viewTestResult?.map((item) => {
        return {
            id: item.entityId,
            test_case_name: item.entityName,
            date: item.date,
            duration: item.duration,
            status: item.status

        }
    })

    const handleDownload = (id) => {
        console.log('result id', id)
    }

    return (
        <div className={classes.root}
             style={{position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)`}}>
            <ContentHeader title={viewReport}/>
            <Box textAlign='left'
                 sx={{
                     minWidth: "500px",
                     borderColor: 'lightgrey',
                     borderRadius: '5px',
                     borderWidth: '1px',
                     borderStyle: 'solid',
                     backgroundColor: '#1F9997',
                     marginBottom: '20px'
                 }}
            >
                <Typography type="body2" className={classes.title} style={{fontSize: "18px", fontWeight: "bold"}}>
                    Test Case Performance
                </Typography>
            </Box>

            <Box textAlign='left'
                 sx={{
                     minWidth: "500px",
                     borderColor: 'lightgrey',
                     marginBottom: '20px',
                     display: 'flex',
                     flexDirection: 'column'
                 }}
            >
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <Typography className={classes.detail}
                                style={{fontSize: "18px", fontWeight: "bold", width: "400px"}}>
                        <span style={{fontWeight: "normal"}}>Test Case ID: </span>#16841
                    </Typography>

                    <Typography className={classes.detail} style={{fontSize: "18px", fontWeight: "bold"}}>
                        <span style={{fontWeight: "normal"}}>Test Case Name: </span>Manulife Group Benefit -
                        877-405-1992
                    </Typography>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>

                    <Typography className={classes.detail}
                                style={{fontSize: "18px", fontWeight: "bold", width: "400px"}}>
                        <span style={{fontWeight: "normal"}}> Status: </span> Total
                    </Typography>
                    <Typography className={classes.detail}
                                style={{fontSize: "18px", fontWeight: "bold", width: "700px"}}>
                        <span style={{fontWeight: "normal"}}> From: </span> 2022/01/01 <span
                        style={{fontWeight: "normal"}}>To:</span> 2022/01/14
                    </Typography>
                    <Typography className={classes.detail} style={{fontSize: "18px", fontWeight: "bold"}}>
                        <span style={{fontWeight: "normal"}}>Test Case Runs: </span>1234
                    </Typography>
                </div>

            </Box>


            <Box style={{height: "500px", maxWidth: "100%"}}
                 sx={{
                     "& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
                         visibility: "visible",
                         width: "0 !important"
                     },
                     "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                         opacity: 0.3
                     },
                     '& .super-app-theme--total': {
                         backgroundColor: "#E8F3F4 !important"
                     },
                     '& .underscore': {
                         textDecoration: "underline",
                         color: "#03A9F4"
                     },
                     '& .pass': {
                         color: "#4CAF50"
                     },
                     '& .warning': {
                         color: "#FF7D42"
                     },
                     '& .failure': {
                         color: "#CB1919"
                     },
                 }}
            >
                {rows && rows.length > 0 ? <DataGrid rows={rows} columns={columns}
                                                     rowHeight={30}
                                                     sortingOrder={['desc', 'asc']}
                                                     components={{
                                                         Footer: () => <div/>
                                                     }}
                                                     getRowClassName={(params) => {
                                                         if (!params.id)
                                                             return `super-app-theme--total`
                                                     }}

                                                     getCellClassName={(params) => {

                                                         if (params.field === 'test_case_name') {
                                                             return 'underscore'
                                                         }
                                                         if (params.field === 'status' && params.row.status.includes('pass')) {
                                                             console.log('dd', params)
                                                             return 'pass'
                                                         }
                                                         if (params.field === 'status' && params.row.status.includes('warn')) {
                                                             return 'warning'
                                                         }
                                                         if (params.field === 'status' && params.row.status.includes('fail')) {
                                                             return 'failure'
                                                         }
                                                     }}
                /> : null}

            </Box>

        </div>

    )
}

export default ViewTestResultContent