import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Chip, MenuItem, TextField, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { merge, flatten, groupBy, uniq } from 'lodash';
import { clearCurrentGroupEdit, getChannels, getGroupById, upsertTestCaseGroup } from '../../../actions';
import FilterComponent from '../../mui/FilterComponent';
import { useTranslation } from "react-i18next";
import { channelRequired } from '../../../helper/user';
import { CHANNELS } from '../../../helper/constants';



const UpsertTestCaseGroup = ({ cancel, id }) => {
    const dispatch = useDispatch();
    const [groupName, setGroupName] = React.useState('');
    const [channelName, setChannelName] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [ivr, setIvr] = React.useState(0);
    const [submitted, setSubmitted] = React.useState(false);
    const [groupId, setGroupId] = React.useState(0);
    const [fullTextSearch, setFullTextSearch] = React.useState('');
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [closeFilter, setCloseFilter] = useState(false);
    const [preSelected, setPreSelected] = useState([]);
    const { t } = useTranslation();

    const apiRef = useGridApiRef();
    const ivrs = useSelector(state => state.reportReducer.ivrs);
    const channels = useSelector(state => state.reportReducer.channels);
    const currentGroup = useSelector(state => state.reportReducer.currentGroup);

    const columns = [{ field: "id", headerName: "ID", width: "90", disableColumnMenu: true },
    {
        field: "name",
        headerName: t("testCase"),
        flex: 1,
        editable: true,
        disableColumnMenu: true,
    },];

    const cancelForm = () => {
        setSelected([]);
        setFullTextSearch('');
        setGroupId(0);
        clearCurrentGroupEdit()(dispatch);
        cancel();
    }
    const testCases = useMemo(() => {
        let ivrsTemp = [];
        switch (channelName) {
            case CHANNELS.VOICE:
                ivrsTemp = (ivrs || []).filter(i => i.channel === CHANNELS.VOICE);
                break;
            case CHANNELS.DIGITAL:
                ivrsTemp = (ivrs || []).filter(i => i.channel === CHANNELS.DIGITAL);
                break;
            default:
                ivrsTemp = ivrs;

        }
        let result = (ivr ? ivrsTemp.find(i => +i.id === +ivr)?.testCases : flatten((ivrsTemp || []).map(c => c.testCases)))
            .filter(i => !fullTextSearch || i.name.toLowerCase().indexOf(fullTextSearch.toLowerCase()) !== -1);

        if (filterStatus.length === 1) {
            const sel = flatten(Object.values(selected));

            if (filterStatus[0] === 1) {//selected
                result = result.filter(r => sel.includes(r.id));
            } else {
                result = result.filter(r => !sel.includes(r.id));
            }
        }

        return result;
    }, [ivr, fullTextSearch, filterStatus, channelName])


    const saveChanges = () => {
        const testCases = selected[0].map(v => ({
            id: +v,
        }))


        setSubmitted(true);

        if (!Object.keys(selected).length || !groupName) {
            return;
        }

        if(channelRequired() && !channelName) {
            return;
        }

        let model = {
            name: groupName,
            channel: channelName,
            testCases,
        };

        if (groupId > 0) {
            model = {
                ...model,
                id: groupId,
            };
        }

        upsertTestCaseGroup(model, groupId)(dispatch);
        cancel();
        setSelected([]);
        setIvr(0);
        setSubmitted(false)
        setGroupId(0)
        setSearchText('');
    }

    const onSelectionModelChange = (newSelection) => {
        let update = { ...selected };
        update = {
            ...update,
            [ivr]: newSelection,
        };


        if (ivr) {
            let noIvr = [];
            Object.entries(update).map(([key, value]) => {
                if (+key !== 0) {
                    noIvr = noIvr.concat(value);
                }
            });

            update = {
                ...update,
                0: noIvr,
            };
        } else {
            update = {
                [ivr]: newSelection,
            };

            (newSelection || []).forEach(id => {
                const _ivr = ivrs.find(c => c.testCases.some(d => d.id === id))
                update = {
                    ...update,
                    [_ivr.id]: (update[_ivr.id] || []).concat(id),
                }
            });
        }

        setSelected(update);
    }

    const updateIvrSelection = useMemo(() => {
        const ids = uniq(flatten(Object.values(selected)));
        const ivr = ivrs.filter(c => c.testCases.some(d => ids.includes(d.id)))
        return ivr.length;

    }, [selected])

    const getIvrs = useMemo(() => ((ivrs || []).filter(i => i.channel === CHANNELS.VOICE)).map(v => ({
        ...v,
        name: `${v.name} (${selected[v.id]?.length || 0} of ${v.testCases.length} Test cases)`,
    })), [ivrs, selected])

    const reset = () => {
        copyPreSelected();
        setSelectedStatus([]);
        setFilterStatus([]);
    }

    const setSearchText = (input) => {
        if (!input && preSelected) {
            copyPreSelected();
        } else {
            setPreSelected(selected);
        }
        setFullTextSearch(input);

    }

    const copyPreSelected = () => {
        let result = { ...selected };

        Object.entries(preSelected).map(([key, value]) => {
            result = {
                ...result,
                [key]: (result[key] || []).concat(value),
            }
        });

        setSelected(result);
    }

    const applyFilters = () => {
        setPreSelected(selected);
        setFilterStatus(selectedStatus.map(s => s.id));
        setCloseFilter(new Date().getTime());
    }

    const handleChangeType = (type) => {
        if (!selectedStatus.find(s => s.id === type.id)) {
            setSelectedStatus([...selectedStatus, type])
        } else {
            setSelectedStatus(selectedStatus.filter(s => s.id !== type.id));
        }
    };

    const Filter = () => {
        return (
            <>
                <Typography variant="subtitle1">
                    <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
                </Typography>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <Box>
                        <Typography variant="subtitle1">
                            <div style={{ fontSize: 14 }}>{t("testCase")} &nbsp;&nbsp;
                                <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {selectedStatus.length > 0 ? `${selectedStatus.length} SELECTED` : ''} </span>
                            </div>

                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '400px', marginTop: 1 }}>
                            {[{ name: t("selected"), id: 1 }, { name: t("unselected"), id: 2 }].map((value, index) => (
                                <Chip key={value.id} label={value.name}
                                    sx={{
                                        fontWeight: "bold",
                                        background: selectedStatus.find(d => d.id === value.id) ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                        cursor: 'pointer'
                                    }} onClick={() => handleChangeType(value)} />
                            )
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box textAlign='left'
                    sx={{
                        width: "100%",
                        display: 'flex',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        sx={{ mt: 1, mb: 5, ml: 2 }}
                        style={{
                            borderRadius: 20,
                            background: '#3F929E1A 0% 0% no-repeat padding-box',
                            height: 38,
                            width: '93px',
                            color: '#1F9997',

                        }}
                        onClick={reset}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "#1f9997" }}>{t("reset")}</Typography>
                    </Button>

                    <Button
                        sx={{ mt: 1, mb: 5, mr: 2 }}
                        style={{
                            height: 40,
                            borderRadius: 20,
                            width: 135,
                            background: '#1F9997 0% 0% no-repeat padding-box',
                        }}
                        onClick={applyFilters}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "white" }}>{t("applyFilters")}</Typography>
                    </Button>
                </Box>
            </>
        )
    }

    useEffect(() => {
        if (currentGroup) {

            setGroupName(currentGroup.name);
            setChannelName(currentGroup.channel || CHANNELS.VOICE);

            let cases = {};
            Object.entries(groupBy(currentGroup.testCases, 'ivrId')).map(([key, value]) => {
                cases = {
                    ...cases,
                    [key]: value.map(c => c.id),
                };
            })
            cases = {
                ...cases,
                0: currentGroup.testCases.map(c => c.id),
            };
            setSelected(cases);
        }

    }, [currentGroup, channelName]);

    useEffect(() => {
        setGroupId(id);
        getChannels()(dispatch);
        if (id > 0) {
            getGroupById(id)(dispatch);
        }
    }, [id]);

    return (
        <div>
            <div className="flex">
                <div className="dib">
                    <TextField
                        style={{
                            width: "400px"
                        }}
                        onChange={(e) => setGroupName(e.target.value)}
                        sx={{ mt: 2 }}
                        label={t("testCaseGroupLabel.1")}
                        error={submitted && !groupName}
                        value={groupName}
                        size="small" />


                </div>

                {channelRequired() && (
                    <div className="dib">
                        <TextField
                            style={{
                                width: "200px"
                            }}
                            select
                            onChange={(e) => { setChannelName(e.target.value); setIvr(0); }}
                            label={t("channel")}
                            value={channelName}
                            error={submitted && !channelName}
                            sx={{ mt: 2 }}
                            size="small" >
                            {(channels || []).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                )}
                <div className="dib">

                    {(channelName == CHANNELS.VOICE || !channelRequired()) && (<TextField
                        select
                        error={submitted && !Object.keys(selected).length}
                        value={ivr}
                        label={t("allIVRApplications")}
                        onChange={(event) => setIvr(event.target.value)}
                        style={{ width: "400px" }}
                        size="small"
                        sx={{ mt: 2 }}
                    >
                        <MenuItem value={0}>
                            {t("allIVRApplications")}
                        </MenuItem>
                        {getIvrs.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>)}

                </div>
            </div>
            <div className="db font-14">
                {Object.keys(selected).length > 0 && (channelName == CHANNELS.VOICE) && (
                    <span>{t("testCasesSelected")}: <b>{uniq(flatten(Object.values(selected))).length} {t("from")} {updateIvrSelection} {t("IVRApplications")}</b></span>
                )}
                {Object.keys(selected).length > 0 && (channelName == CHANNELS.DIGITAL) && (
                    <span>{t("testCasesSelected")}: <b>{uniq(flatten(Object.values(selected))).length} {t("from")} {updateIvrSelection} {t("WebchatApplications")}</b></span>
                )}
            </div>
            <div className="db mt-20">
                <FilterComponent count={testCases?.length} setSearchText={setSearchText} closeDialog={closeFilter}
                    filter={true} refresh={true} filterComponent={(<Filter />)} />
            </div>

            <div className="db">
                <DataGrid rows={testCases} columns={columns} checkboxSelection
                    className='min-h-350'
                    rowHeight={30}
                    selectionModel={selected[ivr]}
                    onSelectionModelChange={onSelectionModelChange}
                    apiRef={apiRef}
                    pageSize={100}
                    page={0}
                    sortingOrder={['desc', 'asc']}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Footer: () => null,
                    }}
                />
            </div>

            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    borderRadius: '5px',
                    borderWidth: '0',
                    borderStyle: 'solid',
                    backgroundColor: '#E8F3F4',
                }}
            >
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 1, mb: 1, ml: 2 }}
                    onClick={saveChanges}
                    style={{
                        borderRadius: 7,
                        backgroundColor: "#1f9997",
                        fontSize: "14px",
                        width: "190px",
                        height: 40
                    }}
                >
                    <Typography style={{ fontFamily: 'Poppins', fontSize: 14, textTransform: 'none', color: "white" }}>{t("save")}</Typography>
                </Button>

                <Button

                >
                    <Typography style={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 600, textTransform: 'none', color: "#BDBDBD" }} onClick={() => cancelForm()}>{t("cancel")}</Typography>
                </Button>
            </Box>
        </div>
    )
}

export default UpsertTestCaseGroup;
