import React, { useState } from "react";
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography, makeStyles } from "@mui/material"
import { isMobile } from 'react-device-detect';
import digital_icon from "../../images/digital_icon.svg";
import fi_rs_phone_call from "../../images/fi-rs-phone-call.svg";
import { CheckBox } from "@mui/icons-material";
import { TestCaseGroupChannel } from "../report/test_case_group/Test-Case-Group-Channel";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { DASHBOARD_SELECTIONS_ITEMS } from "../../helper/constants";


const ContentTopbar = ({
    dashboardSelection,
    setDashboardSelection,
    classes,
    rates,
    groupName,
    checkedAll,
    checkedAllRates,
    handleChangeGroupRates,
    value,
    setValue,
    selectedRates,
    handleSelectedRates,
    groupsLists,
    handleChange,
    handleChangeGroup,
    ExampleCustomInput,
    topbarVisible,
    setTopbarVisible
}) => {
    const { t } = useTranslation();
    // const [dashboardSelection, setDashboardSelection] = useState( isMobile ? 'Digital' : 'Voice');

    const handleDashboardSelectionChange = (event) => {
        setDashboardSelection(event.target.value);
      };

      function setTopbarFixed() {
        if(window.scrollY >= 200) {
            setTopbarVisible(true);
        } else {
            setTopbarVisible(false);
        }
    }

    window.addEventListener("scroll", setTopbarFixed)

    return (
        <Grid container spacing={2}>
            {topbarVisible  && (
                <div className="container-topbar-telco">
                <Grid container spacing={2}>
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
                        <div className="dashboard-selection">
                             <FormControl style={{width: '100%'}}>
                                 <Select
                                sx={{height: '100%', border: 'none'}}
                                    value={dashboardSelection}
                                    onChange={handleDashboardSelectionChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                borderRadius: '10px',
                                                border: '1px solid var(--Tk-1, #006A68)',
                                                background: 'var(--Tk3, #CCE8E6)',
                                                width: 100
                                            }
                                        },
                                        anchorOrigin: {
                                            vertical: "center",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "center",
                                            horizontal: "center"
                                        },
                                    }}
                                >
                                    <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.DIGITAL}>
                                        <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}>
                                            <div style={{display: 'flex'}}><img src={digital_icon} alt=""/></div>
                                            <div>{t(DASHBOARD_SELECTIONS_ITEMS.DIGITAL)}</div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.VOICE}>
                                    <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}><img src={fi_rs_phone_call} alt=""/>{t(DASHBOARD_SELECTIONS_ITEMS.VOICE)}</div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            
                        </div>
                    </Grid>
                    <Grid item xs={10} style={{display: 'flex', alignItems: 'center'}}>
                        <Grid container className={classes.toolbar}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1">
                                    <div style={{ fontSize: 14, marginTop: 0 }}>{t("selectTestCaseGroups")}</div>
                                </Typography>
                                <FormControl sx={{ m: 1, ml: 0, width: '80%', margin: '0px'  }} size="small">
                                    <InputLabel id="topbar-multiple-chip-label"></InputLabel>
                                    <Select
                                        labelId="topbar-multiple-chip-label"
                                        id="topbar-multiple-chip-label"
                                        multiple
                                        value={groupName}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="topbar-select-multiple-chip" />}
                                        renderValue={(selected) => `${selected.length} of ${groupsLists?.data?.length} ${t("selected")}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        <MenuItem value="select all">
                                            <Checkbox checked={checkedAll}
                                                onChange={handleChangeGroup}
                                                style={{ transform: "scale(.75)" }}
                                                sx={{
                                                    color: "#1f9997",
                                                    "&.Mui-checked": {
                                                        color: "#1f9997",
                                                    },
                                                }}
                                            />
                                            <ListItemText primary={t("selectAll")} />
                                        </MenuItem>

                                        {groupsLists ? groupsLists?.data?.map(({ name, channel }, index) => (
                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={groupName.indexOf(name) > -1}
                                                style={{ transform: "scale(.75)" }}
                                                sx={{
                                                    color: "#1f9997",
                                                    "&.Mui-checked": {
                                                        color: "#1f9997",
                                                    },
                                                }}
                                            />
                                            <ListItemText>
                                                <div style={{ fontSize: 14 }}><TestCaseGroupChannel group={{ channel }} />  {name}</div>
                                            </ListItemText>
                                        </MenuItem>
                                    )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4} >
                                <Typography variant="subtitle1">
                                    <div style={{ fontSize: 14, marginTop: isMobile ? 16 : 0 }}>&nbsp; {t("filterBy")}</div>
                                </Typography>
                                <FormControl sx={{ m: 1, ml: 0, width: isMobile ? '100%' : '80%', margin: '0px' }} size="small">
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={selectedRates}
                                        onChange={handleSelectedRates}
                                        multiple
                                        renderValue={(selected) => `${selected.length} of ${rates.length} ${t("selected")}`}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                        style={{ fontSize: 14 }}
                                    >

                                        <MenuItem>
                                            <Checkbox checked={checkedAllRates}
                                                onChange={handleChangeGroupRates}
                                                style={{ transform: "scale(.75)" }}
                                                sx={{
                                                    color: "#1f9997",
                                                    "&.Mui-checked": {
                                                        color: "#1f9997",
                                                    },
                                                }}
                                            />
                                            <ListItemText primary={t("selectAll")} />
                                        </MenuItem>

                                        {rates ? rates.map((name, index) => (
                                            <MenuItem key={name} value={index}>
                                                <Checkbox checked={selectedRates.indexOf(index) > -1}
                                                    style={{ transform: "scale(.75)" }}
                                                    sx={{
                                                        color: "#1f9997",
                                                        "&.Mui-checked": {
                                                            color: "#1f9997",
                                                        },
                                                    }}
                                                />
                                                <ListItemText>
                                                    {index === 0 ? t("rateTypes.0") : (index === 1 ? t("rateTypes.1") : (index === 2 ? t("rateTypes.2") : ''))}
                                                </ListItemText>
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item className={classes.flex} xs={4}>
                                <div className={isMobile ? "w-full" : "w-80"} style={{marginBottom: '10px'}}>
                                    <DatePicker                                        
                                        selected={value ? value : ''}
                                        onChange={(date) => setValue(date)}
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
            )}
            

        </Grid>
    );
}

export default ContentTopbar;