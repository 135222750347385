import Navbar from "../../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import ViewTestResultContent from "./ViewTestResultContent";
import {viewTestResult} from "../../../actions";

const TestResult = () => {

    const defaultPage = [0, 1, 0, 0]
    const dispatch = useDispatch()
    const testResultId = useParams()
    useEffect( () => {
        if(testResultId && testResultId.id) {
            viewTestResult({id: testResultId.id})(dispatch)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Navbar/>
            <Grid container>
                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <ViewTestResultContent/>
                </Grid>
            </Grid>
        </div>
    )
}

export default TestResult