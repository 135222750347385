import React, { forwardRef, useEffect, useState } from "react";
import {
    Box,
    Button, FormHelperText, InputLabel,
    MenuItem, Select, TextField, Typography
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { addDays } from "date-fns";
import moment from "moment";
import Divider from "@mui/material/Divider";
import timeLogo from "../../../images/Group 196.svg";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import ContentHeader from "../../mui/ContentHeader";
import { useDispatch, useSelector } from "react-redux";
import { createReport, editReport, getLatency, getReportTypes, groupsList, LensActions, toggleGlobalAlert } from "../../../actions";
import { addDay, currentMonth, currentMonths, currentWeek, currentWeeks, lastMonth, lastMonths, lastWeek, lastWeeks, lastYear, lastYears } from "../../../helper/date";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    title: {
        color: "white",
        height: "40px",
        verticalAlign: "center",
        padding: "10px"
    },

    input: {
        marginRight: "20px",
        width: "250px",
        height: "40px",
        borderRadius: "5px",
        fontSize: "14px",
        border: "1px solid lightgrey",
        paddingLeft: '16px',
        "&:focus": {
            outline: "none !important",
            border: "2px solid #3f50b5",
        },
    }
}));


const AddReportContent = ({ editMode = false }) => {
    const {t} = useTranslation();
    const Latency = "Test Script Latency by Hour"
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const [warning, setWarning] = useState('');
    const [failure, setFailure] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(new Date(), 6));
    const [form, setForm] = useState({
        timeFrame: t("custom"),
        type: '',
        group: '',
        name:'',
    });

    const onChange = (dates) => {
        if (!dates.length) {
            setStartDate(dates);
            setEndDate(dates);
            return;
        }
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const reportTypes = useSelector(state => state.reportReducer.getReportTypes)
    const testGroups = useSelector(state => state.loginReducer.groupsList)
    const latency = useSelector(state => state.reportReducer.latency)
    const timeFrames = [t("custom"), t("today"), t("yesterday"), t("currentWeek"), t("lastWeek"), t("currentMonth"), t("lastMonth"), t("lastYear")];
    const getOneReport = useSelector(state => state.reportReducer.getOneReport);
    const selectedChannel = useSelector(state => state.loginReducer.selectedChannel);

    useEffect(() => {
        getReportTypes(selectedChannel)(dispatch)
        groupsList()(dispatch)
        getLatency()(dispatch)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps




    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        control,
        reset,
        setValue
    } = useForm({
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (editMode && getOneReport && getOneReport?.data) {
          
            setForm({
                name: getOneReport.data.name,
                type: getOneReport.data.reportTypeID,
                timeFrame: getOneReport.data.timeframeName,
                group: getOneReport.data.testCaseGroupID,
            })
            const start = getOneReport.data.timeframeBegin.split('-');
            const end = getOneReport.data.timeframeEnd.split('-');
            setStartDate(new Date(start[0], (+start[1]) - 1, start[2], 0, 0, 0, 0))
            setEndDate(new Date(end[0], (+end[1]) - 1, end[2], 0, 0, 0, 0))
            setWarning(getOneReport.data.latencyWarn)
            setFailure(getOneReport.data.latencyFail)
        } else {
            setForm({
                name: '',
                type: '',
                timeFrame: t("custom"),
                group: '',
            })
        }
    }, [getOneReport, editMode])

    const handleChangeWarning = (event) => {
        setWarning(event.target.value)
    }
    const handleChangeFailure = (event) => {
        setFailure(event.target.value)
    }

    const handleSaveSubmit = ({ name }) => {
        if (!form?.name) {
            return setError('name', { type: 'manual', message: 'Please enter a name' })
        }
        if (!form?.type) {
            return setError('type', { type: 'manual', message: 'Please select type' })
        }
        if (!form?.group) {
            return setError('group', { type: 'manual', message: 'Please select group' })
        }

        if (form?.type && form?.type.name === Latency && !warning) {
            return setError('warning', { type: 'manual', message: 'Please select threshold warning' })
        }
        if (form?.type && form?.type.name === Latency && !failure) {
            return setError('failure', { type: 'manual', message: 'Please select threshold failure' })
        }

        let reportData = {}
        reportData.name = form?.name
        reportData.description = form?.name
        reportData.reportTypeName = form?.type
        reportData.reportTypeID = form?.type
        reportData.testCaseGroupName = form?.group
        reportData.testCaseGroupID = form?.group
        reportData.timeframeName = form?.timeFrame;

        if (form?.timeFrame === t("custom")) {
            reportData.timeframeBegin = new moment(startDate).format('YYYY-MM-DD');
            reportData.timeframeEnd = endDate ? new moment(endDate).format('YYYY-MM-DD') : new moment(startDate).format('YYYY-MM-DD');
        } else {
            reportData.timeframeBegin = getStartEndTime()[0];
            reportData.timeframeEnd = getStartEndTime()[1];
        }


        reportData.latencyWarn = +form?.type === 6 ? warning : 0
        reportData.latencyFail = +form?.type === 6 ? failure : 0

        if (editMode) {
            editReport(reportData, getOneReport.data.id)(dispatch)
        } else {
            createReport(reportData)(dispatch)
        }
        history.push('/reports')
    };

    const cancel = () => {
        history.push(`/reports`)
        dispatch({ type: LensActions.CreateReport, payload: null })
        dispatch({ type: LensActions.EditReport, payload: null })
    }

    const handleFormChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }
    const getStartEndTime = () => {
        switch (form?.timeFrame) {
            case t("today"): return [new moment().format('YYYY-MM-DD'), new moment().format('YYYY-MM-DD')];
            case t("yesterday"): return [new moment(addDay(new Date(), -1)).format('YYYY-MM-DD'), new moment(addDay(new Date(), -1)).format('YYYY-MM-DD')];
            case t("currentWeek"): return currentWeeks(new Date());
            case t("lastWeek"): return lastWeeks(new Date());
            case t("currentMonth"): return currentMonths(new Date());
            case t("lastMonth"): return lastMonths(new Date());
            case t("lastYear"): return lastYears(new Date());
        }
    }

    const rendertimeFrame = () => {
        switch (form?.timeFrame) {
            case t("custom"): return `${new moment(startDate).format('MMM DD, YYYY')} - ${endDate ? new moment(endDate).format('MMM DD, YYYY') : ""}`;
            case t("today"): return new moment().format('MMM DD, YYYY');
            case t("yesterday"): return new moment(addDay(new Date(), -1)).format('MMM DD, YYYY');
            case t("currentWeek"): return currentWeek(new Date());
            case t("lastWeek"): return lastWeek(new Date());
            case t("currentMonth"): return currentMonth(new Date());
            case t("lastMonth"): return lastMonth(new Date());
            case t("lastYear"): return lastYear(new Date());
        }
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "270px"
        }}>

            <fieldset
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "250px",
                    height: "40px",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    marginTop: '-10px',
                    padding: '13px'
                }}>
                <legend style={{ fontSize: '14px', fontFamily: 'Montserrat', fontWeight: '400', color: 'rgba(0, 0, 0, 0.6)' }}>{t("selectTimeFrame")}</legend>

                <Typography
                    style={{ fontSize: 14, marginRight: "10px", color: "#636364", marginTop: "-5px", width: "200px" }}
                >
                    &nbsp;&nbsp;{rendertimeFrame()}
                </Typography>

                {form?.timeFrame === t("custom") && (
                    <>
                        <Divider sx={{ height: 40, mr: 0.5, marginTop: '-10px' }} orientation="vertical" />
                        <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
                            style={{
                                backgroundColor: "#1F9997",
                                border: "3px solid #1F9997",
                                borderRadius: "5px",
                                width: "25px",
                                height: "25px",
                                marginTop: '-10px',
                                cursor: form?.timeFrame === t("custom") ? 'pointer' : ''
                            }}
                        />
                    </>
                )}
            </fieldset>
        </Box>
    ));

    return (
        <div style={{ position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={t("reportCreateReport")} />
            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '20px'
                }}
            >
                <Typography type="body2" className={classes.title}>
                    {editMode ? t("editReport") : t("createReport")} 
                </Typography>

            </Box>

            {editMode && (
                <Typography variant="subtitle1" style={{
                    fontSize: '18px', fontWeight: 600, fontFamily: 'Montserrat'
                }}>
                    Report ID : {getOneReport?.data?.id}
                </Typography>
            )}

            <Box component="form"
                onSubmit={handleSubmit(handleSaveSubmit)}
            >
                <Box style={{ display: "flex", flexDirection: "row" }} sx={{ mt: 5 }}>

                    <div style={{ width: "400px", marginRight: 16 }}>
                        <TextField
                            value={form?.name}
                            label={t("reportLabel.1")}
                            onChange={handleFormChange}
                            name="name"
                            style={{ marginRight: "20px", width: "250px" }}
                            size="small"
                        >
                            
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.name?.message) ? errors.name?.message : null}</FormHelperText>
                    </div>

                    <div style={{ width: "270px" }}>
                        <TextField
                            value={form?.type}
                            select
                            label={t("reportType.2")}
                            onChange={handleFormChange}
                            name="type"
                            style={{ marginRight: "20px", width: "250px" }}
                            size="small"
                        >
                            {reportTypes?.data?.map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.type?.message) ? errors.type?.message : null}</FormHelperText>
                    </div>

                    <div style={{ width: "270px" }}>

                        <TextField
                            value={form?.group}
                            select
                            label={t("testCaseGroup.2")}
                            onChange={handleFormChange}
                            name="group"
                            style={{ marginRight: "20px", width: "250px" }}
                            size="small"
                        >
                            {testGroups?.data?.map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.group?.message) ? errors.group?.message : null}</FormHelperText>
                    </div>
                    <div style={{ width: "270px" }}>

                        <TextField
                            value={form?.timeFrame}
                            select
                            label={t("selectTimeFrame")}
                            onChange={handleFormChange}
                            style={{ marginRight: "20px", width: "250px" }}
                            name="timeFrame"
                            size="small"
                        >
                            {timeFrames.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.group?.message) ? errors.group?.message : null}</FormHelperText>
                    </div>

                    {form?.timeFrame === t("custom") ? (
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            customInput={<ExampleCustomInput />}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                        />
                    ) : (
                        <ExampleCustomInput />
                    )}
                </Box>

                <br /><br />

                <Box style={{ display: form?.type && form?.type === 6 ? "flex" : "none", flexDirection: "row" }}>
                    <div style={{ width: "350px" }}>
                        <TextField
                            select
                            label={t("latencyThresholdWarnings")}
                            value={warning}
                            onChange={handleChangeWarning}
                            style={{ marginRight: "20px", width: "300px" }}
                            size="small"
                        >
                            {(latency || []).map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.warning?.message) ? errors.warning?.message : null}</FormHelperText>
                    </div>

                    <div style={{ width: "350px" }}>

                        <TextField
                            value={failure}
                            select
                            label={t("latencyThresholdFailures")}
                            onChange={handleChangeFailure}
                            style={{ marginRight: "20px", width: "300px" }}
                            size="small"
                        >
                            {(latency || []).map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormHelperText
                            style={{ color: "red" }}>{Boolean(errors.failure?.message) ? errors.failure?.message : null}</FormHelperText>
                    </div>
                </Box>
                <br />
                <Box textAlign='left'
                    sx={{
                        minWidth: "500px",
                        backgroundColor: '#E8F3F4',
                        marginTop: form?.type && form?.type === Latency ? '100px' : '165px',
                        height: 72,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        type="submit"
                        sx={{ mt: 1, mb: 1, ml: 5 }}
                        style={{
                            borderRadius: 5,
                            background: '#1F9997 0% 0% no-repeat padding-box',
                            height: 40,
                            width: "200px",
                        }}
                    >
                        <Typography style={{ textTransform: 'none', fontSize: "15px", fontFamily: 'Poppins', fontWeight: 600, color: "white" }}>{t("save")}</Typography>
                    </Button>

                    <Button
                        sx={{ ml: 2 }}
                        onClick={cancel}
                    >
                        <Typography style={{ textTransform: 'none', fontSize: '15px', fontFamily: 'Poppins', fontWeight: 600, color: "#BDBDBD" }}>{t("cancel")}</Typography>
                    </Button>
                </Box>
            </Box>
        </div>

    )
}

export default AddReportContent
