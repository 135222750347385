import Navbar from "../../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import React, {useEffect} from "react";
import EditReportContent from "./EditReportContent";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getOneReport} from "../../../actions";
import AddReportContent from "../reportAdd/AddReportContent";

const ReportEdit = () => {

    const defaultPage = [0, 1, 0, 0]
    const dispatch = useDispatch()
    const reportId = useParams()
    const reportTypes = useSelector(state => state.reportReducer.getReportTypes)
    const testGroups = useSelector(state => state.loginReducer.groupsList)
    const selectedChannel = useSelector(state => state.loginReducer.selectedChannel)

    useEffect( () => {
        if(reportId && reportId.id) {
            getOneReport({id: reportId.id, reportType: !!reportTypes, testGroup: !!testGroups, selectedChannel: selectedChannel})(dispatch)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Navbar/>
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <AddReportContent editMode={true}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default ReportEdit