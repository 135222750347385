import { format, addDays, startOfWeek, differenceInSeconds, differenceInMilliseconds } from 'date-fns'
import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfYear } from 'date-fns/esm';

export const currentDate = () => {
    let today = new Date();
    let month =
        today.getMonth() > 8
            ? "" + (today.getMonth() + 1)
            : "0" + (today.getMonth() + 1);
    let day = today.getDate() > 9 ? "" + today.getDate() : "0" + today.getDate();
    let date = today.getFullYear() + "-" + month + "-" + day;
    return date;
};

export const convertToDate = (input) => {
    let date = new Date(input);
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return date;
}


export const formatDate = (date, formatString = 'LLLL') => {
    if (typeof date === 'string' && date.indexOf('T') !== -1) {
        return date.split('T')[0].replace(/-/g, '/')
    }

    return date && format(new Date(date), formatString);
};

export const getDate = (date) => {
    if (typeof date === 'string' && date.indexOf('T') !== -1) {
        return date.split('T')[0];
    }

    if (typeof date === 'string' && date.indexOf('-') !== -1) {
        return date.replace(/-/g, '/')
    }
    return '';
};


export const addDay = (date, days) => addDays(date, days);


export const lastYear = (date) => {
    let start = startOfYear(date);
    start = addDays(start, -1);
    start = startOfYear(start);
    const end = endOfYear(start);
    return `${formatDate(start, 'MMM dd, yyyy')} - ${formatDate(end, 'MMM dd, yyyy')}`;
}

export const lastYears = (date) => {
    let start = startOfYear(date);
    start = addDays(start, -1);
    start = startOfYear(start);
    const end = endOfYear(start);
    return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}

export const currentWeeks = (date) => {
    const start = startOfWeek(date);
    const end = endOfWeek(date);
    return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}


export const currentWeek = (date) => {
    const start = startOfWeek(date);
    const end = endOfWeek(date);
    return `${formatDate(start, 'MMM dd, yyyy')} - ${formatDate(end, 'MMM dd, yyyy')}`;
}
export const lastMonths = (date) => {
    let start = startOfMonth(date);
    start = addDays(start, -1);
    start = startOfMonth(start);
    const end = endOfMonth(start);
    return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}


export const lastMonth = (date) => {
    let start = startOfMonth(date);
    start = addDays(start, -1);
    start = startOfMonth(start);
    const end = endOfMonth(start);
    return `${formatDate(start, 'MMM dd, yyyy')} - ${formatDate(end, 'MMM dd, yyyy')}`;
}

export const currentMonth = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    return `${formatDate(start, 'MMM dd, yyyy')} - ${formatDate(end, 'MMM dd, yyyy')}`;
}
export const currentMonths = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}

export const lastWeeks = (date) => {
    let start = startOfWeek(date);
    start = addDays(start, -7);
    return [formatDate(start, 'yyyy-MM-dd'), formatDate(addDay(start, 6), 'yyyy-MM-dd')];
}

export const lastWeek = (date) => {
    let start = startOfWeek(date);
    start = addDays(start, -7);
    return `${formatDate(start, 'MMM dd, yyyy')} - ${formatDate(addDay(start, 6), 'MMM dd, yyyy')} `;
}

export const formatDateTime = (date, formatString = 'LLLL') => {
    if (typeof date === 'string' && date.indexOf('T') !== -1) {
        return date.replace('T', ' ').replace(/-/g, '/').split('.')[0]
    }

    return date && format(new Date(date), formatString);
};


export const getStartTime = (time = '') => {
    const times = time.split('T')[1].split(':').map(c => +c);
    console.log(times[1] * 60 + times[2])
    return times[1] * 60 + times[2];
}

export const DiffInSeconds = (start, end) => differenceInSeconds(new Date(start), new Date(end));

export const DiffInMilliSeconds = (start, end) => Math.abs(differenceInMilliseconds(new Date(start), new Date(end)));