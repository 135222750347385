import React from "react";
import Navbar from "../dashboard/Navbar";
import { Grid } from "@mui/material";
import LeftBar from "../dashboard/LeftBar";
import AlertList from "./Alert-List";
import { isMobile } from 'react-device-detect';

const Alert = () => {
    const defaultPage = [0, 0, 1, 0]
    return (
        <div>
            <Navbar />
            <Grid container>
                {!isMobile && (
                    <Grid item>
                        <LeftBar page={defaultPage} />
                    </Grid>
                )}
                <Grid item >
                    <AlertList />
                </Grid>
            </Grid>
        </div>
    );
};

export default Alert;
