import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ContentHeader from "../../mui/ContentHeader";
import { Box, MenuItem, TextField, Typography, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { getAlertSetting, getAlertStatuses, getSettingsReports, upsertAlertSettings } from "../../../actions";
import { ReportTypes } from "../../../helper/report-helper";
import Modal from "../../mui/Modal";
import Schedule from "./Schedule";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { TestCaseGroupChannel } from "../test_case_group/Test-Case-Group-Channel";
import { useMemo } from "react";

const schema = yup.object().shape({
    label: yup.string().required("Required").trim(),
    testCaseGroupId: yup.number().integer().min(1).required("Required"),
    contactGroupId: yup.number().integer().min(1).required("Required"),
    noFailures: yup.number().integer().min(1).required("Required"),
    email: yup.string().required("Required").trim(),
    voice: yup.string().required("Required").trim(),
    sms: yup.string().required("Required").trim(),
    alertStatus: yup.array().min(1).required('Required'),
});


const EditContent = () => {
    const dispatch = useDispatch();
    let history = useHistory()
    const { id } = useParams();
    const [hash, setHash] = useState("init");
    const [editOpen, setEditOpen] = useState(false);
    const testCaseGroup = useSelector(state => state.reportReducer.testCaseGroup);
    const distributions = useSelector(state => state.reportReducer.distributions);
    const alertSetting = useSelector(state => state.reportReducer.alertSetting);
    const alertStatuses = useSelector(state => state.reportReducer.alertStatuses);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        control,
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: {
            label: '', testCaseGroupId: 0, contactGroupId: 0, notiActive: false,
            email: '', voice: '', sms: '', attachRecording: false, noFailures: 0,
            alertRecovery: false, warnings: false, alertStatus: [], schedule: []
        }
    });

    const watchTestCaseGroupId = watch("testCaseGroupId", 0);


    const scheduled = (data) => {
        setValue('schedule', data);
        setEditOpen(false);
    }

    const handleSaveSubmit = (data) => {
        if (!data.label || !data.testCaseGroupId || !data.contactGroupId
            || !data.email || !data.voice || !data.sms || !data.alertStatus) {
            return;
        }

        let model = {
            name: data.label,
            active: data.notiActive,
            testCaseGroupId: data.testCaseGroupId,
            distributionId: data.contactGroupId,
            emailSubject: data.email,
            attachRecording: data.attachRecording,
            voiceMessage: data.voice,
            smsPrefix: data.sms,
            failureToAlert: data.noFailures,
            notifyOnRecovery: data.alertRecovery,
            alertStatus: data.alertStatus,
            schedule: data.schedule,
        };

        if (id > 0) {
            model = {
                ...model,
                id,
            }
        }

        upsertAlertSettings(model, +id)(dispatch);
        history.push('/settings/alert-settings/1');

    }

    const isVoice = useMemo(() => {
        return !watchTestCaseGroupId ? '' : testCaseGroup.find(tcg => tcg.id === watchTestCaseGroupId)?.channel || 'ivr';
    }, [watchTestCaseGroupId])

    const updateStatus = (e) => {
        const values = getValues('alertStatus')

        if (e.target.checked) {
            setValue('alertStatus', (values || []).concat(e.target.value))
        } else {
            setValue('alertStatus', values.filter(v => v !== e.target.value))
        }

    }
    useEffect(() => {
        if (alertSetting) {
            reset({
                label: alertSetting.name,
                testCaseGroupId: alertSetting.testCaseGroupId,
                contactGroupId: alertSetting.distributionId,
                notiActive: alertSetting.active,
                noFailures: +alertSetting.failureToAlert,
                email: alertSetting.emailSubject,
                alertRecovery: alertSetting.notifyOnRecovery,
                attachRecording: alertSetting.attachRecording,
                voice: alertSetting.voiceMessage,
                sms: alertSetting.smsPrefix,
                alertStatus: alertSetting.alertStatus,
                schedule: alertSetting.schedule,
            });
        }
    }, [alertSetting])

    useEffect(() => {
        getSettingsReports(ReportTypes.TEST_CASE_GROUP)(dispatch)
        getSettingsReports(ReportTypes.DISTRIBUTION)(dispatch)
        getAlertStatuses()(dispatch);

        if (id > 0) {
            getAlertSetting(id)(dispatch);
        } else {
            reset({
                label: '',
                testCaseGroupId: 0,
                contactGroupId: 0,
                notiActive: false,
                noFailures: 0,
                email: '',
                alertRecovery: false,
                attachRecording: false,
                voice: '',
                sms: '',
                alertStatus: '',
                schedule: [],
            });
        }
    }, [id])


    return (
        <div style={isMobile ? { paddingBottom: '65px', paddingLeft: '24px', paddingRight: '24px', paddingTop: '0' } : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={`${t("settingsAlertSettings")} / ${id > 0 ? t("edit.1") : t("create.1")} ${t("alertSetting")}`} />

            <Box textAlign='left'
                sx={{
                    minWidth: isMobile ? "100%" : "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '5px',
                    verticalAlign: 'middle'
                }}
            >
                <Typography type="body2" className="edit-title">
                    {id > 0 ? t("edit.1") : t("create.1")} {t("alertSetting")}
                </Typography>

            </Box>

            <form onSubmit={handleSubmit(handleSaveSubmit)} style={isMobile ? { width: 'calc(100vw - 50px)' } : {}}>
                <Box sx={{ mt: 4, mb: 5 }}>
                    <Controller
                        render={({ field }) => (
                            <TextField size="small" label={t("statusAlertLabel.1")}
                                fullWidth={isMobile}
                                {...field} value={field.value}

                            />
                        )}
                        control={control}
                        name="label"
                    />
                    {errors?.label && <div className="error">t("thisFieldRequired")!</div>}
                </Box>
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t("details.0")}
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4} sx={{ mt: 3 }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField select size="small" label={t("testCaseGroup.2")} className="min-w-340"
                                        fullWidth={isMobile}
                                        {...field} value={field.value}>
                                        <MenuItem value={0}>{t("select")}</MenuItem>
                                        {testCaseGroup?.map(tcg => (
                                            <MenuItem key={tcg.id} value={tcg.id}>
                                                <TestCaseGroupChannel group={tcg} /> {tcg.name}</MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                control={control}
                                name="testCaseGroupId"
                            />
                            {errors?.testCaseGroupId && <div className="error">t("thisFieldRequired")!</div>}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} sx={{ mt: 3 }}>
                            <Controller
                                render={({ field }) => (

                                    <TextField select size="small" label={t("contactGroup.1")} className="min-w-340"
                                        {...field} value={field.value}
                                    >
                                        <MenuItem value={0}>{t("select")}</MenuItem>
                                        {distributions?.map(tcg => (
                                            <MenuItem key={tcg.id} value={tcg.id}>{tcg.name}</MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                control={control}
                                name="contactGroupId"
                            />
                            {errors?.contactGroupId && <div className="error">t("thisFieldRequired")!</div>}
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="h6">
                    {t("notificationOptions")}
                </Typography>

                <Grid container sx={{ mb: 4 }}>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Box sx={{ mt: isMobile ? 2 : 0 }}>
                            <Typography variant="body2" className="config">
                                Configuration
                            </Typography>
                            <Controller
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label" control={<Checkbox checked={field.value}  {...field} />} label={t("active")} />
                                )}
                                control={control}
                                name="notiActive"
                            />
                            <Button sx={{ ml: 2 }} variant="contained" color="primary" className="round-button" onClick={() => setEditOpen(true)}>{t("schedule")}</Button>
                        </Box>
                    </Grid>
                    {isMobile ? (
                        <>
                            <Grid item xs={12}>
                                <TextField InputProps={{
                                    readOnly: true,
                                }} size="small" label="Type*" defaultValue={t("email")} className="mt-30" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField size="small" label="Subject" fullWidth
                                            {...field} value={field.value} className="mt-30"

                                        />
                                    )}
                                    control={control}
                                    name="email"
                                />
                                {errors?.email && <div className="error">t("thisFieldRequired")!</div>}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField InputProps={{
                                    readOnly: true,
                                }} size="small" label="Type*" defaultValue={t("voice")} className="mt-30" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField size="small" label="Message" fullWidth
                                            {...field} value={field.value} className="mt-30"

                                        />
                                    )}
                                    control={control}
                                    name="voice"
                                />
                                {errors?.voice && <div className="error">t("thisFieldRequired")!</div>}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField InputProps={{
                                    readOnly: true,
                                }} size="small" label="Type*" defaultValue={"SMS"} className="mt-30" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField size="small" label="Message" fullWidth
                                            {...field} value={field.value} className="mt-30"

                                        />
                                    )}
                                    control={control}
                                    name="sms"
                                />
                                {errors?.sms && <div className="error">t("thisFieldRequired")!</div>}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={3}>
                                <Box>
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} size="small" label="Type*" defaultValue={t("email")} className="mt-30" />
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} size="small" label="Type*" defaultValue={t("voice")} className="mt-30" />
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} size="small" label="Type*" defaultValue={"SMS"} className="mt-30" />
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField size="small" label={t("subject")}
                                                {...field} value={field.value} className="mt-30"

                                            />
                                        )}
                                        control={control}
                                        name="email"
                                    />
                                    {errors?.email && <div className="error">t("thisFieldRequired")!</div>}
                                    <Controller
                                        render={({ field }) => (
                                            <TextField size="small" label="Message"
                                                {...field} value={field.value} className="mt-30"

                                            />
                                        )}
                                        control={control}
                                        name="voice"
                                    />
                                    {errors?.voice && <div className="error">t("thisFieldRequired")!</div>}
                                    <Controller
                                        render={({ field }) => (
                                            <TextField size="small" label="Message"
                                                {...field} value={field.value} className="mt-30"

                                            />
                                        )}
                                        control={control}
                                        name="sms"
                                    />
                                    {errors?.sms && <div className="error">t("thisFieldRequired")!</div>}
                                </Box>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={isMobile ? 12 : 3}>
                        {(!isVoice || isVoice === 'ivr') && (
                            <Box sx={{ mt: 3 }} >
                                <Controller
                                    render={({ field }) => (
                                        <FormControlLabel className="checkbox-label" control={<Checkbox  {...field} checked={field.value} />} label={t("attachCallRecording")} />
                                    )}
                                    control={control}
                                    name="attachRecording"
                                />

                            </Box>
                        )}
                    </Grid>
                </Grid>

                <Typography variant="h6">
                   {t("alertConditions")}
                </Typography>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Controller
                            render={({ field }) => (
                                <TextField sx={{ mt: 2 }} select label={t("numberofFailures")}
                                    className="min-w-240" size="small"
                                    {...field} value={field.value}>
                                    <MenuItem value={0}>{t("select")}</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </TextField>
                            )}
                            control={control}
                            name="noFailures"
                        />
                        {errors?.noFailures && <div className="error">{t("numberofFailures")}{t("isRequired")}!</div>}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Box sx={{ mt: isMobile ? 3 : 0 }}>
                            <Typography variant="body2" className="config">
                                {t("alertOnceandOnRecovery")}
                            </Typography>
                            <Controller
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label" control={<Checkbox checked={field.value}  {...field} />} label={t("enabled")} />
                                )}
                                control={control}
                                name="alertRecovery"
                            />
                        </Box>
                    </Grid>
                    {isVoice && (
                        <Grid item xs={isMobile ? 6 : 3}>
                            <Box sx={{ mt: isMobile ? 3 : 0 }}>
                                <Typography variant="body2" className="config">
                                    {t("failures")}
                                </Typography>
                                <Controller
                                    render={({ field }) =>
                                    (
                                        <>
                                            {alertStatuses?.filter((c, index) =>
                                                c.statusGroup === 'TRS_FAIL' && (c.supportChannel || ['ivr']).includes(isVoice)).map(status => (
                                                    <FormControlLabel
                                                        className="checkbox-label db"
                                                        control={<Checkbox value={status.code}
                                                            onChange={updateStatus}
                                                            checked={field.value.indexOf(status.code) !== -1} />}
                                                        label={status.text} />
                                                ))}
                                        </>
                                    )
                                    }
                                    control={control}
                                    name="alertStatus"
                                />
                                {errors?.alertStatus && <div className="error">{t("atLeastOneFailure")} {isVoice === 'ivr' ? '/Warnings':''} {t("isRequired")}!</div>}

                            </Box>
                        </Grid>
                    )}

                    {(alertStatuses?.filter(c => c.statusGroup === 'TRS_WARN' && (c.supportChannel || ['ivr']).includes(isVoice)).length > 0) && (
                        <Grid item xs={isMobile ? 6 : 3}>
                            <Box sx={{ mt: isMobile ? 3 : 0 }}>
                                <Typography variant="body2" className="config">
                                    {t("warnings")}
                                </Typography>
                                <Controller
                                    render={({ field }) => (
                                        <>
                                            {alertStatuses?.filter(c => c.statusGroup === 'TRS_WARN' && (c.supportChannel || ['ivr']).includes(isVoice)).map(status => (
                                                <FormControlLabel
                                                    className="checkbox-label db"
                                                    control={<Checkbox value={status.code}
                                                        onChange={updateStatus}
                                                        checked={field.value.indexOf(status.code) !== -1} />}
                                                    label={status.text} />
                                            ))}
                                        </>
                                    )}
                                    control={control}
                                    name="alertStatus"
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Box textAlign='left'
                    sx={{
                        minWidth: isMobile ? "100%" : "500px",
                        borderRadius: '5px',
                        borderWidth: '0',
                        borderStyle: 'solid',
                        backgroundColor: '#E8F3F4',
                        mt: isMobile ? 4 : 6,
                        mb: isMobile ? 2 : 0,
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 2 }}
                        style={{
                            borderRadius: 7,
                            backgroundColor: "#1f9997",
                            fontSize: "14px",
                            width: "190px",
                            height: 40
                        }}
                    >
                        <Typography style={{ fontFamily: 'Poppins', fontSize: 14, textTransform: 'none', color: "white" }}>{t("save")}</Typography>
                    </Button>

                    <Button

                    >
                        <Typography style={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 600, textTransform: 'none', color: "#BDBDBD" }}
                            onClick={() => history.push('/settings/alert-settings/1')} >{t("cancel")}</Typography>
                    </Button>
                </Box>
                <Modal
                    isOpen={editOpen}
                    openHash={hash}
                    overflow={true}
                    body={<Schedule data={getValues('schedule') || []} done={scheduled} cancel={() => setEditOpen(false)} />}
                />
            </form>
        </div>
    )
}

export default EditContent;
