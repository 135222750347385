import axios from "axios";
import { getVoiceApi } from "./config.service";

export const SSO_URL = 'https://sso-lensapi.tekvision.com';

const getToken = () => {
    if (localStorage.getItem('TOKEN')) {

        return {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
    }

    return {};
}


export default axios.create({
    baseURL: getVoiceApi(),
    timeout: 30000,
    ...getToken(),
});

export const ssoApi = axios.create({
    baseURL: `${SSO_URL}/api`,
    timeout: 30000,
});




export const acApi = axios.create({
    baseURL: `https://api.tekvision.com`,
    timeout: 30000,
});