import { useEffect, useState } from "react"
import lensService from "../../api/lensService";
import { getVoiceApi } from "../../api/config.service";

export const Audio = ({ url }) => {
    const [blob,setBlob] = useState(null);

    const loadPlayer = async () => {
        const res = await lensService.get(`${getVoiceApi()}/api/trs/v1/getContent?attachmentUrl=${url}`, {
            responseType: 'blob',
        });

        const blob = new Blob([res.data], { type: "octet/stream" });
        const audioUrl = window.URL.createObjectURL(blob);
        setBlob(audioUrl);
    }

    useEffect(() => {
        if(url) {
            loadPlayer();
        }
    }, [url]);

    return (
        
        blob && <audio controls>
            <source src={blob} type="audio/wav" />
        </audio>
    )
}