import Navbar from "../../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import React, {useEffect} from "react";
import ViewReportContent from "./ViewReportContent";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {viewReport} from "../../../actions";

const ReportView = () => {

    const defaultPage = [0, 1, 0, 0]
    const dispatch = useDispatch()
    const reportId = useParams()
    useEffect( () => {
        if(reportId && reportId.id) {
            viewReport({id: reportId.id})(dispatch)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Navbar/>
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <ViewReportContent/>
                </Grid>
            </Grid>
        </div>
    )
}

export default ReportView