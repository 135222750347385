import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import ContentHeader from "../../mui/ContentHeader";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import * as echarts from "echarts";
import { DataGrid } from "@mui/x-data-grid";
import { flatten, groupBy, orderBy, reverse, sortBy, sumBy, chunk } from 'lodash';
import { useHistory } from "react-router-dom";
import ApexCharts from 'apexcharts'
import { convertToDate, formatDate, formatDateTime } from "../../../helper/date";
import CustomFooter from "../../mui/CustomFooter";
import { useTranslation } from "react-i18next";
import download from "../../../images/download-white.svg";
import { downloadCSV ,getMos} from "../../../actions";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        "& .MuiDataGrid-row": {
            "&:nth-child(2n+1)": { backgroundColor: "#F9F9F9" },
            maxHeight: 'none !important',
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#FFFFFF",
            fontSize: 12
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important'
        },
        '& .MuiDataGrid-cell': {
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
            fontSize: "16px",
            padding: "15px !important"
        },
        '& .MuiDataGrid-root': {
            border: 'none !important'
        }
    },
    title: {
        color: "white",
        paddingLeft: '32px',
        verticalAlign: "center",
        padding: "10px",
    },
    detail: {
        padding: "10px",
        fontSize: "18px"
    },
}));

const getFormat = (count) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    x: `${k < 10 ? ("0"+k):(""+k) }`,
    y:0,
	fillColor:'#FF0000',
  }));
  
const heatMapFormat = getFormat(24);

const ViewReportContent = () => {
    //const viewReport = "REPORTS / VIEW REPORT"
    const classes = useStyles();
    const dispatch = useDispatch();
    const [reportTable, setReportTable] = useState(null)
    const viewOneReport = useSelector(state => state.reportReducer.viewReport)
    const currentReport = useSelector(state => state.reportReducer.selectedReport)
    const mosReport = useSelector(state => state.reportReducer.mos)
    const latency = useSelector(state => state.reportReducer.latency)
    const [rowPerPage, setRowPerPage] = useState(100);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState([]);
    const { t } = useTranslation();
    let history = useHistory();

    const handlePageChange = (event, value) => {
        setPage(value)
    }
    
    useEffect(() => {
		 if (viewOneReport && currentReport) {
            getMos(currentReport?.testCaseGroupID,currentReport?.timeframeBegin,currentReport?.timeframeEnd)(dispatch);
		 }
    }, [viewOneReport, currentReport]);

    useEffect(() => {
        if (viewOneReport && currentReport) {

            if (+currentReport.reportTypeID === 2) {
                const chartData = groupBy(flatten(viewOneReport.map(v => v.hourlyStatus)), 'hourly');
                const data = Object.entries(chartData).map(([key, value]) => ({
                    entityId: key,
                    entityName: key.split('T').map(d => d.indexOf('-') === -1 ? `${d.substring(0, 5)} - ${parseInt(d.substring(0, 2)) + 1}:00` : formatDate(convertToDate(d), 'MMMM d, yyyy')).join(', '),
                    failureCount: sumBy(value, 'failureCount'),
                    successCount: sumBy(value, 'successCount'),
                    warningCount: sumBy(value, 'warningCount'),
                }));

                setTotal([
                    {
                        entityId: '0',
                        entityName: t("total.0"),
                        failureCount: sumBy(viewOneReport, 'failureCount'),
                        successCount: sumBy(viewOneReport, 'successCount'),
                        warningCount: sumBy(viewOneReport, 'warningCount'),
                    }
                ]);
                setReportTable(orderBy(data, 'entityId'));
            } else if (+currentReport.reportTypeID !== 6) {
                setTotal([
                    {
                        entityId: '0',
                        entityName: +currentReport.reportTypeID === 5 ? t("totalSegmentPerformance") : t("totalTestCasePerformance"),
                        failureCount: sumBy(viewOneReport, 'failureCount'),
                        successCount: sumBy(viewOneReport, 'successCount'),
                        warningCount: sumBy(viewOneReport, 'warningCount'),
                    }
                ]);
                setReportTable(viewOneReport);
            } else {
                setReportTable(viewOneReport.segments);
            }

            setTimeout(function () {
                if (+currentReport.reportTypeID === 2) {
                    createLineChart();
		}else if (+currentReport.reportTypeID === 7){
		    createMultipleLine('viewReport');
                } else if (+currentReport.reportTypeID !== 6) {
                    createChart('viewReport')
                } else {
                    createHeatMap();
                }
            }, 1)
        } else {
            setReportTable([]);
            createHeatMap();
        }
    }, [viewOneReport, currentReport,mosReport]) // eslint-disable-line react-hooks/exhaustive-deps




    const downloadReport = async () => {
        const data = await downloadCSV(currentReport?.id)(dispatch);

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += data.data;

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", data.file);
        document.body.appendChild(link);

        link.click();

    }

    const drawUpperChart = (originalData) => {
        const chartData = groupBy(flatten(originalData.map(v => v.hourlyStatus)).map(r => ({
            ...r,
            hourly: new Date(r.hourly).getTime(),
            dateString: r.hourly,
        })), 'hourly');
        const pass = Object.entries(chartData).map(([key, value]) => sumBy(value, 'successCount'));
        const waning = Object.entries(chartData).map(([key, value]) => sumBy(value, 'warningCount'));
        const fail = Object.entries(chartData).map(([key, value]) => sumBy(value, 'failureCount'));
        const x = orderBy(Object.keys(chartData).map(c => +c));

        const data = [{
            name: 'Pass',
            data: pass,
        }, {
            name: 'Warning',
            data: waning,
        }, {
            name: 'Fail',
            data: fail,
        }];

        const options = {
            series: data,
            chart: {
                id: "chart2",
                events: {
                    click: (event, chartContext, config) => {
                        const { seriesIndex, dataPointIndex } = config;

                        if (+currentReport.reportTypeID !== 2) {

                            const ltc = latency[seriesIndex];
                            let hours = dataPointIndex === 0 ? '00' : `${dataPointIndex}`;

                            if (hours.length === 1) {
                                hours = `0${hours}`;
                            }
                            history.push(`/report/latency/${hours}/-1/${currentReport?.id}`);
                        } else {

                            const _date = new Date(x[dataPointIndex]);
                            const result = new Date(_date.toISOString().slice(0, -1));
                            const finalStatus = data[seriesIndex].name.replace('warning', 'Warn').replace('fail', 'Fail');
                            const date = formatDateTime(result, 'yyyy-MM-dd HH');
                            history.push(`/report/latency/${parseInt(date.split(' ')[1])}/-1/${currentReport?.id}/${finalStatus}/${date.split(' ')[0]}`);
                        }

                    },
                    mounted: () => {
                        drawLowwerChart();
                    }
                },
                height: 650,
                type: 'line',
                width: '100%',
                selection: {
                    enabled: true,
                    xaxis: {
                        min: '1640998800000',
                        max: '1641513600000'
                    }
                },
                zoom: {
                    enabled: true,
                    type: 'x',
                },
                toolbar: {
                    show: false,
                    autoSelected: "pan",
                },
            },
            stroke: {
                width: 1.5,
            },
            tooltip: {
                enabled: true,
                shared: true,
                x: {
                    show: true,
                    format: 'MMM dd, yyyy H:00',
                }
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 4,
            },
            colors: ['#4CAF50', '#FF7D42', '#CB1919'],
            xaxis: {
                type: 'datetime',
                categories: x,
            },
            grid: {
                padding: {
                    right: 0,
                },
            },
        };

        let chart = new ApexCharts(document.querySelector("#viewReport"), options);

        if (chart && document.querySelector("#viewReport")) {
            chart.render();
        }

    }

    const drawLowwerChart = () => {
	if (document.querySelector("#viewReportZoom")) {
            document.querySelector("#viewReportZoom").innerHTML = '';
        }

        const chartDataDaily = groupBy(flatten(viewOneReport.map(v => v.hourlyStatus)).map(r => ({
            ...r,
            hourly: new Date(r.hourly).getTime(),
        })), 'hourly');


        const passDaily = Object.entries(chartDataDaily).map(([key, value]) => sumBy(value, 'successCount'));
        const waningDaily = Object.entries(chartDataDaily).map(([key, value]) => sumBy(value, 'warningCount'));
        const failDaily = Object.entries(chartDataDaily).map(([key, value]) => sumBy(value, 'failureCount'));
        const xDaily = orderBy(Object.keys(chartDataDaily).map(c => +c));

        const orderTime = Object.keys(chartDataDaily).map(c => +c).sort();


        const dataDaily = [{
            name: 'Pass',
            data: passDaily,
        }, {
            name: 'Waning',
            data: waningDaily,
        }, {
            name: 'Fail',
            data: failDaily,
        }];

        const optionsDaily = {
            series: dataDaily,
            chart: {
                id: 'chart1',
                height: 300,
                type: 'area',
                width: '100%',
                brush: {
                    target: 'chart2',
                    enabled: true
                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: orderTime[0],
                        max: orderTime[orderTime.length - 1],
                    },
                },
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: 1.5,
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
            },
            colors: ['#4CAF50', '#FF7D42', '#CB1919'],
            xaxis: {
                type: 'datetime',
                categories: xDaily,
            },
            grid: {
                padding: {
                    right: 0,
                },
            },
        };

        let chartDaily = new ApexCharts(document.querySelector("#viewReportZoom"), optionsDaily);

        if (chartDaily && document.querySelector("#viewReportZoom")) {
            chartDaily.render();
        }
    }

    const createLineChart = () => {
        if (document.querySelector("#viewReport")) {
            document.querySelector("#viewReport").innerHTML = '';
        }

        if (!viewOneReport || !currentReport || +currentReport.reportTypeID !== 2) {
            return;
        }

        drawUpperChart(viewOneReport);



    }

    const createHeatMap = () => {
        if (document.querySelector("#viewReport")) {
            document.querySelector("#viewReport").innerHTML = '';
        }

        if (!viewOneReport || !viewOneReport?.latencyByHr?.length || !currentReport || +currentReport.reportTypeID !== 6) {
            return;
        }

        const chartData = viewOneReport.latencyByHr || [];
        const x = Object.keys(groupBy(chartData, 'hour'));
        const data = [];
        const totalRuns = sumBy(chartData, 'runCount');

        for (let j = 0; j < latency.length; j++) {
            data.push({
                name: `[${sumBy(chartData.filter(c => +c.latency >= +latency[j].lowerBound && +c.latency <= +latency[j].uppperBound), 'runCount')} runs (${((sumBy(chartData.filter(c => +c.latency >= +latency[j].lowerBound && +c.latency <= +latency[j].uppperBound), 'runCount') / totalRuns) * 100).toFixed(2)}%) ]  ${latency[j].text.replace('N/A', '0')}`,
                data: (x || []).map(t => ({
                    x: +t < 10 ? `0${t}` : `${t}`,
                    y: sumBy(chartData.filter(c => +c.hour === +t && (+c.latency >= +latency[j].lowerBound && +c.latency <= +latency[j].uppperBound)), 'runCount'),
                    fillColor: '#FF0000',
                }))
            })
	   if(data[j]?.data?.length<24){
		let temp = _.differenceBy(heatMapFormat,data[j].data,'x');
		let tempStatus = _.unionWith(data[j].data,temp,_.isEqual);
		let result = _.sortBy(tempStatus,function(item){return item.x;})
		data[j].data = result;
	   }
        }

        const options = {
            series: data,
            chart: {
                events: {
                    click: (event, chartContext, config) => {
                        const { seriesIndex, dataPointIndex } = config;
                        const ltc = latency[seriesIndex];
                        let hours = dataPointIndex === 0 ? '00' : `${dataPointIndex}`;

                        if (hours.length === 1) {
                            hours = `0${hours}`;
                        }
                        history.push(`/report/latency/${hours}/${ltc.id}/${currentReport?.id}`);

                    }
                },
                height: 550,
                type: 'heatmap',
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: 8,
            },
            plotOptions: {
                heatmap: {
                    radius: 10,
                    shadeIntensity: 0,
                    enableShades: false,
                    distributed: true,
                    useFillColorAsStroke: false,
                    colorScale: {
                        ranges: [{
                            from: 0,
                            to: 0,
                            name: ' ',
                            color: '#ffffff'
                        },

                        ],
                    },

                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],

                },

            },
            colors: (latency || []).map(t => {
                if (currentReport && +t.uppperBound < +currentReport.latencyWarn) {
                    return '#4CAF50';
                } else if (currentReport && +t.uppperBound >= +currentReport.latencyWarn && +t.uppperBound < +currentReport.latencyFail) {
                    return '#FF7D42'
                } else {
                    return '#BC222C';
                }
            }),
            xaxis: {
                type: 'category',
            },
            annotations: {
                points: [{
                    marker: {
                        size: 0,
                    },
                }],
                // yaxis: (latency || []).map((t, index) => ({
                //     y: (index + .3) * 7.6,
                //     borderColor: '#fff',
                //     label: {
                //         text: `${sumBy(chartData.filter(c => +c.latency >= +t.lowerBound && +c.latency <= +t.uppperBound), 'runCount')} runs (${((sumBy(chartData.filter(c => +c.latency >= +t.lowerBound && +c.latency <= +t.uppperBound), 'runCount') / totalRuns) * 100).toFixed(2)}%)`,
                //         position: 'right',
                //         offsetX: 50,
                //         textAnchor: 'start',
                //         borderColor: '#fff',
                //         //offsetY: -450,
                //         style: {
                //             fontSize: '14px',
                //             fontWeight: 600,
                //             fontFamily: 'Montserrat',
                //             color: '#595C5D',
                //         }
                //     }
                // })),
            },

            grid: {

                padding: {
                    right: 0,
                },
            },
        };

        let chart = new ApexCharts(document.querySelector("#viewReport"), options);

        if (chart && document.querySelector("#viewReport")) {
            chart.render();
        }
    }

    const createChart = (divId) => {
        document.getElementById(divId).innerHTML = '';
        try {
            echarts.dispose(document.getElementById(divId));

        } catch { }

        let myChart = echarts.init(document.getElementById(divId));

        let tempViewOneReport = [...viewOneReport].sort(
            (a, b) => {
                if (a.entityId > b.entityId) {
                    return -1;
                }
                if (a.entityId < b.entityId) {
                    return 1;
                }
                return 0;

            })
        
        let temp = chunk(tempViewOneReport, 10);
        let pages = [...Array(temp.length)].map((a,index) => index+1);
        const yData = tempViewOneReport.map(value => `${value.entityId} - ${value.entityName}`)
        let yDataTemp = chunk(yData, 10);
        const successData = tempViewOneReport.map(value => value.successCount)
        let successDataTemp = chunk(successData, 10);
        const warningData = tempViewOneReport.map(value => value.warningCount)
        let warningDataTemp = chunk(warningData, 10);
        const failureData = tempViewOneReport.map(value => value.failureCount)
        let failureDataTemp = chunk(failureData, 10);

        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                backgroundColor: '#E8F3F4',
                borderColor: '#c8e2f7',
                borderRadius: '10px',
                textStyle: {
                    color: 'black',
                },
                formatter: (param) => {
                    let res = '<div style="background-color: #1F9997; margin: -10px -10px; padding: 20px; color: white">' + param[0]?.name + '</div>' + '<br/>' + '<div>';
                    for (let x = 0; x < param?.length; x++) {
                        res += `<div style="background-color: ${param[x].color}; height: 15px; width: 15px; display: inline-block; margin-left: 10px">` + '</div>' + '&nbsp;&nbsp;' + param[x]?.seriesName + ': ' + param[x]?.data + '<br/>';
                    }
                    res += '</div>'
                    return res
                }
            },
            timeline:{
					data:pages,
					bottom:'15px',
					axisType:'value',
					autoPlay:false,
		    			tooltip:{
						formatter:function (s){
							return "Page "+s.dataIndex;
						}
					}
				},
            legend: {
                top:'10px',
				right: 'center',		   
				itemHeight: "25",
				itemGap: 30,
            },
            grid: {
                top:'10%',
                left: '3%',
                right: '4%',
                bottom: '15%',
                containLabel: true
            },
            options: [
					{
					xAxis: {
						type: 'value'
					},
					yAxis:{
						type: 'category',
						data: yDataTemp[0],
						min:0,
						max:9,
						splitNumber:10,
						axisLabel: {
							margin: 30,
							width: "90",
							overflow: "truncate",
						}
					},
					series: [
						{
							name: 'Pass',
							type: 'bar',
							stack: 'total',
							emphasis: {
								focus: 'series'
							},
							data: successDataTemp[0],
							barWidth: 25,
							barCategoryGap:'50%',	  
							itemStyle: { color: '#4CAF50' }
						},
						{
							name: 'Warning',
							type: 'bar',
							stack: 'total',
							emphasis: {
								focus: 'series'
							},
							data: warningDataTemp[0],
							barWidth: 40,
							barCategoryGap:'50%',	  
							itemStyle: { color: '#FF7D42' }
						},
						{
							name: 'Failure',
							type: 'bar',
							stack: 'total',
							emphasis: {
								focus: 'series'
							},
							data: failureDataTemp[0],
							barWidth: 40,
							barCategoryGap:'50%',	  
							itemStyle: { color: '#CB1919' }
						}
					]
					}]
        };
		
		for( let i =1; i< pages.length + 1; i++){
			let obj ={

				series: [
					{'data': successDataTemp[i]},
					{'data': warningDataTemp[i]},
					{'data': failureDataTemp[i]}
				
				],
				yAxis: {'data': yDataTemp[i]},
				
			}
			option.options.push(obj);
		}
		
		myChart.clear();
        myChart.setOption(option);

        myChart.on('click', 'series', (params) => {
            const row = viewOneReport?.find(c => c.entityId === +params.name.split('-')[0].trim());
            const reportId = +currentReport.reportTypeID === 5 ? `/${currentReport?.id}` : '';

            switch (params.seriesName) {
                case "Failure": {
                    history.push(`/report/drill/${row.entityId}/STOPPED/${row.periodStart}/${row.periodEnd}${reportId}`)
                    return;
                }
                case "Pass": {
                    history.push(`/report/drill/${row.entityId}/PASS/${row.periodStart}/${row.periodEnd}${reportId}`)
                    return;
                }
                case "Warning": {
                    history.push(`/report/drill/${row.entityId}/WARN/${row.periodStart}/${row.periodEnd}${reportId}`)
                    return;
                }
            }
        });

    }
    
        	const createMultipleLine = (divId) => {
		document.getElementById(divId).innerHTML = '';

		try {
		    echarts.dispose(document.getElementById(divId));

		} catch { }

		let myChart = echarts.init(document.getElementById(divId));

	        let tempMosReport = mosReport || [];
		const xData = tempMosReport?.map(value => value.hourly.slice(0,16).replace('T',' '))

		const maxMosData = tempMosReport?.map(value => value.maxMosCq)

		const avgMosData = tempMosReport?.map(value => value.avgMosCq)

		const medianMosData = tempMosReport?.map(value => value.medianMosCq)

		const minMosData = tempMosReport?.map(value => value.minMosCq)

		
		
		let option = {
		  tooltip: {
			trigger: 'axis',
			axisPointer: {
                    type: 'shadow'
                },
                backgroundColor: '#E8F3F4',
                borderColor: '#c8e2f7',
                borderRadius: '10px',
                textStyle: {
                    color: 'black',
                },
			 formatter: (param) => {
				let res = '<div style="background-color: #1F9997; margin: -10px -10px; padding: 20px; color: white">' + param[0]?.name + '</div>' + '<br/>' + '<div>';
				for (let x = 0; x < param?.length; x++) {
					res += `<div style="background-color: ${param[x].color}; height: 15px; width: 15px; display: inline-block; margin-left: 10px">` + '</div>' + '&nbsp;&nbsp;' + param[x]?.seriesName + ': ' + param[x]?.data.toFixed(1) + '<br/>';
				}
				res += '</div>'
				return res
			}
		  },
		  legend: {
			data: ['MIN MOS', 'MAX MOS', 'AVG MOS', 'MEDIAN MOS']
		  },
		  grid: {
			top:'10%',
			left: '3%',
			right: '4%',
			bottom: '15%',
			containLabel: true
		  },
			  xAxis: {
				type: 'category',
				boundaryGap: false,
				data: xData
			  },
			  yAxis: {
				type: 'value',
				interval:1,
				min:1,
				max:5
			  },
			dataZoom: [
				{
				 startValue: xData[0]
				},
				{
				type: 'inside'
				}
				  ],
			  series: [
			  	{
				  name: 'MIN MOS',
				  type: 'line',
				  color:'red',
				  data: minMosData
				},
				{
				  name: 'MAX MOS',
				  type: 'line',
				  data: maxMosData
				},
				{
				  name: 'AVG MOS',
				  type: 'line',
				  data: avgMosData
				},
				{
				  name: 'MEDIAN MOS',
				  type: 'line',
				  data: medianMosData
				},
				]
		};
	
		myChart.clear();
        	myChart.setOption(option);
		myChart.on('click', function(params) {
		history.push(`/report/mos/${currentReport?.id}/${params.value}/${params.name.slice(11,13)}/${params.name.slice(0,10)}`);
		});
		
	}


    const columns = useMemo(() => {
        if (currentReport && +currentReport.reportTypeID === 6) {
            return [

                { field: "id", headerName: "ID", width: "90", disableColumnMenu: true },
                {
                    field: "segmentName",
                    headerName: t("testSegments"),
                    width: "500",
                    disableColumnMenu: true,
                },
                {
                    field: "pass",
                    headerName: t("pass.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => history.push(`/report/drill/${params.row.id}/*/${params.row.periodStart}/${params.row.periodEnd}/${currentReport?.id}/success`)}>{params.row.pass}</div>
                    )
                },
                {
                    field: "warning",
                    headerName: t("warning.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => history.push(`/report/drill/${params.row.id}/*/${params.row.periodStart}/${params.row.periodEnd}/${currentReport?.id}/warning`)}>{params.row.warning}</div>
                    )
                },
                {
                    field: "failure",
                    headerName: t("failure.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => history.push(`/report/drill/${params.row.id}/*/${params.row.periodStart}/${params.row.periodEnd}/${currentReport?.id}/failure`)}>{params.row.failure}</div>
                    )
                },
                {
                    field: "total",
                    headerName: t("total.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => history.push(`/report/drill/${params.row.id}/*/${params.row.periodStart}/${params.row.periodEnd}/${currentReport?.id}`)}>{params.row.total}</div>
                    )
                }
            ]
	} else if(currentReport && +currentReport.reportTypeID === 7){
	    return [

                { field: "id", headerName: "ID", width: "90", disableColumnMenu: true },
                {
                    field: "hourly",
                    headerName: t("hourly"),
                    width: "500",
                    disableColumnMenu: true,
                },
                {
                    field: "minMosCq",
                    headerName:  t("minMos"),
                    flex: 1,
                    disableColumnMenu: true,
		    renderCell: params => (
			<div onClick={() => history.push(`/report/mos/${currentReport?.id}/${params.row.minMosCq}/${params.row.hourly.slice(11,13)}/${params.row.hourly.slice(0,10)}`)}>{params.row.minMosCq}</div>
		    )
                },
                {
                    field:  "maxMosCq",
                    headerName: t("maxMos"),
                    flex: 1,
                    disableColumnMenu: true,
	            renderCell: params => (
			<div onClick={() => history.push(`/report/mos/${currentReport?.id}/${params.row.maxMosCq}/${params.row.hourly.slice(11,13)}/${params.row.hourly.slice(0,10)}`)}>{params.row.maxMosCq}</div>
		    )
                },
                {
                    field: "avgMosCq",
                    headerName: t("avgMos"),
                    flex: 1,
                    disableColumnMenu: true,
		    renderCell: params => (
			<div onClick={() => history.push(`/report/mos/${currentReport?.id}/${params.row.avgMosCq}/${params.row.hourly.slice(11,13)}/${params.row.hourly.slice(0,10)}`)}>{params.row.avgMosCq}</div>
		    )
                },
                {
                    field: "medianMosCq",
                    headerName: t("medianMos"),
                    flex: 1,
                    disableColumnMenu: true,
		    renderCell: params => (
			<div onClick={() => history.push(`/report/mos/${currentReport?.id}/${params.row.medianMosCq}/${params.row.hourly.slice(11,13)}/${params.row.hourly.slice(0,10)}`)}>{params.row.medianMosCq}</div>
		    )
                 }
            ]
        } else {
            return [

                {
                    field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: currentReport && +currentReport.reportTypeID === 2,

                    renderCell: params => (
                        <span>{+params.row.id === 0 ? '' : params.row.id}</span>
                    )
                },
                {
                    field: "test_case_performance",
                    headerName: currentReport && +currentReport.reportTypeID === 2 ? t("individualTimeReferences") :
                        (currentReport && +currentReport.reportTypeID === 5 ? t("individualTestSegmentPerformance") : t("testCasePerformance.1")),
                    width: "500",
                    disableColumnMenu: true,
                },
                {
                    field: "pass",
                    headerName: t("pass.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => runDrillDownReport(params, 'PASS')}>{params.row.pass}</div>
                    )
                },
                {
                    field: "warning",
                    headerName: t("warning.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => runDrillDownReport(params, 'WARN')}>{params.row.warning}</div>
                    )
                },
                {
                    field: "failure",
                    headerName: t("failure.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => runDrillDownReport(params, 'STOPPED')}>{params.row.failure}</div>
                    )
                },
                {
                    field: "total",
                    headerName: t("total.1"),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: params => (
                        <div onClick={() => runDrillDownReport(params, 'TOTAL')} > {params.row.total}</div >
                    )
                }
            ]
        }


    }, [currentReport]);

    const runDrillDownReport = (params, status) => {
        if (+params.row.id !== 0) {
            if (+currentReport.reportTypeID === 2) {
                const finalStatus = status.replace('PASS', 'Pass').replace('WARN', 'Warn').replace('STOPPED', 'Fail').replace('TOTAL', 'All')
                history.push(`/report/latency/${parseInt(params.row.id.split('T')[1].substring(0, 2))}/-1/${currentReport?.id}/${finalStatus}/${params.id.split('T')[0]}`);
            } else {
                const reportId = +currentReport.reportTypeID === 5 ? `/${currentReport?.id}` : '';
                history.push(`/report/drill/${params.row.id}/${status}/${params.row.periodStart}/${params.row.periodEnd}${reportId}`)
            }
        }
    }

    const totalRows = total?.map((item, index) => {
        return {
            id: currentReport && +currentReport.reportTypeID === 6 ? (item.segmentId || index) : (item.entityId || index),
            test_case_performance: item.entityName,
            pass: `${item.successCount} (${((item.successCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            warning: `${item.warningCount} (${((item.warningCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            failure: `${item.failureCount} (${((item.failureCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            total: `${item.successCount + item.failureCount + item.warningCount}`,
            periodStart: item.periodStart,
            periodEnd: item.periodEnd,
            segmentName: item.segmentName,
        }
    })
    const rows = reportTable?.map((item, index) => {
        return {
            id: currentReport && +currentReport.reportTypeID === 6 ? (item.segmentId || index) : (item.entityId || index),
            test_case_performance: item.entityName,
            pass: `${item.successCount} (${((item.successCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            warning: `${item.warningCount} (${((item.warningCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            failure: `${item.failureCount} (${((item.failureCount / (item.successCount + item.failureCount + item.warningCount)) * 100).toFixed(2)} %)`.replace('NaN', '0.00'),
            total: `${item.successCount + item.failureCount + item.warningCount}`,
            periodStart: item.periodStart,
            periodEnd: item.periodEnd,
            segmentName: item.segmentName,
        }
    })
    
    const hourlyRows = mosReport?.map((item, index) => {
        return {
            id: index,
            hourly: item.hourly.replace('T',' ').slice(0,16),
            minMosCq: item.minMosCq.toFixed(1),
            maxMosCq: item.maxMosCq.toFixed(1),
            avgMosCq: item.avgMosCq.toFixed(1),
            medianMosCq: item.medianMosCq.toFixed(1),
        }
    })


    return (
        <div className={classes.root}
            style={{ position: "absolute", top: "0px", left: "130px" }}>
            <ContentHeader title={t("reportViewReport")} />
            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '20px',
                    display: 'flex',
                }}
            >
                <div className="db">
                    <div className="dib w-80">
                        <Typography type="body2" className={classes.title} style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {t("reportLabel.0")}: {currentReport?.name}
                        </Typography>
                    </div>
                    <div className="dib right w-20">
                        <IconButton component="span" size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadReport()
                            }
                            }
                        >
                            <img src={download} alt="pic" width="25px" />
                        </IconButton>
                    </div>
                </div>

            </Box>

            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    marginBottom: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: !currentReport || +currentReport.reportTypeID !== 2 ? 'center' : 'unset',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 0px 10px #00000029',
                    height: '60px',
                    paddingLeft: currentReport && +currentReport.reportTypeID === 2 ? '44px' : 'unset',
                    paddingRight: currentReport && +currentReport.reportTypeID === 2 ? '44px' : 'unset',
                }}
            >
                <Grid container>
                    <Grid item xs={currentReport && +currentReport.reportTypeID === 2 ? 6 : 4} textAlign='left'>
                        <Typography className={classes.detail} style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {t("reportType.0")}: {currentReport?.reportTypeName}
                        </Typography>
                    </Grid>
                    <Grid item xs={currentReport && +currentReport.reportTypeID === 2 ? 6 : 4} textAlign={currentReport && +currentReport.reportTypeID === 2 ? 'right' : 'center'}>
                        <Typography className={classes.detail} style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {t("testCaseGroup.0")}:  {currentReport?.testCaseGroupName}
                        </Typography>
                    </Grid>

                    {!currentReport || +currentReport.reportTypeID !== 2 && (
                        <Grid item xs={4} textAlign='right'>
                            <Typography className={classes.detail} style={{ fontSize: "18px", fontWeight: "bold" }}>
                                <span style={{ fontWeight: "normal" }}> {t("from")}: </span> {currentReport?.timeframeBegin} <span
                                    style={{ fontWeight: "normal" }}>{t("to")}:</span> {currentReport?.timeframeEnd}
                            </Typography>
                        </Grid>
                    )}
                </Grid>






            </Box>

            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    marginBottom: '20px',
                    display: 'block',
                    justifyContent: 'space-between',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 0px 10px #00000029',
                }}
            >
                <div id="viewReport" style={{ width: "1500px", height: (currentReport && +currentReport.reportTypeID !== 6 && +currentReport.reportTypeID !== 2 ? '900px' : '600px') }}>
                </div>

                {currentReport && +currentReport.reportTypeID === 6 && (
                    <div className="latency-legend">
                        <div>&nbsp;</div> <Typography variant="subtitle3">{t("pass.0")}</Typography>
                        <div className="bg-warning">&nbsp;</div> <Typography variant="subtitle3">{t("warning.0")}</Typography>
                        <div className="failure">&nbsp;</div> <Typography variant="subtitle3">{t("failure.0")}</Typography>
                    </div>
                )}
            </Box>

            {currentReport && +currentReport.reportTypeID === 2 && (
                <Box textAlign='left'
                    sx={{
                        minWidth: "500px",
                        marginBottom: '20px',
                        display: 'block',
                        justifyContent: 'space-between',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 0px 10px #00000029',
                    }}
                >
                    <Box textAlign='left'
                        sx={{
                            marginBottom: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 0px 10px #00000029',
                            height: '60px',
                            paddingLeft: '44px',
                            paddingRight: '44px',
                        }}
                    >
                        <Typography sx={{ width: '50%' }} className={classes.detail} style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {t("zoomforDateRange")}
                        </Typography>

                        <Typography sx={{ width: '50%', textAlign: 'right' }} className={classes.detail} style={{ fontSize: "18px", fontWeight: "bold" }}>
                            <span style={{ fontWeight: "normal" }}> {t("from")}: </span> {currentReport?.timeframeBegin} <span
                                style={{ fontWeight: "normal" }}>{t("to")}:</span> {currentReport?.timeframeEnd}
                        </Typography>

                    </Box>
                    <div id="viewReportZoom" style={{ width: "1500px", height: '400px' }}>
                    </div>
                </Box>
            )}
	    
	    {currentReport && +currentReport.reportTypeID  !== 7 && (
               <Box style={{ height: "110px", maxWidth: "100%" }}
                sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeaders": {
                        visibility: "hidden",
                        width: "0 !important"
                    },
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                        opacity: 0.3
                    },
                    '& .super-app-theme--total': {
                        backgroundColor: "#E8F3F4 !important"
                    },
                    '& .underscore': {
                        textDecoration: "underline",
                        color: "#03A9F4"
                    },
                    '& .pass': {
                        color: "#4CAF50"
                    },
                    '& .warning': {
                        color: "#FF7D42"
                    },
                    '& .failures': {
                        color: "#CB1919"
                    },
                }}>
                {totalRows && totalRows.length > 0 ? <DataGrid rows={totalRows} columns={columns}
                    rowHeight={30}
                    pageSize={1}
                    page={0}
                    components={{
                        Footer: () => null
                    }}
                    getRowClassName={(params) => {
                        if (!params.id)
                            return `super-app-theme--total`
                    }}

                    getCellClassName={(params) => {
                        if (+params.id === 0 && params.field === 'pass') {
                            return 'pass pointer'
                        }
                        if (+params.id === 0 && params.field === 'warning') {
                            return 'warning pointer'
                        }
                        if (+params.id === 0 && params.field === 'failure') {
                            return 'failures pointer'
                        }
                        if (+params.id === 0 && params.field === 'total') {
                            return ''
                        }
                        if (params.id && (params.field === 'pass' || params.field === 'warning' || params.field === 'failure' || params.field === 'total')) {
                            return 'underscore pointer'
                        }

                    }}
                /> : null}
            </Box>
	    )}
	    
	    			{currentReport && +currentReport.reportTypeID === 7 ? (
				<Box style={{ height: "500px", maxWidth: "100%" }}
                sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important"
                    },
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                        opacity: 0.3
                    },
                    '& .super-app-theme--total': {
                        backgroundColor: "#E8F3F4 !important"
                    },
                    '& .minMosCq': {
                        color: "#FF0000"
                    },
                    '& .maxMosCq': {
                        color: "#03A9F4"
                    },
                    '& .avgMosCq': {
                        color: "#4CAF50"
                    },
                    '& .medianMosCq': {
                        color:  "#FF9933"
                    },
                }}
            >

                {hourlyRows && hourlyRows.length > 0 ? <DataGrid rows={hourlyRows} columns={columns}
                    rowHeight={30}
                    pageSize={rowPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    page={page - 1}
                    sortingOrder={['desc', 'asc']}
                    components={{
                        Footer: () => CustomFooter(page, handlePageChange, hourlyRows, rowPerPage, setRowPerPage, setPage)
                    }}
                    getRowClassName={(params) => {
                        if (!params.id)
                            return `super-app-theme--total`
                    }}

                    getCellClassName={(params) => {
                        if (params.field === 'minMosCq') {
                            return 'minMosCq pointer'
                        }
                        if (params.field === 'maxMosCq') {
                            return 'maxMosCq pointer'
                        }
                        if (params.field === 'avgMosCq') {
                            return 'avgMosCq pointer'
                        }
                        if (params.field === 'medianMosCq') {
                            return 'medianMosCq pointer'
                        }

                    }}
                /> : null}

            </Box>
			
			
	):(
            <Box style={{ height: "500px", maxWidth: "100%" }}
                sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important"
                    },
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                        opacity: 0.3
                    },
                    '& .super-app-theme--total': {
                        backgroundColor: "#E8F3F4 !important"
                    },
                    '& .underscore': {
                        textDecoration: "underline",
                        color: "#03A9F4"
                    },
                    '& .pass': {
                        color: "#4CAF50"
                    },
                    '& .warning': {
                        color: "#FF7D42"
                    },
                    '& .failures': {
                        color: "#CB1919"
                    },
                }}
            >

                {rows && rows.length > 0 ? <DataGrid rows={rows} columns={columns}
                    rowHeight={30}
                    pageSize={rowPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    page={page - 1}
                    sortingOrder={['desc', 'asc']}
                    components={{
                        Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                    }}
                    getRowClassName={(params) => {
                        if (!params.id)
                            return `super-app-theme--total`
                    }}

                    getCellClassName={(params) => {
                        if (+params.id === 0 && params.field === 'pass') {
                            return 'pass pointer'
                        }
                        if (+params.id === 0 && params.field === 'warning') {
                            return 'warning pointer'
                        }
                        if (+params.id === 0 && params.field === 'failure') {
                            return 'failures pointer'
                        }
                        if (+params.id === 0 && params.field === 'total') {
                            return ''
                        }
                        if (params.id && (params.field === 'pass' || params.field === 'warning' || params.field === 'failure' || params.field === 'total')) {
                            return 'underscore pointer'
                        }

                    }}
                /> : null}

            </Box>
	)}
        </div>

    )
}

export default ViewReportContent
