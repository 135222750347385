import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from "@mui/styles";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from "@mui/material";
import Badge from '@mui/material/Badge';
import { useHistory } from "react-router-dom";
import { ReportTypes, SETTINGS_REPORT } from '../../helper/report-helper';
import { useSelector } from 'react-redux';
import { getUserId, isAdmin } from '../../helper/user';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    top: "65px",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: 'linear-gradient(180deg, #1F9997 0%, #2E6665 57%, #636364 100%)',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile ? 0 : `calc(${theme.spacing(8)} + 1px)`,
    top: "58px",
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
        top: "65px"
    },
    background: 'linear-gradient(180deg, #1F9997 0%, #2E6665 57%, #636364 100%)',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const useStyles = makeStyles((theme) => ({
    drawer: {
        position: "absolute",
        marginLeft: "auto",
        top: "200px",
    },
}));

const LeftBar = ({ page, opendrawer = null }) => {
    const _isAdmin = isAdmin();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    let history = useHistory()

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const reportLink = () => {
        history.push("/reports")
    }

    const dashboardLink = () => {
        // document.location.href = "/dashboard";
        history.push("/dashboard")
    }

    const alertLink = () => {
        history.push("/alerts")
    }

    const settingLink = () => {
        history.push("/setting")
    }

    const logout = () => {
        localStorage.clear();
        document.location.href = "/";
    }
    const reportsClicked = (id, url) => {
        if (+id === 0) {
            history.push(`/users/${getUserId()}`)
        } else {
            history.push(`/${url}/${id}`)
        }
    }

    useEffect(() => {
        if (opendrawer) {
            handleDrawerOpen();
        } else {
            handleDrawerClose();
        }
    }, [opendrawer])

    return (
        <div style={{ position: "relative" }}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Drawer id="drawer" variant="permanent" open={open} onMouseEnter={handleDrawerOpen}
                    onMouseLeave={handleDrawerClose} className={classes.drawer}
                >
                    <Divider />
                    <List>
                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: open ? 'initial' : 'center',
                                px: open ? 2 : 9,
                            }}
                            onClick={() => dashboardLink()}
                        >
                            <div
                                style={{
                                    width: open ? "220px" : "130px",
                                    display: "flex",
                                    padding: "5px",
                                    justifyContent: open ? 'space-between' : 'center',
                                    alignItems: open ? 'space-between' : 'center',
                                    px: 4,
                                    borderRadius: open && page[0] ? "30px" : "",
                                    border: open && page[0] ? "1px solid grey" : "",
                                    backgroundColor: open && page[0] ? "white" : "",
                                    opacity: open && page[0] ? "0.5" : 1
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        paddingLeft: (!page[0] && !open) ? "10px" : undefined, 
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        border: page[0] && !open ? "10px solid white" : "",
                                        borderRadius: page[0] && !open ? "20px" : "",
                                    }}
                                >
                                    <AccessTimeIcon
                                        style={{ color: open && page[0] ? "black" : "#FFFFFF", width: "25px" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography type="body2"
                                        style={{ color: open && page[0] ? "black" : "#FFFFFF" }}>{t("dashboard.0")}</Typography>}
                                    sx={{ opacity: open ? 1 : 0 }} />
                                <ArrowForwardIosIcon sx={{
                                    opacity: open ? 1 : 0,
                                    width: "12px",
                                    color: open && page[0] ? "black" : "#FFFFFF"
                                }}
                                />
                            </div>


                        </ListItemButton>

                        {!isMobile && (
                            <ListItemButton
                                sx={{
                                    minHeight: 30,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: open ? 2 : 8,
                                }}

                                onClick={() => reportLink()}
                            >
                                <div
                                    style={{
                                        width: open ? "220px" : "130px",
                                        display: "flex",
                                        padding: "5px",
                                        justifyContent: open ? 'space-between' : 'center',
                                        alignItems: open ? 'space-between' : 'center',
                                        px: 4,
                                        borderRadius: open && page[1] ? "30px" : "",
                                        border: open && page[1] ? "1px solid grey" : "",
                                        backgroundColor: open && page[1] ? "white" : "",
                                        opacity: open && page[1] ? "0.5" : 1
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            marginLeft: !open ? (!page[1] ? "20px" : "5px") : undefined, 
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            border: page[1] && !open ? "10px solid white" : "",
                                            borderRadius: page[1] && !open ? "20px" : "",

                                        }}
                                    >
                                        <LibraryBooksIcon
                                            style={{ color: open && page[1] ? "black" : "#FFFFFF", width: "20px" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography type="body2"
                                            style={{ color: open && page[1] ? "black" : "#FFFFFF" }}>{t("reports.0")}</Typography>}
                                        sx={{ opacity: open ? 1 : 0 }} />
                                    <ArrowForwardIosIcon sx={{
                                        opacity: open ? 1 : 0,
                                        width: "12px",
                                        color: open && page[1] ? "black" : "#FFFFFF"
                                    }}
                                    />
                                </div>

                            </ListItemButton>
                        )}

                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: open ? 'initial' : 'center',
                                px: open ? 2 : 6.5,
                            }}
                            onClick={() => alertLink()}
                        >
                            <div
                                style={{
                                    width: open ? "220px" : "130px",
                                    display: "flex",
                                    padding: "5px",
                                    justifyContent: open ? 'space-between' : 'center',
                                    alignItems: open ? 'space-between' : 'center',
                                    px: 4,
                                    borderRadius: open && page[2] ? "30px" : "",
                                    border: open && page[2] ? "1px solid grey" : "",
                                    backgroundColor: open && page[2] ? "white" : "",
                                    opacity: open && page[2] ? "0.5" : 1
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        marginLeft: !open ? "20px" : undefined, 
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        border: page[2] && !open ? "10px solid white" : "",
                                        borderRadius: page[2] && !open ? "20px" : "",
                                    }}
                                >
                                    <NotificationsNoneIcon style={{ color: open && page[2] ? "black" : "#FFFFFF" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography type="body2"
                                        style={{ color: open && page[2] ? "black" : "#FFFFFF" }}>{t("alerts.0")}</Typography>}
                                    sx={{ opacity: open ? 1 : 0 }} />
                                <ArrowForwardIosIcon sx={{
                                    opacity: open ? 1 : 0,
                                    width: "12px",
                                    color: open && page[2] ? "black" : "#FFFFFF"
                                }}
                                />
                            </div>


                        </ListItemButton>

                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: open ? 'initial' : 'center',
                                px: open ? 2 : 8,
                            }}

                        >
                            <div
                                style={{
                                    width: open ? "220px" : "130px",
                                    display: "flex",
                                    padding: "5px",
                                    justifyContent: open ? 'space-between' : 'center',
                                    alignItems: open ? 'space-between' : 'center',
                                    px: 4,
                                    borderRadius: open && page[3] ? "30px" : "",
                                    border: open && page[3] ? "1px solid grey" : "",
                                    backgroundColor: open && page[3] ? "white" : "",
                                    opacity: open && page[3] ? "0.5" : 1
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        marginLeft: !open ? (page[3] ? "5px" : "15px") : undefined, 
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        border: page[3] && !open ? "10px solid white" : "",
                                        borderRadius: page[3] && !open ? "20px" : "",
                                    }}
                                >
                                    <SettingsIcon
                                        style={{ color: open && page[3] ? "black" : "#FFFFFF", width: "20px" }}
                                    />
                                </ListItemIcon>

                                <ListItemText
                                    primary={<Typography type="body2"
                                        style={{ color: open && page[3] ? "black" : "#FFFFFF" }}>{t("settings.0")}</Typography>}
                                    sx={{ opacity: open ? 1 : 0 }} />
                                <ArrowForwardIosIcon sx={{
                                    opacity: open ? 1 : 0,
                                    width: "12px",
                                    color: open && page[3] ? "black" : "#FFFFFF"
                                }}
                                />
                            </div>


                        </ListItemButton>


                        {
                            _isAdmin && SETTINGS_REPORT.filter(s => !isMobile || (isMobile && s.id === ReportTypes.ALERT)).map(({ title, id, url }, index) =>
                                <ListItemButton key={index}
                                    sx={{
                                        minHeight: 30,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 4,
                                    }}
                                    onClick={(e) => reportsClicked(id, url)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 5 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2"
                                        style={{ color: '#FFFFFF' }}>{id == 1 ? t("reportTypes.0") : (id == 2 ? t("reportTypes.1") : (id == 3 ? t("reportTypes.2") : (id == 4 ? t("reportTypes.3") : '')))}</Typography>}
                                        sx={{ opacity: open ? 1 : 0 }} />

                                </ListItemButton>
                            )
                        }
                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: open ? 'initial' : 'center',
                                px: 4,
                            }}
                            onClick={(e) => reportsClicked(0)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 5 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2"
                                style={{ color: '#FFFFFF' }}>{t("userProfile")}</Typography>}
                                sx={{ opacity: open ? 1 : 0 }} />

                        </ListItemButton>

                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: open ? 'initial' : 'center',
                                px: open ? 2 : 9,
                            }}
                            onClick={() => logout()}
                        >
                            <div
                                style={{
                                    width: open ? "220px" : "130px",
                                    display: "flex",
                                    padding: "5px",
                                    justifyContent: open ? 'space-between' : 'center',
                                    alignItems: open ? 'space-between' : 'center',
                                    px: 4,
                                    opacity: 1
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ExitToAppIcon
                                        style={{ color: "#FFFFFF", width: "25px" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography type="body2"
                                        style={{ color: "#FFFFFF" }}>{t("signOut")}</Typography>}
                                    sx={{ opacity: open ? 1 : 0 }} />

                            </div>


                        </ListItemButton>
                    </List>
                </Drawer>
            </Box>
        </div>
    );
}

export default LeftBar
