import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton, Typography,
} from "@mui/material";
import trashCan from "../../images/delete_outline_black_24dp.svg";
import { isMobile } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';


const Modal = ({ title, ButtonDelete, isOpen, openHash, statement, body, overflow, small, primaryButton, xl }) => {

    const [open, setOpen] = useState(() => isOpen);

    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen, openHash])

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={!isMobile && body && !small ? true : false} maxWidth={xl ? 'xl' : !isMobile && body && !small ? 'lg' : 'sm'}
            disableAutoFocus={true}>
            {title && (
                <DialogTitle id="responsive-dialog-title"
                    sx={{
                        backgroundColor: "#E8F3F4",
                        textAlign: "center",
                        fontSize: "18px"
                    }}
                >{title}

                    <IconButton className='modal-close-icon' aria-label="close" handleClose onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent style={body && !overflow ? { overflow: 'hidden' } : {}}>
                {!body ? (
                    <DialogContentText sx={{
                        fontSize: "14px",
                        textAlign: "center"
                    }}>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Are you sure you want to delete {statement}?</span>
                        <br />
                        This action cannot be undone.
                    </DialogContentText>

                ) : (
                    <>
                        {body}
                    </>
                )}

            </DialogContent>

            {ButtonDelete && (
                <DialogActions
                    sx={{
                        backgroundColor: "#E8F3F4"
                    }}
                >
                    <Button onClick={handleClose}>
                        <Typography style={{ textTransform: 'none', color: "grey" }}>Cancel</Typography>
                    </Button>

                    <Button style={{ backgroundColor: primaryButton ? '#1F9997' : "#CB1919" }}
                        onClick={() => {
                            ButtonDelete()
                            handleClose()
                        }}
                    >
                        {!primaryButton && (
                            <img src={trashCan} width="18px" alt="pic" />
                        )}

                        <Typography style={{ textTransform: 'none', color: "white" }}>{primaryButton || 'Delete'}</Typography>
                    </Button>

                </DialogActions>
            )}
        </Dialog>
    )
}

export default Modal;