import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";
import CaseGroup from "../../../helper/CaseGroup.js";
import sumLogo from "../../../images/2638347_auto_board_car_dash_limit_icon.svg"
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { Telco } from "../Telco.js";
import { getSyetemComponents } from "../../../actions/index.js";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    successRate: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,

    },

    result: {
        background: 'linear-gradient(180deg, #1F9997 0%, #2E6665 57%, #636364 100%)',
        height: "8rem",
        flexGrow: 1,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "5px",
    },
    rateTotal: {
        margin: theme.spacing(2),
        flexGrow: 1,
        textAlign: "center",
    },
    testRun: {
        margin: theme.spacing(3, 0, 0),
        flexGrow: 1,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    sum: {
        color: "#1F9997",
    },
    success: {
        color: "#FFFFFF",
    },


}));

const ContentKpis = ({
    dashboardSelection,
    setDashboardSelection
}) => {
    const classes = useStyles();
    const [all, setAll] = useState(null)
    const [data, setData] = useState(null)
    const { t } = useTranslation();

    const caseGroups = useSelector(state => state.loginReducer.caseGroups)

    useEffect(() => {
        if (caseGroups) {
            let tempAll = {}
            tempAll.total_testRuns = caseGroups.reduce((total, group) => total + group.testRuns, 0);

            tempAll.total_passCount = caseGroups.reduce((total, group) => total + group.counts[0], 0);
            tempAll.total_warnCount = caseGroups.reduce((total, group) => total + group.counts[1], 0);
            tempAll.total_failCount = caseGroups.reduce((total, group) => total + group.counts[2], 0);

            tempAll.overall_passPct = tempAll.total_passCount / tempAll.total_testRuns

            tempAll.total_passPct = tempAll.total_passCount / tempAll.total_testRuns;
            tempAll.total_warnPct = tempAll.total_warnCount / tempAll.total_testRuns;
            tempAll.total_failPct = tempAll.total_failCount / tempAll.total_testRuns;
            setAll(tempAll);
            loadData();
        }

    }, [caseGroups])

    const loadData = async () => {
        const d = await getSyetemComponents();
        setData(d);
    }

    return (
        data?.length ? (
            <Telco contentKpis={all} data={data} dashboardSelection={dashboardSelection} setDashboardSelection={setDashboardSelection} />
        ) : (
            <div>Loading</div>
        )
    );
};

export default ContentKpis;
