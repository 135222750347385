
export const STORAGE_KEY = 'AC_API_API';

export const getACApi = () => {
    const data = localStorage.getItem(STORAGE_KEY);

    if (!data) {
        return null;
    };

    return JSON.parse(data);
}

export const getVoiceApi = () => getACApi()?.digital || process.env.REACT_APP_ServerBaseURL;
