/**
 * @file CaseGroup.js 
 * @author ronb <rbakowski@connexservice.ca>
 * @version 0.0.1
 * @copyright &copy; 2022, Connex Telecommunications Inc.
 */

const moment = require("moment");

/**
 * Transforms the raw JSON results from the Lens API into a simpler object specific for the Action Center "dashboard"
 * <br>
 * Each entry in the array is a "Case Group"<br>
 * Each Case Group has an hourlyStatus array containing the statuses of tests run
 * on the give date and time for each status type<br>
 * <br>
 * This class simplifies the Case Group structure and provides a method to retrieve
 * the 'hourlyStatus' filtered by date and status type (pass, fail, warn)
 */
class CaseGroup {
  id = "";
  name = "";
  periodStart = "";
  hourlyStatus = [];

  testRuns = 0;
  counts = [0, 0, 0];
  countsPct = [0.0, 0.0, 0.0];
  channel = "";

  /**
   * Constructs an instance of a CaseGroup
   *
   * @param {string} name - required name
   * @param {string} id - required id
   * @param {Object} group - this is the raw JSON group returned from the API, if not provided (null), the following 4 params should be
   * @param {int} successCount - total number of successful tests
   * @param {int} failureCount - total number of failed tests
   * @param {int} warningCount - total number of tests with warnings
   * @param {Object[]} hourlyStatus - array of hourly test statuses and the counts of each status type indexed by hour
   * <pre>
   * [
   *    {
   *       "hourly": "2022-01-01T01:00:00",
   *       "count": [3, 1, 0]
   *    },
   *    .
   *    .
   *    .
   * ]
   * </pre> 
   * @returns {CaseGroup} - a CaseGroup object:
   * <pre>
   * {
   *   "entityName": "Manulife Group Benefit - 877-405-1992",
   *   "entityId": 16481,
   *   "startDate": "2022-01-01T01:00:00",
   *   "testRuns": 97,
   *   "counts": [87, 9, 1],
   *   "countsPct": [0.896907, 0.092783, 0.010309],
   *   "hourlyStatus": [
   *     {
   *       "hourly": "2022-01-01T01:00:00",
   *       "count": [3, 1, 0]
   *     }
   *   }
   * }
   * </pre>
   */
  constructor(
    name,
    id,
    group = null,
    successCount = 0,
    warningCount = 0,
    failureCount = 0,
    hourlyStatus = []

  ) {

    const PASS = 0;
    const WARN = 1;
    const FAIL = 2;

    this.name = name;
    this.id = id;
    this.periodStart = "";
    this.counts = [0, 0, 0];
    this.perCounts = [0, 0, 0];
    this.countsPcts = [0.0, 0.0, 0.0];

    // construct based on give group or detailed data
    if (group) {
      this.periodStart = group.periodStart;
      this.counts[PASS] += group.successCount;
      this.counts[WARN] += group.warningCount;
      this.counts[FAIL] += group.failureCount;
      this.perCounts[PASS] += (group.successCount / (group.successCount + group.warningCount + group.failureCount)) * 100 || 0;
      this.perCounts[WARN] += (group.warningCount / (group.successCount + group.warningCount + group.failureCount)) * 100 || 0;
      this.perCounts[FAIL] += (group.failureCount / (group.successCount + group.warningCount + group.failureCount)) * 100 || 0;
      group.hourlyStatus.forEach((entry) => {
        this.hourlyStatus.push({
          hourly: entry.hourly,
          count: [entry.successCount, entry.warningCount, entry.failureCount],
        });
      });
    } else {
      this.counts[PASS] = successCount;
      this.counts[WARN] = warningCount;
      this.counts[FAIL] = failureCount;
      this.perCounts[PASS] = (successCount / (successCount + warningCount + failureCount)) * 100;
      this.perCounts[WARN] = (warningCount / (successCount + warningCount + failureCount)) * 100;
      this.perCounts[FAIL] = (failureCount / (successCount + warningCount + failureCount)) * 100;
      this.hourlyStatus = hourlyStatus;
      if (hourlyStatus.length > 0) {
        this.periodStart = hourlyStatus[0].time;
      }
    }

    this.testRuns = this.counts.reduce((total, count) => total + count, 0);
    this.countsPct = this.countsPct.map((ignore, i) => this.testRuns > 0.0 ? this.counts[i] / this.testRuns : 0.0);
    this.channel = group?.channel;
  }

  // --------------------------------------------------------------------------

  /**
   * Parse input JSON into something usable
   * @param {Object[]} groups - response JSON from the API call
   * @returns {CaseGroup[]} - list of CaseGroups, where the first one is an aggregation of the rest
   */
  static transformCaseGroups(groups) {
    let caseGroups = [];

    groups.forEach((group) => {
      caseGroups.push(new CaseGroup(group.entityName, group.entityId, group));
    });

    return caseGroups;
  }

  /**
   * The standard status types used throughout this class
   */
  static statusTypes = ["Pass Rate", "Warning Rate", "Failure Rate",];

  /**
   * statusHistory
   * @param {string} day - in ISO format, eg. "2022-01-01T23:59:59.990"
   * @param {int} statusType - 0, 1 or 2 corresponding to above statusTypes
   * @returns {Object[]} - list of statuses for the day and type provided
   */
  statusHistory(day, statusType = 0) {
    var entries = [];

    // compare only the dates, not the times
    let target = new Date(day).toDateString();
    this.hourlyStatus.forEach((entry) => {
      let dt = new Date(entry.hourly).toDateString();
      if (dt === target) {
        let tm = moment.utc(entry.hourly).format("HH:mm");
        entries.push({
          time: tm,
          count: entry.count[statusType],
        });
      }
    });
    return entries;
  }
}

export default CaseGroup;