import { alpha, AppBar, Avatar, Box, FormControl, MenuItem, IconButton,Select, Toolbar, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import tekvision from "../../images/tek.svg";
import avatar from "../../images/11.png"
import React, { useEffect, useState } from "react";
import SuccessAlert from "../mui/SuccessAlert";
import { useDispatch, useSelector } from "react-redux";
import { ShowGlobalErrorAlert, toggleGlobalAlert } from "../../actions";
import LeftBar from "./LeftBar";
import { isMobile } from 'react-device-detect';
import MenuIcon from '@mui/icons-material/Menu';
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        height: "50px",
        margin: "0 -15px 0 -15px",
    },
    logoLg: {
        display: "none",
        [theme.breakpoints.up("xs")]: {
            display: "flex",
            alignItems: 'center',

            '& img': {
                height:'100%',
                width:'200px',
                paddingTop: '0',
            }
        },
    },

    search: {
        display: "flex",
        alignItems: "center",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: theme.shape.borderRadius,
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            display: (props) => (props.open ? "flex" : "none"),
            width: "70%",
        },
    },
    input: {
        color: "white",
        marginLeft: theme.spacing(1),
    },
    cancel: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    searchButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    icons: {
        alignItems: "center",
        display: (props) => (props.open ? "none" : "flex"),
    },
    badge: {
        marginRight: theme.spacing(2),
    },
}));

const Navbar = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const globalAlert = useSelector(state => state.reportReducer.globalAlert);
    const globalErrorAlert = useSelector(state => state.reportReducer.globalErrorAlert);
    const [openDrawer, setOpenDrawer] = useState(null);

    const handleChange = (event) => {
	localStorage.setItem('selected-value',event.target.value);
        setLanguage(event.target.value);
        console.log('language ',i18n.language)

    };
    
    function getSelected(){
		return localStorage.getItem('selected-value');
	}
	
    const [language, setLanguage] = useState(getSelected());
	
    useEffect(() => {
		 
	i18n.changeLanguage(language);
		 
	}, [language]);
	
    const toggleDrawer = () => {
        if (!openDrawer) {
            setOpenDrawer('123');

        } else {
            setOpenDrawer(null);
        }
    }

    useEffect(() => {
        if (globalErrorAlert) {
            setShowErrorAlert(true);

            setTimeout(() => {
                ShowGlobalErrorAlert(null)(dispatch);
            }, 6000);
        } else {
            setShowErrorAlert(false);
        }

    }, [globalErrorAlert]);

    useEffect(() => {
        if (globalAlert) {
            setShowAlert(true);

            setTimeout(() => {
                toggleGlobalAlert('')(dispatch);
            }, 6000);
        } else {
            setShowAlert(false);
        }

    }, [globalAlert]);

    return (
        <AppBar position="fixed" color="transparent">
            <Toolbar className={classes.toolbar}>
                {/* {isMobile ? (
                    <div className={classes.logoLg}>
                        <IconButton
                            size="large"
                            edge="start"
                            onClick={toggleDrawer}
                            aria-label="menu"
                            sx={{ ml: 1 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6">
                            <img src={tekvision} alt="pic" />
                        </Typography>
                    </div>
                ) : ( */}
                <Typography variant="h6" className={classes.logoLg}>
                   <img src={tekvision} alt="pic" />
                </Typography>
                {/* )} */}

                <div className={classes.icons}>
                    {<FormControl fullWidth>
                        <Select
                            value={language}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            renderValue={(language) => language=="en" ? "English":(language=="fr" ? "French":'English')}
                            size="small"
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                        </Select>
                    </FormControl> }

                    <Avatar
                        alt="Demo User"

                        sx={{ width: "30px", height: "30px", margin: "10px" }}
                    />
                </div>
            </Toolbar>
            <SuccessAlert openAlert={showAlert} statement={globalAlert || ''} />
            <SuccessAlert openAlert={showErrorAlert} statement={globalErrorAlert || ''} fail="error" />
            {/* {
                isMobile && (
                    <LeftBar opendrawer={openDrawer} page={[1, 0, 0, 0]} />
                )
            } */}
        </AppBar >
    );
};

export default Navbar;
