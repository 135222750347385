import React, {useEffect} from "react";
import Navbar from "../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../dashboard/LeftBar";
import ReportList from "./ReportList";
import {useDispatch, useSelector} from "react-redux";
import {getReports, getReportTypes, getTimeframe, groupsList} from "../../actions";

const Report = () => {
    const defaultPage = [0, 1, 0, 0]
    const dispatch = useDispatch()
    const selectedChannel = useSelector((state) => state.loginReducer.selectedChannel);
    useEffect(()=>{
        getReports()(dispatch)
        getReportTypes(selectedChannel)(dispatch)
        groupsList()(dispatch)
        getTimeframe()(dispatch)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Navbar/>
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <ReportList/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Report;
