import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as echarts from "echarts";
import { t } from "i18next";
import { isMobile } from "react-device-detect";

const PASS = 0
const WARN = 1
const FAIL = 2
const WARNCOLOR = "rgb(255, 125, 66)"
const FAILCOLOR = "red"
const PASSCOLOR = 'rgb(76, 175, 80)';

export const TestRunResult = () => {
    const [contentKpis, setAll] = useState(null);
    const caseGroups = useSelector(state => state.loginReducer.caseGroups)

    useEffect(() => {
        if (caseGroups) {
            let tempAll = {}
            tempAll.total_testRuns = caseGroups.reduce((total, group) => total + group.testRuns, 0);

            tempAll.total_passCount = caseGroups.reduce((total, group) => total + group.counts[0], 0);
            tempAll.total_warnCount = caseGroups.reduce((total, group) => total + group.counts[1], 0);
            tempAll.total_failCount = caseGroups.reduce((total, group) => total + group.counts[2], 0);

            tempAll.overall_passPct = tempAll.total_passCount / tempAll.total_testRuns

            tempAll.total_passPct = tempAll.total_passCount / tempAll.total_testRuns;
            tempAll.total_warnPct = tempAll.total_warnCount / tempAll.total_testRuns;
            tempAll.total_failPct = tempAll.total_failCount / tempAll.total_testRuns;
            setAll(tempAll);


            let myChart = echarts.init(document.getElementById("donuts"));
            let option = {
                width: '230px',
                height:'230px',
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['55%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: tempAll?.total_passPct || 0, name: 'PASS', itemStyle: { color: PASSCOLOR } },
                            { value: tempAll?.total_warnPct || 0, name: 'warning', itemStyle: { color: WARNCOLOR } },
                            { value: tempAll?.total_failPct || 0, name: 'fail', itemStyle: { color: FAILCOLOR } },
                        ]
                    }
                ]
            };

            myChart.clear();
            myChart.setOption(option);
        }

    }, [caseGroups])

    return (
        <div className="run-result-container">
            <div className="title-run-result">{t("Overall Health")}</div>
            <div className="dib w-40 relative v-top">
                <div id={"donuts"} style={{ height: '230px', width: '230px' }}></div>
                <div className="absolute summary-total">
                    <div className="title">{t("Total Calls")}</div>
                    <div className="total pb-18">{contentKpis?.total_testRuns || 0}</div>
                </div>
            </div>

            <div className="dib w-50 v-top pt-40" style={{width: isMobile ? '-webkit-fill-available' : '', marginRight: isMobile ? '40px': ''}}>
                <div className="flex pb-18">
                    <div className="pass rec-30"></div>
                    <div className="inline-title"><span>{t("pass.0")}</span> <div className="middle-per">{Math.round((contentKpis?.total_passPct || 0) * 100)}%</div>
                        <div>{Math.round((contentKpis?.total_passCount || 0))}</div></div>
                </div>
                <div className="flex  pb-18">
                    <div className="warning rec-30"></div>
                    <div className="inline-title"><span>{t("warning.0")}</span> <div className="middle-per">{Math.round((contentKpis?.total_warnPct || 0) * 100)}%</div>
                        <div>{Math.round((contentKpis?.total_warnCount || 0))}</div></div>
                </div>
                <div className="flex">
                    <div className="fail rec-30"></div>
                    <div className="inline-title"><span>{t("failure.0")}</span><div className="middle-per">{Math.round((contentKpis?.total_failPct || 0) * 100)}%</div>
                        <div>{Math.round((contentKpis?.total_failCount || 0))}</div></div>
                </div>
            </div>

        </div>
    )
}