import { useEffect } from "react"
import { ssoApi } from "../../api/lensService";

export const CallBack = ()=> {
    const getToken = async (id)=> {
        if(!id) {
            document.location.href = "/login";
        } else {
            const result = await ssoApi.get(`/auth/getToken?id=${id}`);
            localStorage.setItem('TOKEN', result?.data?.data?.accessToken);
            localStorage.setItem("user", JSON.stringify({ ...result?.data?.data, remember: result?.data?.data?.remember }));
            document.location.href = "/dashboard";
        }
    }

    useEffect(()=> {
        const id = window.location.search.replace('?id=','');
        getToken(id);
    },[])

    return null;
}