import React from "react";
import ContentDetail from "./reportList/ContentDetail";
import ContentHeader from "../mui/ContentHeader";
import {useTranslation} from "react-i18next";

const ReportList = () => {
    //const reports = "REPORTS"
    const {t} = useTranslation();
    
    return (
        <div style={{position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)`}}>
            <ContentHeader title={t("reports.1")}/>
            <ContentDetail/>
        </div>
    );
};

export default ReportList;
