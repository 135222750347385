import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Toolbar,
    Typography,
    AppBar
} from "@mui/material";
import tekvision from "../../images/tek.svg";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
    page: {
        opacity: 1,
    },
    logo: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        height: "50px",
        margin: "0 -15px 0 -15px",
    },
    logoLg: {
        display: "none",
       
        [theme.breakpoints.up("xs")]: {
            display: "flex",
            alignItems: 'center',

            '& img': {
                height: '100%',
                width: '200px',
                paddingTop: '0',
            }
        },
    },
    login: {
        color: "#707070",
    },
}));

const Header = () => {
    const classes = useStyles();
    const [language, setLanguage] = useState("en");

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
    };

    return (
        <AppBar position="relative" >
            <Toolbar className={classes.logo}>
                <Typography variant="h6" className={classes.logoLg}>
                    <img src={tekvision} alt="pic" />
                </Typography>
                <div>
                    {<FormControl>
                        <Select
                            value={language}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            size="small"
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                        </Select>
                    </FormControl>}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
