import { Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../mui/ContentHeader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { getSettingsReports, getUserById, resetPassword, upsertUser } from "../../actions";
import { ReportTypes } from "../../helper/report-helper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../mui/Modal";
import { getRole, isAdmin } from "../../helper/user";
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";

const schema = yup.object().shape({
    firstname: yup.string().required("Required").trim(),
    lastname: yup.string().required("Required").trim(),
    username: yup.string().required("Required").trim(),
});


const EditUser = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory()
    const [selected, setSelected] = useState([0]);
    const [hash, setHash] = useState("init");
    const [editOpen, setEditOpen] = useState(false);
    const [pwd, setPwd] = useState('');
    const [current, setCurrent] = useState('');
    const [confirm, setConfirm] = useState('');
    const testCaseGroup = useSelector(state => state.reportReducer.testCaseGroup);
    const userInfoDetail = useSelector(state => state.reportReducer.userInfoDetail);
    const adminUser = isAdmin();
    const {t} = useTranslation();

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { isDirty, isValid, errors },
        reset,
        control,
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: {
            firstname: '', lastname: '', username: '',
            password: '', permissions: '',
        }
    });
    const watchRole = watch("permissions", '');

    const handleSaveSubmit = async (data) => {

        if (!data.username || !data.firstname || !data.lastname ||
            (!data.permissions && adminUser)) {
            return;
        }

        let model = {
            username: data.username,
            firstName: data.firstname,
            lastName: data.lastname,
            roles: adminUser ? [data.permissions] : getRole(),
            testCaseGroups: selected.map(t => ({
                id: t,
                name: '',
            }))
        }

        if (!adminUser) {
            delete model.testCaseGroups;
        }

        if (id && +id > 0) {
            model = {
                ...model,
                id,
            }
        } else {
            model = {
                ...model,
                password: data.password,
            }
        }

        const result = await upsertUser(model, id)(dispatch);

        if (result) {
            if (adminUser) {
                history.push('/settings/admin-settings/4');
            } else {
                document.location.reload();
            }
        }
    }

    const resetPwd = () => {
        if (!adminUser && pwd && current && current === confirm && confirm) {


            resetPassword({ newPassword: current, oldPassword: pwd }, id)(dispatch);
            setEditOpen(false);
            return;
        }

        if (pwd) {
            resetPassword({ newPassword: pwd }, id)(dispatch);

            setEditOpen(false);
        }
    }

    const updateStatus = (e) => {
        if (e.target.checked) {
            setSelected(selected.concat(+e.target.value))
        } else {
            setSelected(selected.filter(v => v !== +e.target.value))
        }

    }

    useEffect(() => {
        getSettingsReports(ReportTypes.TEST_CASE_GROUP)(dispatch)
        getUserById(id)(dispatch)
    }, [id]);

    useEffect(() => {
        if (userInfoDetail) {
            setValue('firstname', userInfoDetail.firstName);
            setValue('lastname', userInfoDetail.lastName);
            setValue('username', userInfoDetail.username);
            setValue('permissions', userInfoDetail.roles[0]);
            setSelected(userInfoDetail.testCaseGroups.map(c => c.id))
        } else {
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('username', '');
            setValue('password', '');
            setValue('permissions', '');
            setSelected([])
            setPwd('')
        }
    }, [userInfoDetail]);

    return (
        <div style={isMobile ? { paddingBottom: '65px', paddingLeft: '24px', paddingRight: '24px', paddingTop: '0' } : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={`${id > 0 ? t("settingsAdmin.0") : t("settingsAdmin.1")}`} />
            <Box textAlign='left'
                sx={{
                    minWidth: isMobile ? '100%' : "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '5px',
                    verticalAlign: 'middle'
                }}
            >
                <Typography type="body2" className="edit-title">
                    {adminUser ? (
                        <>{id > 0 ? t("editUser.0") : t("createUser.0")}</>
                    ) : (
                        <>
                            t("userProfile")
                        </>
                    )}

                </Typography>

            </Box>

            <form onSubmit={handleSubmit(handleSaveSubmit)}>
                <Grid container>
                    <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 5 }}>
                        <Controller
                            render={({ field }) => (
                                <TextField size="small" label={t("firstName.0")}
                                    fullWidth={isMobile}
                                    {...field} value={field.value}

                                />
                            )}
                            control={control}
                            name="firstname"
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 5 }}>
                        <Controller
                            render={({ field }) => (
                                <TextField size="small" label={t("lastName.0")}
                                    fullWidth={isMobile}
                                    {...field} value={field.value}

                                />
                            )}
                            control={control}
                            name="lastname"
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 5 }}>
                        <Controller
                            render={({ field }) => (
                                <TextField size="small" label={!adminUser ? t("emailAddress") : t("username.1")}
                                    fullWidth={isMobile}
                                    {...field} value={field.value}

                                />
                            )}
                            control={control}
                            name="username"
                        />
                        <Typography variant="caption" className="db">
                            {t("addEmailAddressForTheUser")}
                        </Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 5 }}>
                        {(adminUser && +id === 0) && (
                            <Controller
                                render={({ field }) => (
                                    <TextField size="small" label={t("password.1")} type='password'
                                        fullWidth={isMobile}
                                        {...field} value={field.value}

                                    />
                                )}
                                control={control}
                                name="password"
                            />
                        )}

                        {((id && +id > 0) || !adminUser) && (
                            <Button sx={{ ml: 2 }} variant="contained" color="primary" className="round-button big-button"
                                onClick={() => {
                                    setHash(new Date().getTime())
                                    setEditOpen(true)
                                }} >{!adminUser ? t("changePassword") : t("resetPassword")}</Button>
                        )}
                    </Grid>

                    {adminUser && (
                        <>
                            <Grid item xs={3} sx={{ mt: 5 }}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField select size="small" label={t("permissions.0")} className="min-w-240"
                                            fullWidth={isMobile}
                                            {...field} value={field.value}>
                                            <MenuItem value={0}>{t("select")}</MenuItem>
                                            <MenuItem value={"ROLE_ADMIN"}>{t("administrator")}</MenuItem>
                                            <MenuItem value={"ROLE_USER"}>{t("user.0")}</MenuItem>
                                        </TextField>
                                    )}
                                    control={control}
                                    name="permissions"
                                />
                            </Grid>

                            {watchRole === 'ROLE_USER' && (
                                <Grid item xs={8} sx={{ mt: 5 }}>
                                    <FormControl fullWidth sx={{ m: 0 }}>
                                        <InputLabel id="test-case-group-label">{t("testCaseGroup.2")}</InputLabel>
                                        <Select size="small" labelId="test-case-group-label"
                                            id="test-case-group" multiple={true} label="Test Case Groups"
                                            value={selected}
                                            renderValue={() => selected.map((id) => testCaseGroup?.find(t => t.id === +id)?.name).join(', ')}>
                                            <MenuItem value={0} key={0}>{t("select")}</MenuItem>
                                            {testCaseGroup?.map(tcg => (
                                                <MenuItem key={tcg.id} value={tcg.id}>
                                                    <Checkbox value={tcg.id} checked={selected.indexOf(tcg.id) !== -1} onChange={updateStatus} />
                                                    <ListItemText primary={tcg.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography variant="caption">
                                            {selected.length} {t("of")} {testCaseGroup?.length} {t("testCaseGroupsSelected")} 
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            )}
                        </>
                    )}

                </Grid>
                <Box textAlign='left'
                    sx={{
                        minWidth: isMobile ? "100%" : "500px",
                        borderRadius: '5px',
                        borderWidth: '0',
                        borderStyle: 'solid',
                        backgroundColor: '#E8F3F4',
                        mt: isMobile ? 6 : 15
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 2 }}
                        style={{
                            borderRadius: 7,
                            backgroundColor: "#1f9997",
                            fontSize: "14px",
                            width: "190px",
                            height: 40
                        }}

                    >
                        <Typography style={{ fontFamily: 'Poppins', fontSize: 14, textTransform: 'none', color: "white" }}>{t("save")}</Typography>
                    </Button>
                    {adminUser && (
                        <Button

                        >
                            <Typography style={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 600, textTransform: 'none', color: "#BDBDBD" }}
                                onClick={() => history.push('/settings/admin-settings/4')} >{t("cancel")}</Typography>
                        </Button>
                    )}
                </Box>

                <Modal
                    isOpen={editOpen}
                    openHash={hash}
                    overflow={false}
                    small={true}
                    ButtonDelete={resetPwd}
                    primaryButton={adminUser ? 'Reset' : 'Save'}
                    title={!adminUser ? 'Change Password' : 'Reset Password'}
                    body={<div className="mt-30" style={{
                        display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '64px'
                        , paddingRight: '64px'
                    }}>

                        <div className="db">
                            <div className="db">
                                <TextField label={!adminUser ? 'Current Password*' : "New Password*"}
                                    onChange={(e) => setPwd(e.target?.value)}
                                    value={pwd}
                                    type="password"
                                    size="small">

                                </TextField>
                            </div>

                            {!adminUser && (
                                <>
                                    <div className="db mt-30">
                                        <TextField label={"New Password*"}
                                            onChange={(e) => setCurrent(e.target?.value)}
                                            value={current}
                                            type="password"
                                            size="small">

                                        </TextField>
                                    </div>
                                    <div className="db mt-30">
                                        <TextField label={"Confirm New Password*"}
                                            onChange={(e) => setConfirm(e.target?.value)}
                                            value={confirm}
                                            type="password"
                                            size="small">

                                        </TextField>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>}
                />
            </form>
        </div>
    )
}

export default EditUser;
