export const ReportTypes = {
    ALERT: 1,
    TEST_CASE_GROUP: 2,
    DISTRIBUTION: 3,
    USER: 4,
};


export const SETTINGS_REPORT = [
    {
        title: 'Alert Settings', confirm: 'alert setting', id: ReportTypes.ALERT,
        url: 'settings/alert-settings',
        columns: [
            { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true },
            {
                field: "name",
                headerName: "STATUS ALERT LABEL",
                flex: 1,
                disableColumnMenu: true,
            },
            {
                field: "testCaseGroupName",
                headerName: "TEST CASE GROUP",
                flex: 0.7,
                disableColumnMenu: true,
            }, {
                field: "distributionName",
                headerName: "CONTACT GROUP",
                flex: 0.7,

                disableColumnMenu: true,
            },
            {
                field: "active",
                headerName: "NOTIFICATION",
                disableColumnMenu: true,
                minWidth: 190,
                maxWidth: 190,
            },
        ],
    },
    {
        title: 'Test Cases Groups', id: ReportTypes.TEST_CASE_GROUP,
        url: 'settings/test-case-group',
        confirm: 'test case group',
        columns: [
            { field: "id", headerName: "ID", width: 90, disableColumnMenu: true },
            {
                field: "name",
                headerName: "TEST CASE GROUP LABEL",

                flex: 1,
                disableColumnMenu: true,
            },
            {
                field: "report_label2",
                headerName: "NUMBER OF TEST CASES",
                flex: 0.6,

                disableColumnMenu: true,
                renderCell: params => (
                    <span>
                        {`${params.row.caseCount} from ${params.row.ivrCount} IVR Applications`}
                    </span>
                )
            },
        ],
    },
    {
        title: 'Distribution Lists', id: ReportTypes.DISTRIBUTION, url: 'settings/distribution-list',
        confirm: 'distribution list',
        columns: [
            { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true, },
            {
                field: "name",
                headerName: "DISTRIBUTION LIST LABEL",
                flex: 1,

                disableColumnMenu: true,
            },
            {
                field: "label",
                headerName: "LABEL",
                flex: 0.6,

                sortable: false,
                disableColumnMenu: true,
                renderCell: params => (
                    <>
                        {params.row.label.map((l, index) => (
                            <div key={`${index}-${l}`} className="db pb-30">{l}</div>
                        ))}
                    </>

                )
            },
            {
                field: "type",
                headerName: "TYPE",
                disableColumnMenu: true,
                sortable: false,
                renderCell: params => (
                    <>
                        {params.row.type.map((l, index) => (
                            <div key={`${index}-${l}`} className="db pb-30">{l}</div>
                        ))}
                    </>

                ),
                minWidth: 120,
                maxWidth: 120,
            },
            {
                field: "email",
                headerName: "EMAIL / NUMBER",
                flex: 0.85,
                sortable: false,

                disableColumnMenu: true,
                renderCell: params => (
                    <>
                        {params.row.email.map(l => (
                            <div key={l} className="db pb-30">{l}</div>
                        ))}
                    </>

                )
            },
            {
                field: "lastModifiedTime",
                headerName: "LAST MODIFIED",
                disableColumnMenu: true,
                minWidth: 195,
                maxWidth: 195,
            },
        ],
    },
    {
        title: 'Admin Settings', id: ReportTypes.USER, confirm: 'user', url: 'settings/admin-settings', columns: [

            { field: "id", headerName: "ID", width: 90, disableColumnMenu: true, hide: true, },
            {
                field: "firstName",
                headerName: "FIRST NAME",

                flex: 0.75,
                disableColumnMenu: true,
            },
            {
                field: "lastName",
                headerName: "LAST NAME",
                flex: 0.75,

                disableColumnMenu: true,
            },
            {
                field: "username",
                headerName: "USERNAME",

                flex: 1,
                disableColumnMenu: true,
            },
            {
                field: "roles",
                headerName: "PERMISSIONS",

                disableColumnMenu: true,
                minWidth: 200,
                maxWidth: 200,
            },
            {
                field: "testCaseGroups",
                headerName: "TEST CASE GROUPS",

                sortable: false,
                disableColumnMenu: true,
                minWidth: 180,
                maxWidth: 180,
            },
        ],
    }
];