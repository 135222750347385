import {combineReducers} from "redux";
import {loginReducer} from "./login";
import {reportReducer} from "./report";

const reducer = combineReducers({
    loginReducer,
    reportReducer
});

export default reducer;
