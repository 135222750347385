import React from "react";
import Navbar from "./Navbar";
import RightContent from "./RightContent";
import { Grid } from "@mui/material";
import LeftBar from "./LeftBar";
import { isMobile } from 'react-device-detect';


const Dashboard = () => {
    const defaultPage = [1, 0, 0, 0]

    return (
        <div>
            <Navbar />
            <Grid container>
                {!isMobile && (
                    <Grid item>
                        <LeftBar page={defaultPage} />
                    </Grid>
                )}
                <Grid item>
                    <RightContent />
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;