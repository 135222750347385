import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {createStore, applyMiddleware} from "redux";
import {Provider as ReduxProvider} from "react-redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import reducer from "./reducers";
import {ENABLE_REDUX_DEV_TOOLS} from "./helper/constants";
import "./i18n";

const reduxStore = createStore(
    reducer,
    {},
    ENABLE_REDUX_DEV_TOOLS
        ? composeWithDevTools(applyMiddleware(thunk))
        : applyMiddleware(thunk)
);

ReactDOM.render(
    <ReduxProvider store={reduxStore}>
        <App/>
    </ReduxProvider>,
    document.getElementById("root")
);
