import "./Telco.css";
import chart from "../../images/chart.svg";
import chatbot from "../../images/chatbot.svg";
import sample from "../../images/sampleivr.svg";
import telcoicon from "../../images/telco_icon.svg";
import headphone from "../../images/headphone_enabled.svg";
import telcobg from "../../images/telco_bg.svg";
import * as echarts from "echarts";
import { useEffect } from "react";
import { TelcoAgent } from "./Telco.agent";
import logo from "../../images/lens_small_logo.svg";
import expand from "../../images/expand.svg";
import Modal from "../mui/Modal";
import { useState } from "react";
import { FormControl, Grid, MenuItem, Typography } from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import lens_active from "../../images/lens_active.svg";
import green_arrow from "../../images/green_arrow.svg";
import telco_active from "../../images/telco_active.svg";
import ivr_active from "../../images/ivr_active.svg";
import agent_active from "../../images/agent_active.svg";
import ivr_red from "../../images/ivr_red.svg";
import telcos_red from "../../images/telcos_red.svg";
import telcos_disabled from "../../images/telcos_disabled.svg";
import agent_disabled from "../../images/agent_disabled.svg";
import agent_red from "../../images/agent_red.svg";
import ivr_disabled from "../../images/ivr_disabled.svg";
import red_arrow from "../../images/red_arrow.svg";
import disabled_arrow from "../../images/disabled_arrow.svg";
import fi_rs_phone_call from "../../images/fi-rs-phone-call.svg";
import digital_icon from "../../images/digital_icon.svg";
import internet from "../../images/internet.svg";
import chatbot_journey from "../../images/chatbot_journey.svg";
import lensLogo from "../../images/Lens Logo Teal E.svg"
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import chatbotIcon from "../../images/chatbot_icon.svg";
import { CHANNELS, DASHBOARD_SELECTIONS_ITEMS } from "../../helper/constants";

const PASS = 0
const WARN = 1
const FAIL = 2
const WARNCOLOR = "rgb(255, 125, 66)"
const FAILCOLOR = "red"
const PASSCOLOR = 'rgb(76, 175, 80)';

export const Telco = ({ 
    contentKpis = {}, 
    id = "donut", 
    modal = false, 
    data = [],
    dashboardSelection,
    setDashboardSelection
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [hash, sethash] = useState('');
    // const [dashboardSelection, setDashboardSelection] = useState( isMobile ? 'Digital' : 'Voice');

    

    const handleDashboardSelectionChange = (event) => {
        setDashboardSelection(event.target.value);
      };

    useEffect(() => {
        console.log('contentKpis', contentKpis);

        if (!modal) return;
        // let myChart = echarts.init(document.getElementById(id));
        // let option = {
        //     width: 'auto',
        //     series: [
        //         {
        //             name: '',
        //             type: 'pie',
        //             radius: ['55%', '70%'],
        //             avoidLabelOverlap: false,
        //             label: {
        //                 show: false,
        //                 position: 'center'
        //             },
        //             emphasis: {
        //                 label: {
        //                     show: false,
        //                     fontSize: 40,
        //                     fontWeight: 'bold'
        //                 }
        //             },
        //             labelLine: {
        //                 show: false
        //             },
        //             data: [
        //                 { value: contentKpis?.total_passPct || 0, name: 'PASS', itemStyle: { color: PASSCOLOR } },
        //                 { value: contentKpis?.total_warnPct || 0, name: 'warning', itemStyle: { color: WARNCOLOR } },
        //                 { value: contentKpis?.total_failPct || 0, name: 'fail', itemStyle: { color: FAILCOLOR } },
        //             ]
        //         }
        //     ]
        // };

        // myChart.clear();
        // myChart.setOption(option);

    }, [contentKpis]);

    const expandSystem = () => {
        
        sethash(`${new Date().getTime()}`);
        setOpen(true);
        
        
    }
    const [diagram, setDiagram] = useState({});

    useEffect(() => {
        if (!modal && data && data?.length) {
            const ivrDisabled = data[0].clientApps?.some(c => !c.lastRun?.appStatus?.enabled);
            const ivrRed = data[0].clientApps?.some(c => c.lastRun?.appStatus?.enabled && c.lastRun?.appStatus?.status !== 'TRS_PASS');
            const ivrGreen = data[0].clientApps?.every(c => c.lastRun?.appStatus?.enabled && c.lastRun?.appStatus?.status === 'TRS_PASS');


            const telcoDisabled = data[0].clientApps?.some(c => !c.lastRun?.telcoStatus?.enabled);
            const telcoRed = data[0].clientApps?.some(c => c.lastRun?.telcoStatus?.enabled && c.lastRun?.telcoStatus?.status !== 'TRS_PASS');
            const telcoGreen = data[0].clientApps?.every(c => c.lastRun?.telcoStatus?.enabled && c.lastRun?.telcoStatus?.status === 'TRS_PASS');


            const agentDisabled = data[0].clientApps?.some(c => !c.lastRun?.agentStatus?.enabled);
            const agentRed = data[0].clientApps?.some(c => c.lastRun?.agentStatus?.enabled && c.lastRun?.agentStatus?.status !== 'TRS_PASS');
            const agentGreen = data[0].clientApps?.every(c => c.lastRun?.agentStatus?.enabled && c.lastRun?.agentStatus?.status === 'TRS_PASS');

            const ivrRedCount = data[0].clientApps?.filter(c => c.lastRun?.appStatus?.enabled && c.lastRun?.appStatus?.status !== 'TRS_PASS')?.length;
            const totalIvr = data[0].clientApps?.length;

            let result = {
                ivrArrow: green_arrow,
                telcoArrow: green_arrow,
                ivrDisabled: ivrDisabled,
                telcoDisabled: telcoDisabled,
                agentDisabled: agentDisabled,
                ivrClass: ivrRed ? 'client-journey-red' : 'client-journey-green',
                telcoClass: telcoRed ? 'client-journey-red' : 'client-journey-green',
                agentClass: agentRed ? 'client-journey-red' : 'client-journey-green',
            };

            if (ivrGreen) {
                result = {
                    ...result,
                    ivr: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? ivr_active : chatbotIcon,
                }
            } else if (ivrDisabled) {
                result = {
                    ...result,
                    ivr: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? ivr_disabled : chatbotIcon,
                    ivrArrow: disabled_arrow,
                }
            } else if (ivrRed) {
                result = {
                    ...result,
                    ivr: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? ivr_red : chatbotIcon,
                    ivrArrow: disabled_arrow//red_arrow,
                }
            }

            if (telcoGreen) {
                result = {
                    ...result,
                    telco: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? telcoicon : internet,
                }
            } else if (telcoDisabled) {
                result = {
                    ...result,
                    telco: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? telcos_disabled : internet,
                    telcoArrow: disabled_arrow,
                }
            } else if (telcoRed) {
                result = {
                    ...result,
                    telco: dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? telcos_red : internet,
                    telcoArrow: red_arrow,
                }
            }


            if (agentGreen) {
                result = {
                    ...result,
                    agent: headphone,
                }
            } else if (agentDisabled) {
                result = {
                    ...result,
                    agent: headphone,
                }
            } else if (agentRed) {
                result = {
                    ...result,
                    agent: agent_red,
                }
            }

            setDiagram({
                ...result,
                ivrRedCount,
                totalIvr,
            });

        }
    }, [data, dashboardSelection]);


    function ModalViewDesktopComp() 
    {
        return (
            <>
                <div className={`${modal ? 'flex' : 'flex ml-400'} w-full center p-20`} style={{ marginTop: '70px' }}>
                    <div className={"lens-service inline" + (modal ? ' ' : '')}>
                        <div className="v-middle">
                            <div className="mr-16">
                                <img src={logo} alt="logo"/>
                            </div>
                            <div className="mr-16">
                                <Typography fontWeight={500} fontSize='20px' lineHeight='24px' paragraph='16px' align="center">
                                    {t("Lens Service Current Status")}
                                </Typography>
                            </div>
                            
                                
                            <div >
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src={dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? telcoicon : internet} />
                                    <Typography fontWeight={700} fontSize='10.66px'>{t(dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? "Telco": "Internet")}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex ${modal ? 'flex-wrap-big w-big pl-40' : 'flex-wrap w-550 ml-240'}`} style={{justifyContent: 'center'}}>
                    {data?.find(c => c.channel === (dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? CHANNELS.VOICE : CHANNELS.DIGITAL))?.clientApps?.map(row => (
                        <div className="ivr-component">
                            <div className={"ivr-small-connection" + (modal ? '-big' : '')}>&nbsp;</div>
                            <TelcoAgent clientApp={row} big={modal} />
                        </div>
                    ))}
                </div>
            </>
        );
    }


    return (
        <>
            {!modal && isMobile && (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div className="dashboard-selection">
                        <FormControl style={{width: '100%'}}>
                            <Select
                            sx={{height: '100%', border: 'none'}}
                                value={dashboardSelection}
                                onChange={handleDashboardSelectionChange}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            borderRadius: '10px',
                                            border: '1px solid var(--Tk-1, #006A68)',
                                            background: 'var(--Tk3, #CCE8E6)',
                                            width: 100
                                        }
                                    },
                                    anchorOrigin: {
                                        vertical: "center",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "center",
                                        horizontal: "center"
                                    },
                                }}
                            >
                                <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.DIGITAL}>
                                    <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}>
                                        <div style={{display: 'flex'}}><img src={digital_icon} alt=""/></div>
                                        <div>{t(DASHBOARD_SELECTIONS_ITEMS.DIGITAL)}</div>
                                    </div>
                                </MenuItem>
                                <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.VOICE}>
                                <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}><img src={fi_rs_phone_call} alt=""/>{t(DASHBOARD_SELECTIONS_ITEMS.VOICE)}</div>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        
                    </div>
                </div>
                
            )}
            

            <Grid container spacing={isMobile ? 2 : 0} style={{justifyContent: 'center'}}>
                {!modal && (
                    <Grid item xs={isMobile ? 12: 4}>
                    <div>
                        <img src={lensLogo} alt="Action Center" width={isMobile ? 45 : 108.12} height={isMobile ? 'auto' : 53.8} />
                        <Typography variant={isMobile ? "h5" : "h3"}
                            style={isMobile ? { paddingLeft: '8px' } : {}}>
                            {t("actionCenter")}
                        </Typography>
                    </div>
                    {!isMobile && (
                        <div style={{marginRight: '5px'}}>
                            <div className="dashboard-selection">
                                <FormControl style={{width: '100%'}}>
                                    <Select
                                    sx={{height: '100%', border: 'none'}}
                                        value={dashboardSelection}
                                        onChange={handleDashboardSelectionChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    borderRadius: '10px',
                                                    border: '1px solid var(--Tk-1, #006A68)',
                                                    background: 'var(--Tk3, #CCE8E6)',
                                                    width: 100
                                                }
                                            },
                                            anchorOrigin: {
                                                vertical: "center",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "center",
                                                horizontal: "center"
                                            },
                                        }}
                                    >
                                        <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.DIGITAL}>
                                            <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}>
                                                <div style={{display: 'flex'}}><img src={digital_icon} alt=""/></div>
                                                <div>{t(DASHBOARD_SELECTIONS_ITEMS.DIGITAL)}</div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem value={DASHBOARD_SELECTIONS_ITEMS.VOICE}>
                                        <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: 1}}><img src={fi_rs_phone_call} alt=""/>{t(DASHBOARD_SELECTIONS_ITEMS.VOICE)}</div>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                
                            </div>
                        </div>
                    )}
                </Grid>
                )}
                
                <Grid item xs={isMobile ? 12 : 8} style={{justifyContent:'center'}}>
                    <div id="container-modal" className={modal ? "container-telco-modal" : "container-telco"}>
                    {/* {modal && !isMobile && (
                        <div className={"run-result-container" + (modal ? "-big" : "")}>
                            <div className="title">Total Calls</div>
                            <div className="total">{contentKpis?.total_testRuns || 0}</div>
                            <div className="flex">
                                <div className="fail"></div>
                                <div className="inline-title">Fail: <div>{Math.round((contentKpis?.total_failPct || 0) * 100)}%</div></div>
                            </div>
                            <div className="flex">
                                <div className="warning"></div>
                                <div className="inline-title">Warning: <div>{Math.round((contentKpis?.total_warnPct || 0) * 100)}%</div></div>
                            </div>
                            <div className="flex">
                                <div className="pass"></div>
                                <div className="inline-title">Pass: <div>{Math.round((contentKpis?.total_passPct || 0) * 100)}%</div></div>
                            </div>
                        </div>
                    )} */}
                    {!modal && !isMobile && (
                        <div className="expand-icon" onClick={expandSystem}><img src={expand} /></div>
                    )}

                    {!modal ? (
                        <div className="db">
                            <div className="system-component-title" style={{display: 'flex'}}>                                                            
                            {t("Client Experience Journey")}</div>
                            <div className="flex justify-center mt-32">
                                <div>
                                    {/* <img className="w-full" src={lens_active} /> */}
                                    <div className='client-journey-green'>
                                        <div>
                                            <img src={lensLogo} style={{width: '64px', height: '40px'}} />
                                        </div>
                                            <Typography fontWeight={500} fontSize='17.84px' align="center" lineHeight='21.41px'>
                                                {t('Simulated Caller')}
                                            </Typography>
                                        
                                    </div>
                                </div>
                                <div><img className="w-full" src={green_arrow} /></div>
                                <div>
                                    {/* <img className="w-full" src={diagram?.telco} style={{height: '129px'}}/> */}
                                    <div className={diagram?.telcoClass}>
                                        <div>
                                            <img src={diagram?.telco} style={{width: '64px', height: '40px'}} />
                                        </div>
                                            <Typography fontWeight={500} fontSize='17.84px' align="center" lineHeight='21.41px' color={diagram?.telcoDisabled ? '#A6A6A6;' : ''}>
                                                {dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? t('Telco') : t('Internet')}
                                            </Typography>
                                        
                                    </div>
                                </div>
                                <div><img className="w-full" src={diagram?.telcoArrow} /></div>
                                <div className="relative">
                                    {(dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE && diagram?.ivrRedCount !== 0 && !isMobile) && (
                                        <div className="failure-count">{diagram?.ivrRedCount}/{diagram?.totalIvr}</div>
                                    )}
                                    {/* <img className="w-full" src={diagram?.ivr} style={{height: '129px'}}/> */}
                                    <div className={diagram?.ivrClass}>
                                        <div>
                                            <img src={diagram?.ivr} style={{width: '64px', height: '40px'}} />
                                        </div>
                                            <Typography fontWeight={500} fontSize='17.84px' align="center" lineHeight='21.41px' color={diagram?.ivrDisabled ? '#A6A6A6;' : ''}>
                                                {dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? t('IVR') : t('Chatbot')}
                                            </Typography>
                                        
                                    </div>
                                </div>
                                <div><img className="w-full" src={diagram?.ivrArrow} /></div>                            
                                <div>                                    
                                    <div className={diagram?.agentClass}>
                                        <div>
                                            <img src={diagram?.agent} style={{width: '64px', height: '40px'}} />
                                        </div>
                                            <Typography fontWeight={500} fontSize='17.84px' align="center" lineHeight='21.41px' color={diagram?.agentDisabled ? '#A6A6A6;' : ''}>
                                                {t('Agent')}
                                            </Typography>                                        
                                    </div>
                                </div>
                                
                                {/* <img src={diagram?.telco} />
                                <img src={diagram?.telcoArrow} />
                                <div className="relative">
                                    {diagram?.ivrRedCount && (
                                        <div className="failure-count">{diagram?.ivrRedCount}/{diagram?.totalIvr}</div>
                                    )}
                                    <img src={diagram?.ivr} />
                                </div>
                                <img src={diagram?.ivrArrow} />
                                <img src={diagram?.agent} /> */}
                            </div>
                            {isMobile && (
                                <diV className="details-button-container" onClick={expandSystem}>
                                <div className="details-button">Details</div>
                            </diV>
                            )}
                            
                            
                            
                        </div>
                    ) : isMobile ? (
                        <>
                            <div className="box">
                                <div className="ivr-component">
                                    
                                    <img src={chart} className="pl-20" style={{ visibility: 'hidden' }} />
                                    <div id={id} style={{ height: '160px', width: '160px' }}></div>
                                    <div className="telcobg"><img src={telcoicon} /><img src={telcobg} /><div className="telco-title">{t("Telco")}</div></div>
                                </div>
                                <div className={"run-result-container" + (modal ? "-big" : "")}>
                                    <div className="title">{t("Total Calls")}</div>
                                    <div className="total">{contentKpis?.total_testRuns || 0}</div>
                                    <div className="flex">
                                        <div className="fail"></div>
                                        <div className="inline-title">{t("failure.0")}: <div>{Math.round((contentKpis?.total_failPct || 0) * 100)}%</div></div>
                                    </div>
                                    <div className="flex">
                                        <div className="warning"></div>
                                        <div className="inline-title">{t("warning.0")}: <div>{Math.round((contentKpis?.total_warnPct || 0) * 100)}%</div></div>
                                    </div>
                                    <div className="flex">
                                        <div className="pass"></div>
                                        <div className="inline-title">{t("pass.0")}: <div>{Math.round((contentKpis?.total_passPct || 0) * 100)}%</div></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`flex flex-wrap-big pl-40`}>
                                {data.find(c => c.channel === 'ivr').clientApps?.map(row => (
                                    <div className="ivr-component">
                                        <div className={"ivr-small-connection" + (modal ? '-big' : '')}>&nbsp;</div>
                                        <TelcoAgent clientApp={row} />
                                    </div>
                                ))}
                            </div>
                        </>
                    ): (
                        <ModalViewDesktopComp />
                        
                        // <>
                        //     <div className={`${modal ? 'flex ml-360' : 'flex ml-400'} w-full center p-20`} style={{ marginTop: '70px' }}>
                        //         <div className="inline relative">
                        //             <div className="connection-left">&nbsp;</div>
                        //         </div>
                        //         <div className={"lens-service inline" + (modal ? ' w-700' : '')}>
                        //             <div className="v-middle">
                        //                 <div className="mr-16">
                        //                     <img src={logo} />
                        //                 </div>
                        //             </div>
                        //         </div>
                        //         <div className="inline relative">
                        //             <div className="connection-right">&nbsp;</div>
                        //         </div>
                        //     </div>
                        //     <div className={`flex flex-wrap ${modal ? 'w-big pl-210' : 'w-550 ml-240'}`}>
                        //         <div className="ivr-component">
                        //             <div className={"chart-left-connection" + (modal ? '-big' : '')}>&nbsp;</div>

                        //             {data[0].clientApps.length >= 2 && (
                        //                 <div className={`chart-right-connection${data[0].clientApps.length === 2 && modal ? '-small' : ''}`}>&nbsp;</div>
                        //             )}
                        //             <img src={chart} className="pl-20" style={{ visibility: 'hidden' }} />
                        //             <div id={id} style={{ height: '160px', width: '160px' }}></div>
                        //             <div className="telcobg"><img src={telcoicon} /><img src={telcobg} /><div className="telco-title">Telco</div></div>
                        //         </div>

                        //         <div className="ivr-component">
                        //             {data[0].clientApps.length >= 3 && (
                        //                 <div className={"upper-connection" + (modal ? '-big' : '')}>&nbsp;</div>
                        //             )}
                        //         </div>

                        //         <div className="ivr-component">
                        //             <div className={"chatbot" + (modal ? ' r-405' : '')}>
                        //                 <img src={chatbot} height={'110px'} />
                        //             </div>

                        //             {!modal && (
                        //                 <div className="chat-bot-diagram-container w-660">
                        //                     <div className={`flex ${modal ? 'flex-wrap-big w-big' : 'flex-wrap'}`}>
                        //                         {data.find(c => c.channel !== 'ivr')?.clientApps?.map(row => (
                        //                             <div className="ivr-component">
                        //                                 <div className={"chat-small-connection" + (modal ? '-big' : '')}>&nbsp;</div>
                        //                                 <TelcoAgent clientApp={row} big={modal} />
                        //                             </div>
                        //                         ))}
                        //                     </div>
                        //                 </div>
                        //             )}
                        //         </div>
                        //     </div>
                        //     <div className={`flex ${modal ? 'flex-wrap-big w-big pl-40' : 'flex-wrap w-550 ml-240'}`}>
                        //         {data.find(c => c.channel === 'ivr').clientApps?.map(row => (
                        //             <div className="ivr-component">
                        //                 <div className={"ivr-small-connection" + (modal ? '-big' : '')}>&nbsp;</div>
                        //                 <TelcoAgent clientApp={row} big={modal} />
                        //             </div>
                        //         ))}
                        //     </div>
                        // </>
                    )}


                </div>
                </Grid>
            </Grid>
            
            <Modal
                isOpen={open}
                openHash={hash}
                overflow={true}
                title={t("Client Experience Components Health Status")}
                xl={true}
                body={<Telco contentKpis={contentKpis} id='donut-modal' modal={true} data={data} dashboardSelection={dashboardSelection} setDashboardSelection={setDashboardSelection} />}
            />
        </>
    )
}