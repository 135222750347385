import React from "react";
import {Grid} from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import Navbar from "../../dashboard/Navbar";
import EditContent from "./EditContent";

const UpsertDistribution = () => {
    const defaultPage = [0, 0, 0, 1]
    return (
        <div>
        <Navbar/>
        <Grid container>

            <Grid item>
                <LeftBar page={defaultPage}/>
            </Grid>
            <Grid item>
               <EditContent />
            </Grid>
        </Grid>
    </div>

    )
}

export default UpsertDistribution;