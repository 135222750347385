import { Grid, Box, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LeftBar from "../../dashboard/LeftBar";
import Navbar from "../../dashboard/Navbar";
import ContentHeader from "../../mui/ContentHeader";
import CustomFooter from "../../mui/CustomFooter";
import FilterComponent from "../../mui/FilterComponent";
import { useHistory } from "react-router-dom";
import downloadIcon from "../../../images/Download Icon.svg";
import { getDate, formatDateTime } from "../../../helper/date";
import { downloadFile, getAlertStatuses, getLatency, getLatencyTestRuns } from "../../../actions";
import {useTranslation} from "react-i18next";


const Latency = () => {
    const defaultPage = [0, 1, 0, 0];
    const dispatch = useDispatch();
    const latency = useSelector(state => state.reportReducer.latency);
    const currentReport = useSelector(state => state.reportReducer.getOneReport);
    const statuses = useSelector(state => state.reportReducer.alertStatuses);
    const testRuns = useSelector(state => state.reportReducer.latencyTestRuns);
    const apiRef = useGridApiRef();
    const [rowPerPage, setRowPerPage] = useState(10);
    const [page, setPage] = useState(1);
    let history = useHistory();
    const [fullTextSearch, setFullTextSearch] = useState('');
    const {t} = useTranslation();

    const { hour, id, reportId, status, date } = useParams();

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const rows = useMemo(() => {
        return (testRuns || [])
            .filter(r => r.runId.toString().indexOf(fullTextSearch) !== -1 ||
                r.testCaseName.indexOf(fullTextSearch) !== -1)
            .map(run => ({
                ...run,
                id: run.runId,
                runTime: formatDateTime(run.runTime, 'yyyy/MM/dd HH:mm:ss'),
                runDuration: `${(+run.runDuration)?.toFixed(2)} sec`,
                status: statuses.find(s => s.id === run.runStatusId)?.text,
            }))
    }, [testRuns, fullTextSearch])

    const columns = [
        { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true },
        {
            field: "runId",
            headerName: t("runId.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "testCaseName",
            headerName: t("testSegmentName.1"),
            editable: true,
            flex: 1,
            width: '200',
            disableColumnMenu: true,
            renderCell: params => (
                <div onClick={() => history.push(`/report/run/${params.row.id}/${latency?.find(l => l.id === +id)?.text}`)}>{params.row.testCaseName}</div>

            )

        },
        {
            field: "runTime",
            headerName: t("dateTime.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "runDuration",
            headerName: t("duration.1"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: t("detailedStatus"),
            editable: true,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "ACTIONS",
            editable: true,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => (
                <img className="pointer" onClick={() => downloadFile(params.row.runId, 'wcr')} src={downloadIcon} alt="Download" />
            )
        },
    ];



    const setSearchText = (input) => {
        setFullTextSearch(input);
    }

    useEffect(() => {
        getAlertStatuses()(dispatch);
        getLatency()(dispatch);
    }, []);

    useEffect(() => {

        if (id && hour && reportId) {
            getLatencyTestRuns(hour, id, reportId, status, date)(dispatch);
        }
    }, [hour, id, reportId]);

    return (
        <div>
            <Navbar />
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage} />
                </Grid>
                <Grid item>
                    <div style={{ position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
                        <ContentHeader title={t("reportsViewReportTestResults")} />
                        <Box textAlign='left'
                            sx={{
                                minWidth: "500px",
                                borderColor: 'lightgrey',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: '#1F9997',
                                marginBottom: '5px',
                                verticalAlign: 'middle'
                            }}
                        >
                            <Typography type="body2" className="edit-title">
                                {currentReport?.data?.reportTypeName}
                            </Typography>

                        </Box>

                        <Box sx={{ mt: 5, ml: 3 }}>
                            <Grid container>
                                <Grid xs={4}>
                                    <Typography variant="h7">{t("testCaseGroup.0")}:</Typography>
                                    <Typography variant="h8">{currentReport?.data?.testCaseGroupName}</Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="h7">{t("from")}:</Typography>
                                    <Typography variant="h8">{date?.replace(/-/g, '/') || rows[0]?.runTime?.split(' ')?.[0]}</Typography>
                                    <Typography variant="h7" sx={{ ml: 1 }}>To:</Typography>
                                    <Typography variant="h8">{date?.replace(/-/g, '/') || rows[rows.length - 1]?.runTime?.split(' ')?.[0]}</Typography>
                                </Grid>
                                <Grid xs={4} sx={{ textAlign: 'right' }}>
                                    <Typography variant="h7">{t("testCaseRuns")}:</Typography>
                                    <Typography variant="h8">{rows?.length}</Typography>
                                </Grid>

                                {+id > -1 && (
                                    <Grid xs={4} sx={{ mt: 2 }}>
                                        <Typography variant="h7">{t("latencyFor")}:</Typography>
                                        <Typography variant="h8">{latency?.find(l => l.id === +id)?.text}</Typography>
                                    </Grid>
                                )}

                                <Grid xs={+id === -1 ? 8 : 4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("timeWindow")}:</Typography>
                                    <Typography variant="h8">{hour}:00 - {hour}:59</Typography>
                                </Grid>
                                <Grid xs={4} sx={{ mt: 2, textAlign: 'right' }}>
                                    <Typography variant="h7">{t("totalIncidentsFound")}:</Typography>
                                    <Typography variant="h8" className="capitalize">{rows?.length}</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <FilterComponent setSearchText={setSearchText} count={rows?.length} title={t("testRuns")} refresh={true} />
                        </Box>

                        <Box sx={{ mt: 4, height: "550px" }}>
                            <DataGrid rows={rows} columns={columns}
                                apiRef={apiRef}
                                sortingOrder={['desc', 'asc']}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                pageSize={rowPerPage}
                                page={page - 1}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'asc' }],
                                    },
                                }}
                                components={{
                                    Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                                }}
                                getCellClassName={(params) => {
                                    if (params.field === "testCaseName") {
                                        return 'underscore pointer'
                                    } else if (params.field === "status") {
                                        return params.formattedValue;
                                    }

                                }}

                            />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Latency;
