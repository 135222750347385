import React from "react";
import { Grid } from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import Navbar from "../../dashboard/Navbar";
import TestCaseGroupList from "./Test-Case-Group-List";
import { isMobile } from 'react-device-detect';

const TestCaseGroupReport = () => {
    const defaultPage = [0, 0, 0, 1]

    return (
        <div>
            <Navbar />
            <Grid container>

                {!isMobile && (
                    <Grid item>
                        <LeftBar page={defaultPage} />
                    </Grid>
                )}
                <Grid item>
                    <TestCaseGroupList />
                </Grid>
            </Grid>
        </div>
    );
};

export default TestCaseGroupReport;
