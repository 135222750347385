import React from 'react';
import PropTypes from "prop-types";
import Modal from './Modal';


const ResponsiveDialog = ({ title, ButtonDelete, isOpen, openHash, statement }) => {
    return (
        <Modal title={title} ButtonDelete={ButtonDelete} isOpen={isOpen} openHash={openHash} statement={statement} />
    );
};
ResponsiveDialog.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openHash: PropTypes.string.isRequired,
    Buttons: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        event: PropTypes.func,
    }))
}
export default ResponsiveDialog;