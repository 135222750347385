const getUser = () => {
    const user = localStorage.getItem('user');

    if (user) {
        return JSON.parse(user)?.data;
    }

    return null;
}

export const getUserId = () => {
    return getUser()?.id;
}

export const isAdmin = () => {
    return getUser()?.roles[0] !== 'ROLE_USER'
}


export const getRole = () => {
    return getUser()?.roles
}

export const channelRequired = () => (process.env.REACT_APP_CHANNEL_REQUIRED || '') == 'true';
export const audioPlayerVisible = () => (process.env.REACT_APP_AUDIO_PLAYER || '') == 'true';