import { LensActions } from "../actions";

export const loginReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LensActions.Login:
            return {
                ...state,
                loginResult: action.payload,
            };
        case LensActions.GroupsList:
            return {
                ...state,
                groupsList: action.payload
            }

        case LensActions.GroupData:
            return {
                ...state,
                groupData: action.payload
            }
        case LensActions.SetCaseGroups:
            return {
                ...state,
                caseGroups: action.payload
            }
        case LensActions.SetDashboardData:
            return {
                ...state,
                originalData: action.payload
            }
        default:
            return state;
    }
};

const defaultState = {
    loginResult: null,
    groupsList: null,
    groupData: null
};
