import format from 'date-fns/format'
import { useState } from 'react';
import { Box, Typography, Button } from "@mui/material";
import { reduce } from 'lodash';
import {useTranslation} from "react-i18next";

const addHalfHour = (time) => {
    const times = time.split(':');
    return format(new Date(new Date(2022, 1, 1, parseInt(times[0]), parseInt(times[1]), 0, 0).getTime() + (30 * 60 * 1000)), 'HH:mm');
}

const Schedule = ({ cancel, done, data }) => {
    const {t} = useTranslation();
    const days = [`${t("monday")}`, `${t("tuesday")}`, `${t("wednesday")}`, `${t("thursday")}`, `${t("friday")}`, `${t("saturday")}`, `${t("sunday")}`];
    const slots = Array.apply(0, new Array(48)).map((v, index) => `${format(new Date(new Date(2022, 1, 1, 0, 0, 0, 0).getTime() + (index * 30 * 60 * 1000)), 'HH:mm')}`);

    const [selected, setSelected] = useState(reduce(data, (result, item) => {

        let { startTimeSlot, endTimeSlot } = item;

        const items = [];
        items.push({
            ...item,
            endTimeSlot: startTimeSlot,
        });

        while (endTimeSlot !== startTimeSlot) {
            startTimeSlot = addHalfHour(startTimeSlot);
            items.push({
                ...item,
                endTimeSlot: startTimeSlot,
                startTimeSlot: startTimeSlot,
            });
        }

        return result.concat(items);
    }, []));

    


    const setDateSelected = (index, day, timeString) => {
        const time = timeString.split(' ')[0];
        const find = selected.find(t => t.dayOfWeek === day && t.startTimeSlot === time);

        if (find) {
            setSelected(selected.filter(t => !(t.dayOfWeek === day && t.startTimeSlot === time)));
        } else {
            setSelected(selected.concat({
                dayOfWeek: day,
                startTimeSlot: time,
                start: index,
                endTimeSlot: time,
            }))
        }

    }

    const isSelected = (index, day, value) => {
        return selected.filter(t => t.dayOfWeek === day && t.startTimeSlot === value).length !== 0;
    }

    return (
        <>
            <div className="db">
                <div className="dib day-of-week"></div>
                {days.map(d => (
                    <div className="dib day-of-week" key={d}>{d}</div>
                ))}
            </div>
            {slots.map((value, index) => (
                <div className="flex pt-2 pb-2" key={`row_${index}`}>
                    <div className="dib day-of-week-hour">{value}</div>
                    {days.map(d => (
                        <div className={`dib day-of-week-row ${isSelected(index, d, value) ? 'day-of-week-row-selected' : ''}`} key={d}
                            onClick={() => setDateSelected(index, d, value)}>&nbsp;</div>
                    ))}
                </div>
            ))}

            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    borderRadius: '5px',
                    borderWidth: '0',
                    borderStyle: 'solid',
                    backgroundColor: '#E8F3F4',
                    mt: 3
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => done(selected)}
                    sx={{ mt: 1, mb: 1, ml: 2 }}
                    style={{
                        borderRadius: 7,
                        backgroundColor: "#1f9997",
                        fontSize: "14px",
                        width: "190px",
                        height: 40
                    }}
                >
                    <Typography style={{ fontFamily: 'Poppins', fontSize: 14, textTransform: 'none', color: "white" }}>{t("schedule")}</Typography>
                </Button>

                <Button

                >
                    <Typography style={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 600, textTransform: 'none', color: "#BDBDBD" }}
                        onClick={() => cancel()} >{t("cancel")}</Typography>
                </Button>
            </Box>
        </>
    )
}

export default Schedule;
