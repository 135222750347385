import React, {forwardRef, useEffect, useState} from "react";
import {
    Box,
    Button, FormHelperText, InputLabel,
    MenuItem, Select, Typography
} from "@mui/material";

import {makeStyles} from "@mui/styles";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useHistory} from "react-router-dom";
import {addDays} from "date-fns";
import moment from "moment";
import Divider from "@mui/material/Divider";
import timeLogo from "../../../images/Group 196.svg";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContentHeader from "../../mui/ContentHeader";
import {useDispatch, useSelector} from "react-redux";
import {editReport, getLatency, LensActions} from "../../../actions";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    title: {
        color: "white",
        height: "40px",
        verticalAlign: "center",
        padding: "10px"
    },

    input: {
        marginRight: "20px",
        width: "250px",
        height: "40px",
        borderRadius: "5px",
        fontSize: "14px",
        border: "1px solid lightgrey",
        "&:focus": {
            outline: "none !important",
            border: "2px solid #3f50b5",
        },
    }
}));

const schema = yup.object().shape({
    name: yup.string().required("Required").trim(),
});


const EditReportContent = () => {
    //const editReportTitle = "REPORT / EDIT REPORT"
    const Custom = "Custom"
    const Latency = "Test Script Latency by Hour"
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const [type, setType] = React.useState('');
    const [group, setGroup] = React.useState('');
    const [warning, setWarning] = useState('');
    const [failure, setFailure] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(new Date(), 6));
    const {t} = useTranslation();
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const reportTypes = useSelector(state => state.reportReducer.getReportTypes)
    const testGroups = useSelector(state => state.loginReducer.groupsList)
    const getOneReport = useSelector(state => state.reportReducer.getOneReport)
    const latency = useSelector(state => state.reportReducer.latency)

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: {name: ""}
    });

    useEffect(() => {
        getLatency()(dispatch)
        if (getOneReport && reportTypes && testGroups) {
            setType(reportTypes.data.find(value => value.id === getOneReport.data.reportTypeID))
            setGroup(testGroups.data.find(value => value.id === getOneReport.data.testCaseGroupID))
            let tempStart = addDays(new Date(getOneReport.data.timeframeBegin), 1)
            let tempEnd = addDays(new Date(getOneReport.data.timeframeEnd), 1)
            setStartDate(tempStart)
            setEndDate(tempEnd)
            setWarning(getOneReport.data.latencyWarn)
            setFailure(getOneReport.data.latencyFail)
            let defaults = {
                name: getOneReport.data.name
            }
            reset(defaults)
        }
    }, [getOneReport, reportTypes, testGroups, reset]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(type && type.name === Latency) {
            setWarning(getOneReport?.data?.latencyWarn? getOneReport.data.latencyWarn : '' )
            setFailure(getOneReport?.data?.latencyFail? getOneReport.data.latencyFail : '' )

        }
    }, [type]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleChangeType = (event) => {
        setType(event.target.value);
    };
    const handleChangeGroup = (event) => {
        setGroup(event.target.value);
    };
    const handleChangeWarning = (event) => {
        setWarning(event.target.value)
    }
    const handleChangeFailure = (event) => {
        setFailure(event.target.value)
    }

    const handleSaveSubmit = ({name}) => {

        if (!type) {
            return setError('type', {type: 'manual', message: 'Please select type'})
        }
        if (!group) {
            return setError('group', {type: 'manual', message: 'Please select group'})
        }

        if (type && type.name === Latency && !warning) {
            return setError('warning', {type: 'manual', message: 'Please select threshold warning'})
        }
        if (type && type.name === Latency && !failure) {
            return setError('failure', {type: 'manual', message: 'Please select threshold failure'})
        }

        let reportData = {}
        reportData.name = name
        reportData.description = name
        reportData.reportTypeName = type.name
        reportData.reportTypeID = type.id
        reportData.testCaseGroupName = group.name
        reportData.testCaseGroupID = group.id
        reportData.timeframeName = Custom
        reportData.timeframeBegin = new moment(startDate).format('YYYY-MM-DD')
        reportData.timeframeEnd = endDate ? new moment(endDate).format('YYYY-MM-DD') : new moment(startDate).format('YYYY-MM-DD')

        if (type.id === 6) {
            reportData.latencyWarn = warning
            reportData.latencyFail = failure
        }

        editReport(reportData, getOneReport.data.id)(dispatch)
        history.push('/reports')
    };


    const cancel = () => {
        dispatch({type:LensActions.EditReport, payload: null })
        dispatch({type:LensActions.CreateReport, payload: null })
        history.push(`/reports`)
    }

    const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (

        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "270px"
        }}>
            <div>
                <InputLabel shrink htmlFor="bootstrap-input">
                    {t("selectTimeFrame")}
                </InputLabel>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "250px",
                height: "40px",
                border: "1px solid lightgrey",
                borderRadius: "5px"
            }}>

                <Typography
                    style={{fontSize: 14, marginRight: "10px", color: "#636364", marginTop: "5px", width: "200px"}}
                >
                    &nbsp;&nbsp;{new moment(startDate).format('MMM DD, YYYY')} - {endDate ? new moment(endDate).format('MMM DD, YYYY') : ""}
                </Typography>
                <Divider sx={{height: 40, mr: 0.5}} orientation="vertical"/>
                <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
                     style={{
                         backgroundColor: "#1F9997",
                         border: "3px solid #1F9997",
                         borderRadius: "5px",
                         width: "25px",
                         height: "25px"
                     }}
                />
            </div>
        </Box>
    ));

    return (
        <div style={{position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)`}}>
            <ContentHeader title={t("reportEditReport")}/>

            <Box textAlign='left'
                 sx={{
                     minWidth: "500px",
                     borderColor: 'lightgrey',
                     borderRadius: '5px',
                     borderWidth: '1px',
                     borderStyle: 'solid',
                     backgroundColor: '#1F9997',
                     marginBottom: '5px'
                 }}
            >
                <Typography type="body2" className={classes.title}>
                    {t("editReport")}
                </Typography>

            </Box>

            <Typography type="body2">
                {t("reportID")}: {getOneReport?.data?.id}
            </Typography>
            <br/><br/>

            <Box component="form"
                 onSubmit={handleSubmit(handleSaveSubmit)}
            >
                <Box style={{display: "flex", flexDirection: "row"}}>

                    <div style={{width: "270px"}}>

                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t("reportLabel.1")}
                        </InputLabel>

                        <input className={classes.input} name="name"

                               {...register("name")}
                        >
                        </input>

                        <FormHelperText
                            style={{color: "red"}}>{Boolean(errors.name?.message) ? errors.name?.message : null}</FormHelperText>
                    </div>

                    <div style={{width: "270px"}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t("reportType.2")}
                        </InputLabel>

                        <Select
                            value={type}
                            onChange={handleChangeType}
                            style={{marginRight: "20px", width: "250px"}}
                            size="small"
                        >
                            {reportTypes?.data?.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText
                            style={{color: "red"}}>{Boolean(errors.type?.message) ? errors.type?.message : null}</FormHelperText>
                    </div>

                    <div style={{width: "270px"}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t("testCaseGroup.2")}
                        </InputLabel>

                        <Select
                            value={group}
                            onChange={handleChangeGroup}
                            style={{marginRight: "20px", width: "250px"}}
                            size="small"
                        >
                            {testGroups?.data?.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText
                            style={{color: "red"}}>{Boolean(errors.group?.message) ? errors.group?.message : null}</FormHelperText>
                    </div>


                    <DatePicker
                        selected={startDate}
                        onChange={onChange}

                        customInput={<ExampleCustomInput/>}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                    />
                </Box>

                <br/><br/>

                <Box style={{display: type && type.id === 6 ? "flex" : "none", flexDirection: "row"}}>
                    <div style={{width: "270px"}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t("latencyThresholdWarnings")}
                        </InputLabel>

                        <Select
                            value={warning}
                            onChange={handleChangeWarning}
                            style={{marginRight: "20px", width: "250px"}}
                            size="small"
                        >
                            {(latency || []).map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText
                            style={{color: "red"}}>{Boolean(errors.warning?.message) ? errors.warning?.message : null}</FormHelperText>
                    </div>

                    <div style={{width: "270px"}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t("latencyThresholdFailures")}
                        </InputLabel>

                        <Select
                            value={failure}
                            onChange={handleChangeFailure}
                            style={{marginRight: "20px", width: "250px"}}
                            size="small"
                        >
                            {(latency || []).map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText
                            style={{color: "red"}}>{Boolean(errors.failure?.message) ? errors.failure?.message : null}</FormHelperText>
                    </div>
                </Box>
                <br/>
                <Box textAlign='left'
                     sx={{
                         minWidth: "500px",
                         borderColor: 'lightgrey',
                         borderRadius: '5px',
                         borderWidth: '1px',
                         borderStyle: 'solid',
                         backgroundColor: '#E8F3F4',
                         marginTop: type && type.name === Latency ? '100px' : '165px'
                     }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{mt: 1, mb: 1, ml: 2}}
                        style={{
                            borderRadius: 5,
                            backgroundColor: "#1f9997",
                            fontSize: "18px",
                            width: "200px",
                        }}
                    >
                        <Typography style={{textTransform: 'none', color: "white"}}>{t("save")}</Typography>
                    </Button>

                    <Button
                        onClick={cancel}
                    >
                        <Typography style={{textTransform: 'none', color: "grey"}}>{t("cancel")}</Typography>
                    </Button>
                </Box>
            </Box>
        </div>

    )
}

export default EditReportContent
