import "./Telco.agent.css";
import bg_enable from "../../images/bg_enabled.svg";
import bg_enable_big from "../../images/bg_enable_big.svg";
import telco_en from "../../images/telco_green.svg";
import db from "../../images/db.svg";
import bg_light_green from "../../images/bg_light_green.svg";
import bg_light_green_big from "../../images/bg_light_green_big.svg"
import headphone_enabled from "../../images/headphone_enabled.svg";
import { useMemo } from "react";
import headphone_red from "../../images/headphone_disabled.svg";
import db_red from "../../images/db_red.svg";
import bg_red_big from "../../images/bg_red_big.svg";
import bg_red from "../../images/bg_light_red.svg";
import bg_grey from "../../images/bg_light_grey.svg";
import bg_grey_big from "../../images/bg_grey_big.svg";
import bg_big_grey from "../../images/bg_big_grey.svg";
import bg_light_grey_big from "../../images/bg_light_grey_big.svg"
import headphone_white from "../../images/headphone_white.svg";
import db_grey from "../../images/db_grey.svg";
import bg_big_red from "../../images/bg_big_red.svg";
import bg_big_red_big from "../../images/bg_big_red_big.svg"
import telco_red from "../../images/telco_red.svg";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const TelcoAgent = ({ clientApp = null, big = false }) => {
    const { t } = useTranslation();

    const agentIconColor = useMemo(() => {
        if (clientApp.lastRun.agentStatus.enabled === true) {
            if (clientApp.lastRun.agentStatus.status === 'TRS_PASS') {
                return headphone_enabled;
            } else {
                return headphone_red;
            }
        }
        return headphone_white;
    }, [clientApp])

    const ivrIcon = useMemo(() => {
        if (clientApp.lastRun.appStatus.enabled === true) {
            if (clientApp.lastRun.appStatus.status === 'TRS_PASS') {
                return big ? bg_enable_big : bg_enable;
            } else {
                return big ? bg_red_big : bg_red
            }
        }
        else {
            return big ? bg_grey_big : bg_grey;
        }


    }, [clientApp]);


    const agentBgIcon = useMemo(() => {
        if (clientApp.lastRun.agentStatus.enabled === true) {
            if (clientApp.lastRun.agentStatus.status === 'TRS_PASS') {
                return big ? bg_light_green_big : bg_light_green;
            } else {
                return big ? bg_big_red_big : bg_big_red;
            }
        }
        return big ? bg_light_grey_big : bg_big_grey;
    }, [clientApp]);


    const telcoIncon = useMemo(() => {
        if (clientApp.lastRun.telcoStatus.enabled === true) {
            if (clientApp.lastRun.telcoStatus.status === 'TRS_PASS') {
                return telco_en;
            } else {
                return telco_red;//Red icon
            }
        }
        else {
            return telco_en;//Gray icon
        }

    }, [clientApp]);

    const dbIcon = (cmp) => {
        let componentStatus = null;
        if (cmp) {
            componentStatus = cmp;
        } else {
            const index = clientApp.lastRun.componentStatus.length - 1;
            componentStatus = clientApp.lastRun.componentStatus[index];
        }

        if (componentStatus.hasComponent === true) {
            if (componentStatus.enabled === true) {
                if (clientApp.lastRun.telcoStatus.status === 'TRS_PASS') {
                    return db;
                } else {
                    return db_red;
                }
            }
            else {
                return db;//Gray icon
            }
        }
        else {
            return null;
        }
    }

    return (
        big ? (
            <>
            <div className="w-338">
                <div className="dbase-big">
                    {clientApp?.lastRun?.componentStatus?.map((cmp, index) => (
                        dbIcon(cmp) && (
                            <div>
                                <img key={`cmp_${index}`} src={dbIcon(cmp)} />
                                <div className="dbase-title" title={cmp.name}>{cmp.name}</div>
                            </div>
                        )
                    ))}

                </div>
                <div className="headphone">
                    <img src={agentIconColor} />
                </div>
                <div className="agent-names-big">
                    {clientApp?.clientEndPoint}
                </div>
                <div className="bg-light">
                    <img src={agentBgIcon} />
                </div>
                <div className="agent-left">
                    <img src={telcoIncon} />
                </div>
                <div className="agent-phone">
                    <img src={ivrIcon} />
                </div>
            </div>
            <Typography fontWeight={400} fontSize='12px' lineHeight='14.4px' style={{display: 'flex', justifyContent: 'center'}}>
                {t("last run")}: {new moment(clientApp?.lastRun?.runTime).format('HH:MM DD.MM.YYYY')}
            </Typography>
            </>
            
        ) : (
            <div className="w-171">
                <div className="dbase">
                    <img src={dbIcon()} />
                </div>
                <div className="headphone">
                    <img src={agentIconColor} />
                </div>
                <div className="agent-names">
                    {clientApp?.clientEndPoint}
                </div>
                <div className="bg-light">
                    <img src={agentBgIcon} />
                </div>
                <div className="agent-left">
                    <img src={telcoIncon} />
                </div>
                <div className="agent-phone">
                    <img src={ivrIcon} />
                </div>
            </div>
        )
    )
}