import { Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import minimap from "wavesurfer.js/dist/plugin/wavesurfer.minimap";
import timeline from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import markers from "wavesurfer.js/dist/plugin/wavesurfer.markers"
import regions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import { downloadFile, setCurrentPlayer, setPlayerStatus } from "../../actions";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DiffInMilliSeconds, DiffInSeconds, getStartTime } from "../../helper/date";
import { flatten } from "lodash";

const formWaveSurferOptions = ref => ({
    container: ref,
    waveColor: "#eee",
    progressColor: "OrangeRed",
    cursorColor: "OrangeRed",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 150,
    normalize: true,
    partialRender: true,
    plugins: [
        //regions.create(),
        markers.create(),
        timeline.create({
            container: '#wave-timeline'
        })
    ]

});

export const AudioPlayer = ({ url = {}, times = [] }) => {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [index, setIndex] = useState(url?.index);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(1);
    const dispatch = useDispatch();
    const currentPlayer = useSelector(state => state.reportReducer.currentPlayer);
    const currentPlayerStatus = useSelector(state => state.reportReducer.currentPlayerStatus);

    const loadAudio = async () => {
        const file = await downloadFile(url?.id, url?.url, false);
        wavesurfer.current.loadBlob(file);
    }

    const getTimes = useMemo(() => flatten(times.map(t => (t.stepRuns || t.steps).map(sr => ({
        ...sr,
        segmentName: t.segmentName || t.name,
    })))), [times]);

    useEffect(() => {
        setPlay(false);

        if (url) {
            const options = formWaveSurferOptions(waveformRef.current);
            wavesurfer.current = WaveSurfer.create({ ...options, progressColor: '#3c8ebd' });

            loadAudio();

            wavesurfer.current.on("ready", () => {
                if (wavesurfer.current) {

                    let preSeconds = 0.0;

                    getTimes.map((time, index) => {
                        preSeconds += index === 0 ? 0 : DiffInSeconds(getTimes[index - 1].endTime || getTimes[index - 1]?.testResult?.endTime, time.startTime || time?.testResult?.startTime)

                        wavesurfer.current.addMarker({
                            time: preSeconds + (time.prompt ? (index === 1 ? 1.6 : 1) : 0),
                            label: time.prompt ? time.segmentName : time.playMessage,
                            position: time.prompt ? 'top' : 'bottom',
                            color: time.prompt ? '#000' : 'red',
                            tooltip: time.prompt || time.playMessage,
                            preventContextMenu: true,
                        });
                        //console.log(preSeconds, time.prompt ? time.segmentName : time.playMessage)
                        preSeconds += (DiffInMilliSeconds(time.endTime || time?.testResult?.endTime, time.startTime || time?.testResult?.startTime)/1000);
                    });

                    wavesurfer.current.setVolume(volume);
                    setVolume(volume);
                    document.querySelectorAll('.marker-label')[0]
                        .addEventListener('click', () => {
                            console.log('clicked')
                        })
                }
            });


            wavesurfer.current.on('finish', function () {
                if (index === url.total) {
                    setPlayerStatus('FINISH')(dispatch);
                } else {
                    setCurrentPlayer(index + 1)(dispatch);
                }
            });

            wavesurfer.current.on('seek', () => {
                const currentIndex = +wavesurfer.current.container.getAttribute('data-id');
                const currentPlayerIndex = +wavesurfer.current.container.getAttribute('data-player');

                if (currentIndex < currentPlayerIndex) {
                    setPlayerStatus(`STOP_${currentIndex}`)(dispatch);
                } else if (currentIndex > currentPlayerIndex) {
                    setPlayerStatus(`FOWARD_${currentIndex}`)(dispatch);
                }
            });
            return () => wavesurfer.current.destroy();
        }
    }, [url]);

    const handlePlayPause = () => {
        if (!playing) {
            setPlayerStatus('PLAY')(dispatch);
        } else {
            setPlayerStatus('PAUSE')(dispatch);
        }

        setPlay(!playing);
    };

    const onVolumeChange = e => {
        const { target } = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            wavesurfer.current.setVolume(newVolume || 1);
        }
    };

    useEffect(() => {
        if (currentPlayerStatus === 'FINISH') {
            setPlay(false);
            setPlayerStatus('PAUSE')(dispatch);
            setCurrentPlayer(1)(dispatch);
            wavesurfer.current.stop(1);
        } else if (currentPlayerStatus?.indexOf('FOWARD') !== -1) {
            const d = +currentPlayerStatus.split('_')[1];
            if (index < d) {
                wavesurfer.current.seekTo(1);
            } else if (index === d) {
                setCurrentPlayer(index)(dispatch);
                setPlayerStatus('PLAY')(dispatch);
            }
            setPlay(true);
        } else if (currentPlayerStatus?.indexOf('STOP') !== -1) {
            const d = +currentPlayerStatus.split('_')[1];
            if (index > d) {
                wavesurfer.current.stop();
            } else if (index === d) {
                setCurrentPlayer(index)(dispatch);
                setPlayerStatus('PLAY')(dispatch);
            }
            setPlay(true);
        } else if (currentPlayerStatus === 'PLAY' && currentPlayer === index) {
            wavesurfer.current.play();
        } else {
            wavesurfer.current.pause();
        }
    }, [currentPlayer, currentPlayerStatus]);

    return (
        <div className="mt-30 mb-20">
            {/* {index % 2 !== 0 ? (
                <div className="waver-border"><Typography sx={{ ml: 1 }} variant="caption">{url?.runId} - {url?.title}</Typography></div>
            ) : (
                <div><Typography sx={{ ml: 1 }} variant="caption">&nbsp;</Typography></div>
            )} */}
            <div id="waveform" ref={waveformRef} data-id={index} data-player={currentPlayer} />
            <div id="wave-timeline"></div>

            {/* {index % 2 === 0 && (
                <div className="waver-border"><Typography sx={{ ml: 1 }} variant="caption">{url?.index}-{url?.runId} - {url?.title}</Typography></div>
            )} */}

            <div className="controls">
                {url?.index === 1 && (
                    <>
                        {!playing ? (
                            <PlayCircleOutlineIcon onClick={handlePlayPause} className="pointer" />
                        ) : (
                            <PauseCircleOutlineIcon onClick={handlePlayPause} className="pointer" />
                        )}
                    </>
                )}

                {url?.volume && (
                    <>
                        <input
                            type="range"
                            id="volume"
                            name="volume"
                            // waveSurfer recognize value of `0` same as `1`
                            //  so we need to set some zero-ish value for silence
                            min="0.01"
                            max="1"
                            step=".025"
                            onChange={onVolumeChange}
                            defaultValue={volume}
                        />
                        <label htmlFor="volume">Volume</label>
                    </>
                )}
            </div>
        </div>
    );
}
