import {
    Box,
    Checkbox,
    Grid,
    ListItemText,
    Modal, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import sort from "../../../images/sort-arrow.png"
import * as echarts from "echarts";
import CaseGroup from "../../../helper/CaseGroup";
import { getDashboardData, getGroupData, groupsList, LensActions, updateCaseGroups } from "../../../actions";
import { useTranslation } from 'react-i18next';

import timeLogo from "../../../images/Group 196.svg"
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { groupBy } from "lodash";
import { sumBy } from "lodash";
import { flatten, chunk, fill } from "lodash";
import { date } from "yup";
import { isMobile } from 'react-device-detect';
import { TestCaseGroupChannel } from "../../report/test_case_group/Test-Case-Group-Channel";
import { TestRunResult } from "../TestRunResult";

// const PASS = 0
// const WARN = 1
// const FAIL = 2
// const WARNCOLOR = "#FF7D42"
// const FAILCOLOR = "#4CAF50"
// const getFormat = (count) =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         time: `${k < 10 ? ("0" + k) : ("" + k)}` + ":00",
//         count: 0,
//     }));

// const statusFormat = getFormat(24);

// const useStyles = makeStyles((theme) => ({
//     flex: {
//         display: 'flex',
//         alignItems: 'center',
//     },

//     container: {
//         paddingTop: theme.spacing(4),
//         width: "100%",
//     },
//     toolbar: {
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//     },
//     group: {
//         display: "flex",
//     },


//     tableHead: {
//         background: "#E8F3F4 0% 0% no-repeat padding-box",
//         height: 53,

//         '& th': {
//             fontSize: '18px',
//             color: '#000000',
//             fontFamily: 'Poppins',
//             fontWeight: '500',
//             cursor: 'pointer',

//             '& span': {
//                 paddingLeft: '32px',
//             },

//             [theme.breakpoints.up('xs')]: {
//                 '& span': {
//                     fontSize: '12px',
//                     paddingLeft: '10px',
//                 },
//             }
//         },
//     },
//     largeGraph: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         backgroundColor: 'white',
//         border: 0,
//         boxShadow: 24,
//         [theme.breakpoints.up('xs')]: {
//             width: isMobile ? "95%" : "50%",
//         }
//     },

//     graph: {
//         height: "85px",
//         width: "100%",
//         borderRadius: "10px",
//         boxShadow: "0px 0px 10px #00000029",
//         [theme.breakpoints.up('xs')]: {
//             width: "100%",
//         }
//     },

//     truncate: {
//         width: '410px',
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//         textOverflow: 'ellipsis',
//         fontWeight: 500,
//         color: '#636364',
//         fontSize: '1.5rem',
//         paddingLeft: '9px',
//     }

// }));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;



const ContentDetails = ({
    classes,
    rates,
    caseGroup,
    groupName,
    checkedAll,
    checkedAllRates,
    handleChangeGroupRates,
    rate,
    value,
    setValue,
    selectedRates,
    handleSelectedRates,
    openGraph,
    setOpenGraph,
    chooseIndex,
    setChooseIndex,
    groupsLists,
    handleChange,
    handleChangeGroup,
    orderByGroup,
    handleCloseGraph,
    ExampleCustomInput,
    orderByCount,
    WARN,
    WARNCOLOR,
    FAIL,
    FAILCOLOR,
    showGraph,
    topbarVisible
}

) => {
    const { t } = useTranslation();
    // const classes = useStyles();
    // const dispatch = useDispatch()
    // const [testGroupAll, setTestGroupAll] = useState(null)
    // const [rates, setRates] = useState(null)
    // const [caseGroup, setCaseGroup] = useState(null)
    // //const [originalData, setOriginalData] = useState(null)
    // const [data, setData] = useState([])
    // const [groupName, setGroupName] = React.useState([]);
    // const [checkedAll, setCheckedAll] = React.useState(true);
    // const [checkedAllRates, setCheckedAllRates] = React.useState(true);
    // const [rate, setRate] = useState('')
    // const [value, setValue] = useState(process.env.REACT_APP_DEFAULT_DATE ? new Date(process.env.REACT_APP_DEFAULT_DATE) : new Date())
    // const [graphCount, setGraphCount] = useState(0)
    // const [orderGroup, setOrderGroup] = useState(0)
    // const [orderCount, setOrderCount] = useState(0)
    // const [selectedRates, setSelectedRates] = useState([])

    // useEffect(() => {
    //     groupsList()(dispatch);
    // }, [])

    // useEffect(() => {
    //     getDashboardData(moment(value).format('YYYY-MM-DD'))(dispatch);
    // }, [value])


    // const [openGraph, setOpenGraph] = React.useState(false);
    // const [chooseIndex, setChooseIndex] = useState(null)
    // const groupsLists = useSelector(state => state.loginReducer.groupsList);
    // const originalData = useSelector(state => state.loginReducer.originalData);

    // useEffect(() => {
    //     if (originalData && originalData.length) {

    //         const group = groupBy(originalData, 'groupId');
    //         const gpName = [];
    //         const realData = Object.entries(group).map(([key, value]) => {
    //             return {
    //                 entityId: key,
    //                 entityName: value[0]?.groupName,
    //                 failureCount: sumBy(value, 'failureCount'),
    //                 successCount: sumBy(value, 'successCount'),
    //                 warningCount: sumBy(value, 'warningCount'),
    //                 entityType: 'case',
    //                 channel: value[0]?.channel,
    //                 periodEnd: value[0]?.periodEnd,
    //                 periodStart: value[0]?.periodStart,
    //                 hourlyStatus: Object.entries(groupBy(flatten(value.map(c => c.hourlyStatus)), 'hourly')).map(([key1, value1]) => {
    //                     return {
    //                         hourly: key1,
    //                         failureCount: sumBy(value1, 'failureCount'),
    //                         successCount: sumBy(value1, 'successCount'),
    //                         warningCount: sumBy(value1, 'warningCount'),
    //                     }
    //                 }),
    //             }
    //         });

    //         const caseGroups = CaseGroup.transformCaseGroups(realData);
    //         setCaseGroup(caseGroups);

    //         const tempRates = CaseGroup.statusTypes
    //         setRates([...tempRates])
    //         setSelectedRates([0, 1, 2]);
    //         setRate(0);

    //         let tempGraphData = [];
    //         caseGroups.forEach((group) => {
    //             if (group.hourlyStatus.length > 0) {
    //                 if (selectedRates?.length > 1) {
    //                     for (let i = 0; i < rates.length; i++) {
    //                         let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
    //                         if (history.length < 24) {
    //                             let temp = _.differenceBy(statusFormat, history, 'time');
    //                             let tempStatus = _.unionWith(history, temp, _.isEqual);
    //                             let result = _.sortBy(tempStatus, function (item) { return item.time; })
    //                             history = result;

    //                         }

    //                         let count = history.map(value => value.count)

    //                         tempGraphData.push(count)
    //                     }

    //                     setData([...tempGraphData]);
    //                 } else {
    //                     tempGraphData.push([])
    //                 }
    //             }
    //         })

    //         updateCaseGroups(caseGroups)(dispatch);
    //         setGroupName(groupsLists?.data?.map(g => g.name));

    //     }
    // }, [originalData])


    // const handleCloseGraph = () => setOpenGraph(false);

    // useEffect(() => {

    //     if (checkedAll && testGroupAll) {
    //         let temp = testGroupAll.map((value) => value)
    //         setGroupName([...temp])
    //     }
    // }, [testGroupAll]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setGroupName(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    //     setOrderCount(orderCount % 2 === 0 ? 1 : 0)

    // };


    // useEffect(() => {
    //     let tempGraphData = [];
    //     const _caseGroup = (caseGroup || []).filter(c => groupName.includes(c.name));

    //     _caseGroup.forEach((group) => {
    //         if ((group.hourlyStatus.length > 0) && (selectedRates !== 0)) {
    //             for (let i = 0; i < rates.length; i++) {
    //                 let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
    //                 if (history.length < 24) {
    //                     let temp = _.differenceBy(statusFormat, history, 'time');
    //                     let tempStatus = _.unionWith(history, temp, _.isEqual);
    //                     let result = _.sortBy(tempStatus, function (item) { return item.time; })
    //                     history = result;

    //                 }

    //                 let count = history.map(value => value.count)

    //                 tempGraphData.push(count)
    //             }

    //             setData([...tempGraphData]);

    //         } else {
    //             tempGraphData.push([])
    //             setData([...tempGraphData]);
    //         }
    //     });


    //     updateCaseGroups(_caseGroup)(dispatch);

    //     orderByCount(true);
    // }, [groupName, selectedRates]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleChangeGroup = (event) => {
    //     setCheckedAll(event.target.checked);

    //     if (event.target.checked) {
    //         setGroupName(groupsLists?.data?.map((value) => value.name))
    //     } else {
    //         setGroupName([])
    //         setData([])
    //     }
    // }


    // useEffect(() => {
    //     setCheckedAll(groupName?.length === groupsLists?.data?.length);
    // }, [groupsLists, groupName]);




    // const handleChangeGroupRates = (event) => {
    //     setCheckedAllRates(event.target.checked);

    //     if (event.target.checked) {
    //         setSelectedRates([0, 1, 2])
    //     } else {
    //         setSelectedRates([])
    //         setData([])

    //     }

    // }



    // const handleSelectedRates = (event) => {

    //     const {
    //         target: { value },
    //     } = event;

    //     setSelectedRates(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );

    // };

    // useEffect(() => {

    //     if (selectedRates?.length == 1) {
    //         setRate(selectedRates[0])
    //     } else {
    //         setCheckedAllRates(selectedRates?.length > 1 && (selectedRates?.length === rates?.length))
    //     }

    // }, [selectedRates]);


    // useEffect(() => {
    //     if (caseGroup && caseGroup.length && data && data.length) {
    //         let temp = [];
    //         temp = chunk(data, 3);
    //         if (selectedRates?.length == 1) {
    //             if (rate == 0) {
    //                 for (let i = 0; i < caseGroup.length; i++) {

    //                     _.fill(temp[i]?.[1], 0);
    //                     _.fill(temp[i]?.[2], 0);
    //                 }
    //             } else if (rate == 1) {
    //                 for (let i = 0; i < caseGroup.length; i++) {

    //                     _.fill(temp[i]?.[0], 0);
    //                     _.fill(temp[i]?.[2], 0);
    //                 }

    //             } else if (rate == 2) {
    //                 for (let i = 0; i < caseGroup.length; i++) {

    //                     _.fill(temp[i]?.[0], 0);
    //                     _.fill(temp[i]?.[1], 0);
    //                 }

    //             }
    //         } else if ((selectedRates.length == 2) && (selectedRates.indexOf(2) == -1)) {

    //             for (let i = 0; i < caseGroup.length; i++) {

    //                 _.fill(temp[i]?.[2], 0);

    //             }
    //         } else if ((selectedRates.length == 2) && (selectedRates.indexOf(0) == -1)) {
    //             for (let i = 0; i < caseGroup.length; i++) {

    //                 _.fill(temp[i]?.[0], 0);

    //             }
    //         } else if ((selectedRates.length == 2) && (selectedRates.indexOf(1) == -1)) {
    //             for (let i = 0; i < caseGroup.length; i++) {

    //                 _.fill(temp[i]?.[1], 0);

    //             }
    //         }

    //         if (selectedRates?.length !== 0 && temp.length >= 1) {
    //             setTimeout(function () {
    //                 for (let i = 0; i < caseGroup.length; i++) {
    //                     createChart(`main_${caseGroup[i].id}`, temp[i]);

    //                     if (document.getElementById(`main_${caseGroup[i].id}`)) {
    //                         document.getElementById(`main_${caseGroup[i].id}`).style.width = '99%';

    //                     }
    //                 }
    //             }

    //                 , 1);

    //         } else {

    //             setTimeout(function () {
    //                 for (let i = 0; i < caseGroup.length; i++) {

    //                     createChart(`main_${caseGroup[i].id}`, []);

    //                     if (document.getElementById(`main_${caseGroup[i].id}`)) {
    //                         document.getElementById(`main_${caseGroup[i].id}`).style.width = '99%';

    //                     }
    //                 }
    //             }

    //                 , 1);
    //         }

    //     }

    // }, [caseGroup, rate, groupName, selectedRates, data]);

    // useEffect(() => {
    //     if (chooseIndex) {
    //         let tempGraphData = [];
    //         caseGroup.filter(cg => cg.id === chooseIndex.id).forEach((group) => {
    //             if ((group.hourlyStatus.length > 0) && (selectedRates.length !== 0)) {

    //                 for (let i = 0; i < rates.length; i++) {
    //                     let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
    //                     if (history.length < 24) {
    //                         let temp = _.differenceBy(statusFormat, history, 'time');
    //                         let tempStatus = _.unionWith(history, temp, _.isEqual);
    //                         let result = _.sortBy(tempStatus, function (item) { return item.time; })
    //                         history = result;

    //                     }

    //                     let count = history.map(value => value.count)

    //                     tempGraphData.push(count)
    //                 }

    //                 if (selectedRates.length == 1) {
    //                     switch (rate) {
    //                         case 0:
    //                             _.fill(tempGraphData[1], 0)
    //                             _.fill(tempGraphData[2], 0)
    //                             break
    //                         case 1:
    //                             _.fill(tempGraphData[0], 0)
    //                             _.fill(tempGraphData[2], 0)
    //                             break
    //                         case 2:
    //                             _.fill(tempGraphData[0], 0)
    //                             _.fill(tempGraphData[1], 0)

    //                     }

    //                 } else if ((selectedRates.length == 2) && (selectedRates.indexOf(2) == -1)) {

    //                     _.fill(tempGraphData[2], 0);

    //                 } else if ((selectedRates.length == 2) && (selectedRates.indexOf(0) == -1)) {

    //                     _.fill(tempGraphData[0], 0);

    //                 } else if ((selectedRates.length == 2) && (selectedRates.indexOf(1) == -1)) {

    //                     _.fill(tempGraphData[1], 0);

    //                 }

    //                 setTimeout(function () {
    //                     createChartDetail(`main`, tempGraphData);
    //                 }
    //                     , 1)

    //             } else {
    //                 tempGraphData.push([])
    //             }
    //         })

    //     }
    // }, [chooseIndex, graphCount, groupName, selectedRates]) // eslint-disable-line react-hooks/exhaustive-deps

    // function createChartDetail(divId, data) {

    //     let myChart = echarts.init(document.getElementById(divId));

    //     if (myChart == null) {

    //         let myChart = echarts.init(document.getElementById(divId));

    //     }
    //     let option = {
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //                 type: 'shadow'
    //             },
    //             backgroundColor: '#E8F3F4',
    //             borderColor: '#c8e2f7',
    //             borderRadius: '10px',
    //             textStyle: {
    //                 color: 'black',
    //             },
    //             formatter: (param) => {
    //                 let res = '<div style="background-color: #1F9997; margin: -10px -10px; padding: 20px; color: white">' + "Time Slot : " + param[0].name + '</div>' + '<br/>' + '<div>';
    //                 for (let x = 0; x < param.length; x++) {
    //                     res += `<div style="background-color: ${param[x].color}; height: 15px; width: 15px; display: inline-block; margin-left: 10px">` + '</div>' + '&nbsp;&nbsp;' + "Test Case Runs" + ': ' + param[x].data + '<br/>';
    //                 }
    //                 res += '</div>'
    //                 return res
    //             }
    //         },
    //         xAxis: {
    //             type: 'category',
    //             data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
    //                 '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    //         },
    //         yAxis: {
    //             type: 'value',
    //             position: 'left',
    //             axisLabel: {
    //                 formatter: '{value} ',
    //             },
    //             interval: 1,
    //         },

    //         series: [
    //             {
    //                 name: 'Pass',
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: FAILCOLOR
    //                 },
    //                 stack: 'total',
    //                 data: data?.[0],
    //             },
    //             {
    //                 name: "Warning",
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: WARNCOLOR
    //                 },
    //                 stack: 'total',
    //                 data: data[1],
    //             },
    //             {
    //                 name: "Fail",
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: 'red'
    //                 },
    //                 stack: 'total',
    //                 data: data[2],
    //             }
    //         ]
    //     };
    //     myChart.clear();
    //     myChart.setOption(option);
    // }

    // function createChart(divId, data) {
    //     if (!document.getElementById(divId)) {
    //         return;
    //     }

    //     let myChart = echarts.init(document.getElementById(divId));

    //     if (myChart == null) {

    //         let myChart = echarts.init(document.getElementById(divId));

    //     }
    //     let width = 0.99 * document.getElementById(divId).offsetWidth;

    //     let option = {
    //         width: 'auto',
    //         xAxis: {
    //             show: false,
    //             type: 'category',
    //             data: ['0', "", "", "", "", "", "6", "", "", "", "", "", "12", "", "", "", "", "", "18", "", "", "", "", '', "24"]
    //         },
    //         yAxis: {
    //             type: 'value',
    //             position: 'left',
    //             show: false,
    //             axisLabel: {
    //                 formatter: '{value} ',
    //             }
    //         },

    //         series: [
    //             {
    //                 name: 'Pass',
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: FAILCOLOR
    //                 },
    //                 stack: 'total',
    //                 data: data?.[0],
    //             },
    //             {
    //                 name: "Warning",
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: WARNCOLOR
    //                 },
    //                 stack: 'total',
    //                 data: data?.[1],
    //             },
    //             {
    //                 name: "Fail",
    //                 type: 'bar',
    //                 itemStyle: {
    //                     color: 'red'
    //                 },
    //                 stack: 'total',
    //                 data: data?.[2],
    //             }
    //         ]
    //     };
    //     myChart.resize({ width: width });
    //     myChart.clear();
    //     myChart.setOption(option);
    // }


    // const showGraph = (index) => {
    //     setOpenGraph(true)
    //     setChooseIndex(index)
    //     setGraphCount(graphCount + 1)
    // }

    // const orderByGroup = () => {
    //     let tempCaseGroup = _.cloneDeep(caseGroup)

    //     if (orderGroup % 2) {
    //         tempCaseGroup.sort((a, b) => {
    //             if (a.name > b.name) {
    //                 return -1;
    //             }
    //             if (a.name < b.name) {
    //                 return 1;
    //             }
    //             return 0;

    //         })
    //     } else {
    //         tempCaseGroup.sort((a, b) => {
    //             if (a.name < b.name) {
    //                 return -1;
    //             }
    //             if (a.name > b.name) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     }
    //     setCaseGroup(tempCaseGroup)
    //     let tempGraphData = []
    //     tempCaseGroup?.forEach((group) => {
    //         if (group.hourlyStatus.length > 0) {
    //             for (let i = 0; i < rates?.length; i++) {
    //                 let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
    //                 if (history.length < 24) {
    //                     let temp = _.differenceBy(statusFormat, history, 'time');
    //                     let tempStatus = _.unionWith(history, temp, _.isEqual);
    //                     let result = _.sortBy(tempStatus, function (item) { return item.time; })
    //                     history = result;

    //                 }

    //                 let count = history.map(value => value.count)

    //                 tempGraphData.push(count)
    //             }

    //             setData([...tempGraphData]);

    //         } else {
    //             tempGraphData.push([])
    //             setData([...tempGraphData]);
    //         }

    //     })

    //     setOrderGroup(orderGroup + 1)
    // }

    // const orderByCount = (oc = false) => {
    //     let tempCaseGroup = _.cloneDeep(caseGroup)

    //     if (!tempCaseGroup) {
    //         return;
    //     }
    //     if (!oc && orderCount % 2) {
    //         tempCaseGroup.sort((a, b) => {
    //             if (a.perCounts[rate] < b.perCounts[rate]) {
    //                 return -1;
    //             }
    //             if (a.perCounts[rate] > b.perCounts[rate]) {
    //                 return 1;
    //             }
    //             return 0;

    //         })
    //     } else {
    //         tempCaseGroup.sort((a, b) => {
    //             if (a.perCounts[rate] > b.perCounts[rate]) {
    //                 return -1;
    //             }
    //             if (a.perCounts[rate] < b.perCounts[rate]) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     }

    //     setCaseGroup(tempCaseGroup)
    //     let tempGraphData = []
    //     tempCaseGroup?.forEach((group) => {
    //         if (group.hourlyStatus.length > 0) {
    //             for (let i = 0; i < rates?.length; i++) {
    //                 let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
    //                 if (history.length < 24) {
    //                     let temp = _.differenceBy(statusFormat, history, 'time');
    //                     let tempStatus = _.unionWith(history, temp, _.isEqual);
    //                     let result = _.sortBy(tempStatus, function (item) { return item.time; })
    //                     history = result;

    //                 }

    //                 let count = history.map(value => value.count)

    //                 tempGraphData.push(count)
    //             }

    //             setData([...tempGraphData]);

    //         } else {
    //             tempGraphData.push([])
    //             setData([...tempGraphData]);
    //         }

    //     })

    //     setOrderCount(orderCount + 1)
    // }

    // const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

    //     <Box className="date-picker-dashboard">
    //         <Typography variant="subtitle1">
    //             <div style={{ fontSize: 14, marginTop: isMobile ? 16 : 0 }}>&nbsp; {t("selectDate")}</div>
    //         </Typography>
    //         <div className="border">
    //             <div>
    //                 <Typography
    //                     style={{ fontSize: 14 }}
    //                 >
    //                     {new moment(value).format('MMM DD, YYYY')}
    //                 </Typography>
    //             </div>
    //             <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
    //                 style={{
    //                     backgroundColor: "#1F9997",
    //                     border: "3px solid #1F9997",
    //                     borderRadius: "5px",
    //                     width: "33px",
    //                     height: "33px"
    //                 }}
    //             />
    //         </div>
    //     </Box>
    // ));


    return (
        <div className={classes.container}>
            <Grid container spacing={isMobile ? 2 : 0}>
                
                <Grid item xs={isMobile ? 12: 4}>
                {!topbarVisible && (
                    <Grid container className={classes.toolbar}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <div style={{ fontSize: 14, marginTop: isMobile ? 16 : 0 }}>{t("selectTestCaseGroups")}</div>
                            </Typography>
                            <FormControl sx={{ m: 1, ml: 0, width: isMobile ? '100%' : '80%' }} size="small">
                                <InputLabel id="demo-multiple-chip-label"></InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={groupName}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" />}
                                    renderValue={(selected) => `${selected.length} of ${groupsLists?.data?.length} ${t("selected")}`}
                                    style={{ fontSize: 14 }}
                                >
                                    <MenuItem value="select all">
                                        <Checkbox checked={checkedAll}
                                            onChange={handleChangeGroup}
                                            style={{ transform: "scale(.75)" }}
                                            sx={{
                                                color: "#1f9997",
                                                "&.Mui-checked": {
                                                    color: "#1f9997",
                                                },
                                            }}
                                        />
                                        <ListItemText primary={t("selectAll")} />
                                    </MenuItem>

                                    {groupsLists ? groupsLists?.data?.map(({ name, channel }, index) => (
                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={groupName.indexOf(name) > -1}
                                                style={{ transform: "scale(.75)" }}
                                                sx={{
                                                    color: "#1f9997",
                                                    "&.Mui-checked": {
                                                        color: "#1f9997",
                                                    },
                                                }}
                                            />
                                            <ListItemText>
                                                <div style={{ fontSize: 14 }}><TestCaseGroupChannel group={{ channel }} />  {name}</div>
                                            </ListItemText>
                                        </MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <div style={{ fontSize: 14, marginTop: isMobile ? 16 : 0 }}>&nbsp; {t("filterBy")}</div>
                            </Typography>
                            <FormControl sx={{ m: 1, ml: 0, width: isMobile ? '100%' : '80%' }} size="small" >
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={selectedRates}
                                    onChange={handleSelectedRates}
                                    multiple
                                    renderValue={(selected) => `${selected.length} of ${rates?.length} ${t("selected")}`}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    style={{ fontSize: 14 }}
                                >

                                    <MenuItem>
                                        <Checkbox checked={checkedAllRates}
                                            onChange={handleChangeGroupRates}
                                            style={{ transform: "scale(.75)" }}
                                            sx={{
                                                color: "#1f9997",
                                                "&.Mui-checked": {
                                                    color: "#1f9997",
                                                },
                                            }}
                                        />
                                        <ListItemText primary={t("selectAll")} />
                                    </MenuItem>

                                    {rates ? rates?.map((name, index) => (
                                        <MenuItem key={name} value={index}>
                                            <Checkbox checked={selectedRates.indexOf(index) > -1}
                                                style={{ transform: "scale(.75)" }}
                                                sx={{
                                                    color: "#1f9997",
                                                    "&.Mui-checked": {
                                                        color: "#1f9997",
                                                    },
                                                }}
                                            />
                                            <ListItemText>
                                                {index == 0 ? t("rateTypes.0") : (index == 1 ? t("rateTypes.1") : (index == 2 ? t("rateTypes.2") : ''))}
                                            </ListItemText>
                                        </MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item className={classes.flex} xs={12}>
                            <div className={isMobile ? "w-full" : "w-80"}>
                                <DatePicker
                                    selected={value}
                                    onChange={(date) => setValue(date)}
                                    customInput={<ExampleCustomInput />}
                                />
                            </div>

                        </Grid>

                    </Grid>
                )}
                    
                </Grid>
                <Grid item xs={isMobile ? 12 : 8} style={isMobile ? {paddingBottom: '10px'} : {}}>
                    <TestRunResult />
                </Grid>
            </Grid>

            <br />

            <TableContainer>
                <Table
                    style={isMobile ? { tableLayout: 'fixed', overflow: 'hidden' } : {}}
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            border: "none",
                        },
                        minWidth: isMobile ? '100%' : 700
                    }} aria-label="spanning table"

                >
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            <TableCell align="left" colSpan={1}
                                onClick={orderByGroup}>
                                <span>{t("testCaseGroup.0")} <img src={sort} alt="pic" width="10px" /></span>
                            </TableCell>
                            {!isMobile ? (
                                <>
                                    <TableCell align="right" colSpan={1}
                                        onClick={()=>orderByCount(false)}>
                                        <span>{t("count")} <img src={sort} alt="pic" width="10px" /></span>
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        {t("chart")}
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell align="right"
                                    onClick={()=>orderByCount(false)}>
                                    <span>{t("count")} <img src={sort} alt="pic" width="10px" /></span>
                                </TableCell>
                            )}

                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {
                            caseGroup && (caseGroup || []).filter(c => groupName?.includes(c.name)).length > 0 ?
                                (caseGroup || []).filter(c => groupName?.includes(c.name)).map((value, index) =>
                                    <>
                                        <TableRow key={index} sx={{}}>
                                            <TableCell
                                                className={isMobile ? 'p-2' : ''}
                                                colSpan={2}
                                                sx={{
                                                    height: "120px",
                                                    width: isMobile ? 'auto' : '40%',
                                                }}
                                            >
                                                <Typography variant="subtitle1" style={{
                                                    borderRadius: "10px",
                                                    padding: "10px",
                                                    boxShadow: "0px 3px 6px #00000029",
                                                    fontFamily: "Montserrat",
                                                    display: isMobile ? "block" : "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    height: isMobile ? "60px" : "85px",
                                                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                                                }}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {(selectedRates.length == 1) ? (rate === FAIL ? <CancelIcon
                                                            style={{ color: "red", fontSize: "28px" }} /> : rate === WARN ?

                                                            <WarningAmberIcon
                                                                style={{ color: WARNCOLOR, fontSize: "28px" }} /> :
                                                            <CheckCircleOutlineIcon
                                                                style={{ color: FAILCOLOR, fontSize: "28px" }} />) : null
                                                        }

                                                        &nbsp;
                                                        <span title={value.name} className={classes.truncate}
                                                            style={isMobile ? { width: '100%', fontSize: '12px' } : {}}>
                                                            <TestCaseGroupChannel group={groupsLists?.data?.find(g => +g.id === +value.id)} />{value.name}</span>
                                                    </div>

                                                    {(selectedRates.length == 1) ? (

                                                        <span style={{ fontFamily: "Montserrat", color: +rate === +FAIL ? "red" : +rate === +WARN ? WARNCOLOR : FAILCOLOR }}>
                                                            <b >{
                                                                !isNaN(value.countsPct[rate]) && Math.round(100 * value.countsPct[rate])
                                                            }%</b>
                                                            &nbsp;
                                                            ({value.counts[rate]}/{value.testRuns})</span>
                                                    ) :
                                                        <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
                                                            {(selectedRates.indexOf(0) != -1) ? (
                                                                <div style={{ textAlign: 'center', paddingLeft: isMobile ? '10px' : '0', fontFamily: "Montserrat", color: FAILCOLOR, fontSize: isMobile ? "12px" : "18px", width: isMobile ? '30%' : '', display: isMobile ? 'flex' : 'block' }}>
                                                                    <CheckCircleOutlineIcon style={{ color: FAILCOLOR, fontSize: isMobile ? "18px" : "28px" }} />
                                                                    <br />
                                                                    <b >{
                                                                        !isNaN(value.countsPct[0]) && Math.round(100 * value.countsPct[0])
                                                                    }%</b>
                                                                    &nbsp;({value.counts[0]}/{value.testRuns})
                                                                </div>) : null}
                                                            {(selectedRates.indexOf(1) != -1) ? (
                                                                <div style={{ textAlign: 'center', fontFamily: "Montserrat", color: WARNCOLOR, fontSize: isMobile ? "12px" : "18px", width: isMobile ? '30%' : '', display: isMobile ? 'flex' : 'block' }}>
                                                                    <WarningAmberIcon style={{ color: WARNCOLOR, fontSize: isMobile ? "18px" : "28px" }} />
                                                                    <br />
                                                                    <b >{
                                                                        !isNaN(value.countsPct[1]) && Math.round(100 * value.countsPct[1])
                                                                    }%</b>
                                                                    &nbsp;({value.counts[1]}/{value.testRuns})
                                                                </div>) : null}
                                                            {(selectedRates.indexOf(2) != -1) ? (
                                                                <div style={{ textAlign: 'center', fontFamily: "Montserrat", color: "red", fontSize: isMobile ? "12px" : "18px", width: isMobile ? '30%' : '', display: isMobile ? 'flex' : 'block' }}>
                                                                    <CancelIcon style={{ color: "red", fontSize: isMobile ? "18px" : "28px" }} />
                                                                    <br />
                                                                    <b >{
                                                                        !isNaN(value.countsPct[2]) && Math.round(100 * value.countsPct[2])
                                                                    }%</b>
                                                                    &nbsp;({value.counts[2]}/{value.testRuns})
                                                                </div>) : null}
                                                        </div>
                                                    }

                                                </Typography>
                                            </TableCell>

                                            {!isMobile && (
                                                <>
                                                    <TableCell colSpan={1} />
                                                    <TableCell colSpan={3}
                                                        align="right"
                                                        sx={{
                                                            borderRadius: "20px",
                                                            height: "120px"
                                                        }}

                                                        onClick={() => showGraph(value)} style={{ cursor: "pointer", }}
                                                    >
                                                        <div id={`main_${value.id}`}
                                                            className={classes.graph}
                                                        >
                                                        </div>

                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                        {isMobile && (
                                            <TableRow>
                                                <TableCell colSpan={2}
                                                    className="p-2"
                                                    sx={{
                                                        borderRadius: "20px",
                                                        height: "120px"
                                                    }}
                                                    onClick={() => showGraph(value)} style={{ cursor: "pointer", }}>
                                                    <div id={`main_${value.id}`}
                                                        className={classes.graph}
                                                    >
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                ) :
                                <TableRow>
                                    <TableCell colSpan={5}
                                        sx={{
                                            borderRadius: "5px",
                                            border: "1px solid lightgrey",
                                            padding: "20px",
                                            boxShadow: "2px 2px lightgrey",
                                            fontFamily: "Montserrat",
                                            align: "center",
                                            fontSize: "20px"
                                        }}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                caseGroup && chooseIndex ?
                    <Modal
                        open={openGraph}
                        onClose={handleCloseGraph}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className={classes.largeGraph} style={{ outline: 0 }}>
                            <br />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    &nbsp;&nbsp;{chooseIndex.name} ({chooseIndex.testRuns})
                                    {(selectedRates.length == 1) && (
                                        <span
                                            style={{
                                                color:
                                                    rate === FAIL ? "red" : rate === WARN ? WARNCOLOR : FAILCOLOR
                                            }}>
                                            {chooseIndex.counts[rate]}&nbsp;({!isNaN(chooseIndex.countsPct[rate]) && Math.round(100 * chooseIndex.countsPct[rate])}%)
                                        </span>
                                    )}
                                </Typography>
                                <Typography style={{ cursor: "pointer" }}>
                                    <CancelIcon onClick={() => {
                                        setOpenGraph(false)
                                        setChooseIndex(null)
                                    }} />&nbsp;&nbsp;
                                </Typography>
                            </div>
                            <Typography id="main" style={{ width: isMobile ? '100vw' : "900px", height: "400px" }}
                            >
                            </Typography>
                        </Box>
                    </Modal> : null

            }

        </div>
    );
};

export default ContentDetails;
