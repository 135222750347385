import {
    Box, Button, Checkbox, Chip,
    Dialog,
    ListItemText, MenuItem, Typography,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DatePicker from "react-datepicker";
import moment from "moment";
import timeLogo from "../../../images/Group 196.svg";
import {useTranslation} from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
    disableScrollLock: true,
};
const Filter = ({ isOpen, onClose, setSelected }) => {
    const [open, setOpen] = React.useState(() => isOpen);
    const [groupName, setGroupName] = React.useState([]);
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [testGroupAll, setTestGroupAll] = useState(null)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [reportType, setReportType] = useState([])
    const [timeframe, setTimeframe] = useState([])
    const {t} = useTranslation();

    const reportTypes = useSelector(state => state.reportReducer.getReportTypes)
    const testGroups = useSelector(state => state.loginReducer.groupsList)
    const reportTimeframe = useSelector(state => state.reportReducer.getReportTimeframe)
    const reports = useSelector(state => state.reportReducer.getReports)


    const handleChangeType = (type) => {
        if (reportType.indexOf(type.name) < 0) {
            setReportType([...reportType, type.name])
        } else {
            setReportType(reportType.filter(r => r !== type.name));
        }
    };

    const handleTimeframe = (time) => {
        if (timeframe.indexOf(time) < 0) {
            setTimeframe([...timeframe, time])
        }else{
            setTimeframe(timeframe.filter(r => r !== time));
        }
    };



    useEffect(() => {
        if (testGroups) {
            const tempGroups = testGroups?.data?.map((group) => {
                return group.name
            });
            setTestGroupAll([...tempGroups])
        }

    }, [testGroups])

    useEffect(() => {

        if (checkedAll && testGroupAll) {
            let temp = testGroupAll.map((value) => value)
            setGroupName([...temp])
        }
    }, [testGroupAll]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setGroupName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeGroup = (event) => {
        setCheckedAll(event.target.checked)
        if (event.target.checked) {
            let temp = testGroupAll.map((value) => value)
            setGroupName([...temp])
        } else {
            setGroupName([])
        }
    }

    useEffect(() => {
        if (testGroupAll && groupName.length !== testGroupAll.length) {
            setCheckedAll(false)
        }
        if (testGroupAll && groupName.length === testGroupAll.length) {
            setCheckedAll(true)
        }

    }, [groupName]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleClose = () => onClose()
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])


    const reset = () => {
        setGroupName([])
        setCheckedAll(true)
        setStartDate('')
        setEndDate('')
        setReportType([])
        setTimeframe([])
        setSelected(null);
    }


    const applyFilters = () => {
        setSelected({
            reportType: reportType,
            groupName: groupName,
            startDate: startDate,
            endDate: endDate,
            timeframe: timeframe,
        })
        onClose();
    }

    const ExampleCustomInputStart = forwardRef(({ value, onClick }, ref) => (

        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "200px"
        }}>
            <div>
                <InputLabel shrink htmlFor="bootstrap-input">
                    {t("startDate.0")}
                </InputLabel>
            </div>
            <br />
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "180px",
                height: "40px",
                border: "1px solid lightgrey",
                borderRadius: "5px"
            }}>

                <Typography
                    style={{ fontSize: 14, marginRight: "10px", color: "#636364", marginTop: "5px", width: "150px" }}
                >
                    &nbsp;&nbsp;{startDate ? new moment(startDate).format('DD/MM/YYYY') : 'DD/MM/YYYY'}
                </Typography>
                <Divider sx={{ height: 40, mr: 0.5 }} orientation="vertical" />
                <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
                    style={{
                        backgroundColor: "#1F9997",
                        border: "3px solid #1F9997",
                        borderRadius: "5px",
                        width: "25px",
                        height: "25px"
                    }}
                />
            </div>
        </Box>
    ))

    const ExampleCustomInputEnd = forwardRef(({ value, onClick }, ref) => (

        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "200px"
        }}>
            <div>
                <InputLabel shrink htmlFor="bootstrap-input">
                    {t("endDate.0")}
                </InputLabel>
            </div>
            <br />
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "180px",
                height: "40px",
                border: "1px solid lightgrey",
                borderRadius: "5px"
            }}>

                <Typography
                    style={{ fontSize: 14, marginRight: "10px", color: "#636364", marginTop: "5px", width: "150px" }}
                >
                    &nbsp;&nbsp;{endDate ? new moment(endDate).format('DD/MM/YYYY') : 'DD/MM/YYYY'}
                </Typography>
                <Divider sx={{ height: 40, mr: 0.5 }} orientation="vertical" />
                <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
                    style={{
                        backgroundColor: "#1F9997",
                        border: "3px solid #1F9997",
                        borderRadius: "5px",
                        width: "25px",
                        height: "25px"
                    }}
                />
            </div>
        </Box>
    ))

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            PaperProps={{ sx: { width: "700px", height: "70%", position: "fixed", top: 220, right: 120, m: 2, p: 2, maxWidth: 'md' } }}
        >
            <Typography variant="subtitle1">
                <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
            </Typography>
            <Box sx={{ display: 'flex', marginTop: 1 }}>
                <Box>
                    <Typography variant="subtitle1">
                        <div style={{ fontSize: 14 }}>{t("reportType.0")} &nbsp;&nbsp;
                            <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {reportType.length > 0 ? `${reportType.length} SELECTED` : ''} </span>
                        </div>

                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '400px', marginTop: 1 }}>
                        {reportTypes?.data?.map((value, index) => (
                            <Chip key={index} label={value.name}
                                sx={{
                                    fontWeight: "bold",
                                    background: reportType.indexOf(value.name) > -1 ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                    borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                    cursor: 'pointer'
                                }} onClick={() => handleChangeType(value)} />
                        )
                        )}
                    </Box>
                </Box>

                <Box>
                    <Typography variant="subtitle1">
                        <div style={{ fontSize: 14 }}>{t("testCaseGroup.0")}</div>
                    </Typography>
                    <FormControl sx={{ m: 1, ml: 0, width: 220 }} size="small">
                        <InputLabel id="demo-multiple-chip-label"></InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={groupName}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected[0]}
                            MenuProps={MenuProps}
                            style={{ fontSize: 14 }}
                        >
                            <MenuItem value="select all">
                                <Checkbox checked={checkedAll}
                                    onChange={handleChangeGroup}
                                    style={{ transform: "scale(.75)" }}
                                    sx={{
                                        color: "#1f9997",
                                        "&.Mui-checked": {
                                            color: "#1f9997",
                                        },
                                    }}
                                />
                                <ListItemText primary={t("selectAll")} style={{ color: checkedAll ? "#1f9997" : "" }} />
                            </MenuItem>

                            {testGroupAll ? testGroupAll?.map((name, index) => (
                                <MenuItem key={index} value={name}>
                                    <Checkbox checked={groupName.indexOf(name) > -1}
                                        style={{ transform: "scale(.75)" }}
                                        sx={{
                                            color: "#1f9997",
                                            "&.Mui-checked": {
                                                color: "#1f9997",
                                            },
                                        }}
                                    />
                                    <ListItemText>
                                        <div style={{ fontSize: 14 }}>{name}</div>
                                    </ListItemText>
                                </MenuItem>
                            )) : null}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <br />

            <Box sx={{ display: "flex" }}>
                <Box >

                    <Typography variant="subtitle1">
                        <div style={{ fontSize: 14 }}>{t("timeFrame.0")} &nbsp;&nbsp;
                            <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {timeframe.length > 0 ? `${timeframe.length} SELECTED` : ''} </span>
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '250px' }}>
                        {reportTimeframe?.data?.map((value, index) => (
                            <Chip key={index} label={value}
                                style={{
                                    fontWeight: "bold",
                                    background: timeframe.indexOf(value) > -1 ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                    borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleTimeframe(value)}
                            />
                        )
                        )}
                    </Box>

                </Box>

                <Box sx={{ display: "flex", width: "400px", height: "80px" }}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        customInput={<ExampleCustomInputStart />}
                    />

                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        customInput={<ExampleCustomInputEnd />}
                    />
                </Box>
            </Box>

            <Divider sx={{ height: 40, mr: 0.5 }} />
            <Box textAlign='left'
                sx={{
                    minWidth: "600px",
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Button
                    sx={{ mt: 1, mb: 5, ml: 2 }}
                    style={{
                        borderRadius: 20,
                        background: '#3F929E1A 0% 0% no-repeat padding-box',
                        height: 38,
                        width: '93px',
                        color: '#1F9997',

                    }}
                    onClick={reset}
                >
                    <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "#1f9997" }}>{t("reset")}</Typography>
                </Button>

                <Button
                    sx={{ mt: 1, mb: 5, mr: 2 }}
                    style={{
                        height: 40,
                        borderRadius: 20,
                        width: 135,
                        background: '#1F9997 0% 0% no-repeat padding-box',
                    }}
                    onClick={applyFilters}
                >
                    <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "white" }}>{t("applyFilters")}</Typography>
                </Button>
            </Box>
        </Dialog>
    )
}

Filter.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default Filter;
