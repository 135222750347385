
import ContentKpis from "./rightContent/ContentKpis";
import ContentDetails from "./rightContent/ContentDetails";
import Version from "./rightContent/Version";
import {
    Box,
    Typography
} from "@mui/material";
import { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import React from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import * as echarts from "echarts";
import CaseGroup from "../../helper/CaseGroup";
import { SetChannelSelection, getDashboardData, groupsList, updateCaseGroups } from "../../actions";
import { useTranslation } from 'react-i18next';

import timeLogo from "../../images/Group 196.svg"
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { groupBy } from "lodash";
import { sumBy } from "lodash";
import { flatten, chunk } from "lodash";
import { isMobile } from 'react-device-detect';
import ContentHeader from "../mui/ContentHeader";
import ContentTopbar from "../mui/ContentTopbar";
import { CHANNELS, DASHBOARD_SELECTIONS_ITEMS } from "../../helper/constants";

const PASS = 0
const WARN = 1
const FAIL = 2
const WARNCOLOR = "#FF7D42"
const FAILCOLOR = "#4CAF50"
const getFormat = (count) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        time: `${k < 10 ? ("0" + k) : ("" + k)}` + ":00",
        count: 0,
    }));

const statusFormat = getFormat(24);

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
    },

    container: {
        paddingTop: theme.spacing(4),
        width: "100%",
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    group: {
        display: "flex",
    },


    tableHead: {
        background: "#E8F3F4 0% 0% no-repeat padding-box",
        height: 53,

        '& th': {
            fontSize: '18px',
            color: '#000000',
            fontFamily: 'Poppins',
            fontWeight: '500',
            cursor: 'pointer',

            '& span': {
                paddingLeft: '32px',
            },

            [theme.breakpoints.up('xs')]: {
                '& span': {
                    fontSize: '12px',
                    paddingLeft: '10px',
                },
            }
        },
    },
    largeGraph: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: 0,
        boxShadow: 24,
        [theme.breakpoints.up('xs')]: {
            width: isMobile ? "95%" : "50%",
        }
    },

    graph: {
        height: "85px",
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px #00000029",
        [theme.breakpoints.up('xs')]: {
            width: "100%",
        }
    },

    truncate: {
        width: '410px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        color: '#636364',
        fontSize: '1.5rem',
        paddingLeft: '9px',
    }

}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const RightContent = () => {
    
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch()
    const [testGroupAll, setTestGroupAll] = useState(null)
    const [rates, setRates] = useState(null)
    const [caseGroup, setCaseGroup] = useState(null)
    //const [originalData, setOriginalData] = useState(null)
    const [data, setData] = useState([])
    const [groupName, setGroupName] = React.useState([]);
    const [checkedAll, setCheckedAll] = React.useState(true);
    const [checkedAllRates, setCheckedAllRates] = React.useState(true);
    const [rate, setRate] = useState('')
    const [value, setValue] = useState(process.env.REACT_APP_DEFAULT_DATE ? new Date(process.env.REACT_APP_DEFAULT_DATE) : new Date())
    const [graphCount, setGraphCount] = useState(0)
    const [orderGroup, setOrderGroup] = useState(0)
    const [orderCount, setOrderCount] = useState(0)
    const [selectedRates, setSelectedRates] = useState([])
    const [topbarVisible, setTopbarVisible] = useState(false);
    const selectedChannel = useSelector(state => state.loginReducer.selectedChannel);
    const [dashboardSelection, setDashboardSelection] = useState(
        selectedChannel ? (selectedChannel === CHANNELS.VOICE ? DASHBOARD_SELECTIONS_ITEMS.VOICE : DASHBOARD_SELECTIONS_ITEMS.DIGITAL) : 
        (isMobile ? DASHBOARD_SELECTIONS_ITEMS.DIGITAL : DASHBOARD_SELECTIONS_ITEMS.VOICE)
    );

    useEffect(() => {
        groupsList()(dispatch);
    }, [])

    useEffect(() => {
        getDashboardData(moment(value).format('YYYY-MM-DD'))(dispatch);
    }, [value])

    useEffect(() => {
        SetChannelSelection(dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.VOICE ? CHANNELS.VOICE : CHANNELS.DIGITAL)(dispatch)
    }, [dashboardSelection])


    const [openGraph, setOpenGraph] = React.useState(false);
    const [chooseIndex, setChooseIndex] = useState(null)
    const groupsLists = useSelector(state => state.loginReducer.groupsList);
    const originalData = useSelector(state => state.loginReducer.originalData);

    useEffect(() => {
        if (originalData && originalData.length) {

            const group = groupBy(originalData, 'groupId');
            const gpName = [];
            const selectedChannel = dashboardSelection === DASHBOARD_SELECTIONS_ITEMS.DIGITAL ? CHANNELS.DIGITAL : CHANNELS.VOICE;
            const realData = Object.entries(group).filter(([key, value]) => value[0].channel === selectedChannel).map(([key, value]) => {
                return {
                    entityId: key,
                    entityName: value[0]?.groupName,
                    failureCount: sumBy(value, 'failureCount'),
                    successCount: sumBy(value, 'successCount'),
                    warningCount: sumBy(value, 'warningCount'),
                    entityType: 'case',
                    channel: value[0]?.channel,
                    periodEnd: value[0]?.periodEnd,
                    periodStart: value[0]?.periodStart,
                    hourlyStatus: Object.entries(groupBy(flatten(value.map(c => c.hourlyStatus)), 'hourly')).map(([key1, value1]) => {
                        return {
                            hourly: key1,
                            failureCount: sumBy(value1, 'failureCount'),
                            successCount: sumBy(value1, 'successCount'),
                            warningCount: sumBy(value1, 'warningCount'),
                        }
                    }),
                }
            });

            const caseGroups = CaseGroup.transformCaseGroups(realData);
            setCaseGroup(caseGroups);

            const tempRates = CaseGroup.statusTypes
            setRates([...tempRates])
            setSelectedRates([0, 1, 2]);
            setRate(0);

            let tempGraphData = [];
            caseGroups.forEach((group) => {
                if (group.hourlyStatus.length > 0) {
                    if (selectedRates?.length > 1) {
                        for (let i = 0; i < rates.length; i++) {
                            let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
                            if (history.length < 24) {
                                let temp = _.differenceBy(statusFormat, history, 'time');
                                let tempStatus = _.unionWith(history, temp, _.isEqual);
                                let result = _.sortBy(tempStatus, function (item) { return item.time; })
                                history = result;

                            }

                            let count = history.map(value => value.count)

                            tempGraphData.push(count)
                        }

                        setData([...tempGraphData]);
                    } else {
                        tempGraphData.push([])
                    }
                }
            })

            updateCaseGroups(caseGroups)(dispatch);
            setGroupName(groupsLists?.data?.map(g => g.name));

        }
    }, [originalData, dashboardSelection])


    const handleCloseGraph = () => setOpenGraph(false);

    useEffect(() => {

        if (checkedAll && testGroupAll) {
            let temp = testGroupAll.map((value) => value)
            setGroupName([...temp])
        }
    }, [testGroupAll]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setGroupName(
            typeof value === 'string' ? value.split(',') : value,
        );
        setOrderCount(orderCount % 2 === 0 ? 1 : 0)

    };


    useEffect(() => {
        let tempGraphData = [];
        const _caseGroup = (caseGroup || []).filter(c => groupName.includes(c.name));

        _caseGroup.forEach((group) => {
            if ((group.hourlyStatus.length > 0) && (selectedRates !== 0)) {
                for (let i = 0; i < rates.length; i++) {
                    let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
                    if (history.length < 24) {
                        let temp = _.differenceBy(statusFormat, history, 'time');
                        let tempStatus = _.unionWith(history, temp, _.isEqual);
                        let result = _.sortBy(tempStatus, function (item) { return item.time; })
                        history = result;

                    }

                    let count = history.map(value => value.count)

                    tempGraphData.push(count)
                }

                setData([...tempGraphData]);

            } else {
                tempGraphData.push([])
                setData([...tempGraphData]);
            }
        });


        updateCaseGroups(_caseGroup)(dispatch);

        orderByCount(true);
    }, [groupName, selectedRates]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeGroup = (event) => {
        console.log('hey there');
        setCheckedAll(event.target.checked);

        if (event.target.checked) {
            setGroupName(groupsLists?.data?.map((value) => value.name))
        } else {
            setGroupName([])
            setData([])
        }
    }

    useEffect(() => {
        setCheckedAll(groupName?.length === groupsLists?.data?.length);
    }, [groupsLists, groupName]);




    const handleChangeGroupRates = (event) => {
        setCheckedAllRates(event.target.checked);

        if (event.target.checked) {
            setSelectedRates([0, 1, 2])
        } else {
            setSelectedRates([])
            setData([])

        }

    }



    const handleSelectedRates = (event) => {

        const {
            target: { value },
        } = event;

        setSelectedRates(
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    useEffect(() => {

        if (selectedRates?.length == 1) {
            setRate(selectedRates[0])
        } else {
            setCheckedAllRates(selectedRates?.length > 1 && (selectedRates?.length === rates?.length))
        }

    }, [selectedRates]);


    useEffect(() => {
        if (caseGroup && caseGroup.length && data && data.length) {
            let temp = [];
            temp = chunk(data, 3);
            if (selectedRates?.length == 1) {
                if (rate == 0) {
                    for (let i = 0; i < caseGroup.length; i++) {

                        _.fill(temp[i]?.[1], 0);
                        _.fill(temp[i]?.[2], 0);
                    }
                } else if (rate == 1) {
                    for (let i = 0; i < caseGroup.length; i++) {

                        _.fill(temp[i]?.[0], 0);
                        _.fill(temp[i]?.[2], 0);
                    }

                } else if (rate == 2) {
                    for (let i = 0; i < caseGroup.length; i++) {

                        _.fill(temp[i]?.[0], 0);
                        _.fill(temp[i]?.[1], 0);
                    }

                }
            } else if ((selectedRates.length == 2) && (selectedRates.indexOf(2) == -1)) {

                for (let i = 0; i < caseGroup.length; i++) {

                    _.fill(temp[i]?.[2], 0);

                }
            } else if ((selectedRates.length == 2) && (selectedRates.indexOf(0) == -1)) {
                for (let i = 0; i < caseGroup.length; i++) {

                    _.fill(temp[i]?.[0], 0);

                }
            } else if ((selectedRates.length == 2) && (selectedRates.indexOf(1) == -1)) {
                for (let i = 0; i < caseGroup.length; i++) {

                    _.fill(temp[i]?.[1], 0);

                }
            }

            if (selectedRates?.length !== 0 && temp.length >= 1) {
                setTimeout(function () {
                    for (let i = 0; i < caseGroup.length; i++) {
                        createChart(`main_${caseGroup[i].id}`, temp[i]);

                        if (document.getElementById(`main_${caseGroup[i].id}`)) {
                            document.getElementById(`main_${caseGroup[i].id}`).style.width = '99%';

                        }
                    }
                }

                    , 1);

            } else {

                setTimeout(function () {
                    for (let i = 0; i < caseGroup.length; i++) {

                        createChart(`main_${caseGroup[i].id}`, []);

                        if (document.getElementById(`main_${caseGroup[i].id}`)) {
                            document.getElementById(`main_${caseGroup[i].id}`).style.width = '99%';

                        }
                    }
                }

                    , 1);
            }

        }

    }, [caseGroup, rate, groupName, selectedRates, data]);

    useEffect(() => {
        if (chooseIndex) {
            let tempGraphData = [];
            caseGroup.filter(cg => cg.id === chooseIndex.id).forEach((group) => {
                if ((group.hourlyStatus.length > 0) && (selectedRates.length !== 0)) {

                    for (let i = 0; i < rates.length; i++) {
                        let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
                        if (history.length < 24) {
                            let temp = _.differenceBy(statusFormat, history, 'time');
                            let tempStatus = _.unionWith(history, temp, _.isEqual);
                            let result = _.sortBy(tempStatus, function (item) { return item.time; })
                            history = result;

                        }

                        let count = history.map(value => value.count)

                        tempGraphData.push(count)
                    }

                    if (selectedRates.length == 1) {
                        switch (rate) {
                            case 0:
                                _.fill(tempGraphData[1], 0)
                                _.fill(tempGraphData[2], 0)
                                break
                            case 1:
                                _.fill(tempGraphData[0], 0)
                                _.fill(tempGraphData[2], 0)
                                break
                            case 2:
                                _.fill(tempGraphData[0], 0)
                                _.fill(tempGraphData[1], 0)

                        }

                    } else if ((selectedRates.length == 2) && (selectedRates.indexOf(2) == -1)) {

                        _.fill(tempGraphData[2], 0);

                    } else if ((selectedRates.length == 2) && (selectedRates.indexOf(0) == -1)) {

                        _.fill(tempGraphData[0], 0);

                    } else if ((selectedRates.length == 2) && (selectedRates.indexOf(1) == -1)) {

                        _.fill(tempGraphData[1], 0);

                    }

                    setTimeout(function () {
                        createChartDetail(`main`, tempGraphData);
                    }
                        , 1)

                } else {
                    tempGraphData.push([])
                }
            })

        }
    }, [chooseIndex, graphCount, groupName, selectedRates]) // eslint-disable-line react-hooks/exhaustive-deps

    function createChartDetail(divId, data) {

        let myChart = echarts.init(document.getElementById(divId));

        if (myChart == null) {

            myChart = echarts.init(document.getElementById(divId));

        }
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                backgroundColor: '#E8F3F4',
                borderColor: '#c8e2f7',
                borderRadius: '10px',
                textStyle: {
                    color: 'black',
                },
                formatter: (param) => {
                    let res = '<div style="background-color: #1F9997; margin: -10px -10px; padding: 20px; color: white">' + "Time Slot : " + param[0].name + '</div>' + '<br/>' + '<div>';
                    for (let x = 0; x < param.length; x++) {
                        res += `<div style="background-color: ${param[x].color}; height: 15px; width: 15px; display: inline-block; margin-left: 10px">` + '</div>' + '&nbsp;&nbsp;' + "Test Case Runs" + ': ' + param[x].data + '<br/>';
                    }
                    res += '</div>'
                    return res
                }
            },
            xAxis: {
                type: 'category',
                data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
                    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
            },
            yAxis: {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value} ',
                },
                interval: 1,
            },

            series: [
                {
                    name: 'Pass',
                    type: 'bar',
                    itemStyle: {
                        color: FAILCOLOR
                    },
                    stack: 'total',
                    data: data?.[0],
                },
                {
                    name: "Warning",
                    type: 'bar',
                    itemStyle: {
                        color: WARNCOLOR
                    },
                    stack: 'total',
                    data: data[1],
                },
                {
                    name: "Fail",
                    type: 'bar',
                    itemStyle: {
                        color: 'red'
                    },
                    stack: 'total',
                    data: data[2],
                }
            ]
        };
        myChart.clear();
        myChart.setOption(option);
    }

    function createChart(divId, data) {
        if (!document.getElementById(divId)) {
            return;
        }

        let myChart = echarts.init(document.getElementById(divId));

        if (myChart == null) {

            myChart = echarts.init(document.getElementById(divId));

        }
        let width = 0.99 * document.getElementById(divId).offsetWidth;

        let option = {
            width: 'auto',
            xAxis: {
                show: false,
                type: 'category',
                data: ['0', "", "", "", "", "", "6", "", "", "", "", "", "12", "", "", "", "", "", "18", "", "", "", "", '', "24"]
            },
            yAxis: {
                type: 'value',
                position: 'left',
                show: false,
                axisLabel: {
                    formatter: '{value} ',
                }
            },

            series: [
                {
                    name: 'Pass',
                    type: 'bar',
                    itemStyle: {
                        color: FAILCOLOR
                    },
                    stack: 'total',
                    data: data?.[0],
                },
                {
                    name: "Warning",
                    type: 'bar',
                    itemStyle: {
                        color: WARNCOLOR
                    },
                    stack: 'total',
                    data: data?.[1],
                },
                {
                    name: "Fail",
                    type: 'bar',
                    itemStyle: {
                        color: 'red'
                    },
                    stack: 'total',
                    data: data?.[2],
                }
            ]
        };
        myChart.resize({ width: width });
        myChart.clear();
        myChart.setOption(option);
    }


    const showGraph = (index) => {
        setOpenGraph(true)
        setChooseIndex(index)
        setGraphCount(graphCount + 1)
    }

    const orderByGroup = () => {
        let tempCaseGroup = _.cloneDeep(caseGroup)

        if (orderGroup % 2) {
            tempCaseGroup.sort((a, b) => {
                if (a.name > b.name) {
                    return -1;
                }
                if (a.name < b.name) {
                    return 1;
                }
                return 0;

            })
        } else {
            tempCaseGroup.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        }
        setCaseGroup(tempCaseGroup)
        let tempGraphData = []
        tempCaseGroup?.forEach((group) => {
            if (group.hourlyStatus.length > 0) {
                for (let i = 0; i < rates?.length; i++) {
                    let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
                    if (history.length < 24) {
                        let temp = _.differenceBy(statusFormat, history, 'time');
                        let tempStatus = _.unionWith(history, temp, _.isEqual);
                        let result = _.sortBy(tempStatus, function (item) { return item.time; })
                        history = result;

                    }

                    let count = history.map(value => value.count)

                    tempGraphData.push(count)
                }

                setData([...tempGraphData]);

            } else {
                tempGraphData.push([],[],[])
                setData([...tempGraphData]);
            }

        })

        setOrderGroup(orderGroup + 1)
    }

    const orderByCount = (oc = false) => {
        let tempCaseGroup = _.cloneDeep(caseGroup)

        if (!tempCaseGroup) {
            return;
        }
        if (!oc && orderCount % 2) {
            tempCaseGroup.sort((a, b) => {
                if (a.perCounts[rate] < b.perCounts[rate]) {
                    return -1;
                }
                if (a.perCounts[rate] > b.perCounts[rate]) {
                    return 1;
                }
                return 0;

            })
        } else {
            tempCaseGroup.sort((a, b) => {
                if (a.perCounts[rate] > b.perCounts[rate]) {
                    return -1;
                }
                if (a.perCounts[rate] < b.perCounts[rate]) {
                    return 1;
                }
                return 0;
            })
        }

        setCaseGroup(tempCaseGroup)
        let tempGraphData = []
        tempCaseGroup?.forEach((group) => {
            if (group.hourlyStatus.length > 0) {
                for (let i = 0; i < rates?.length; i++) {
                    let history = group.statusHistory(group.hourlyStatus[i]?.hourly, i);
                    if (history.length < 24) {
                        let temp = _.differenceBy(statusFormat, history, 'time');
                        let tempStatus = _.unionWith(history, temp, _.isEqual);
                        let result = _.sortBy(tempStatus, function (item) { return item.time; })
                        history = result;

                    }

                    let count = history.map(value => value.count)

                    tempGraphData.push(count)
                }

                setData([...tempGraphData]);

            } else {
                tempGraphData.push([],[],[])
                setData([...tempGraphData]);
            }

        })

        setOrderCount(orderCount + 1)
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

        <Box className="date-picker-dashboard">
            <Typography variant="subtitle1">
                <div style={{ fontSize: 14, marginTop: isMobile ? 16 : 0 }}>&nbsp; {t("selectDate")}</div>
            </Typography>
            <div className="border">
                <div>
                    <Typography
                        style={{ fontSize: 14 }}
                    >
                        {new moment(value).format('MMM DD, YYYY')}
                    </Typography>
                </div>
                <img src={timeLogo} alt="Select Day" onClick={onClick} ref={ref}
                    style={{
                        backgroundColor: "#1F9997",
                        border: "3px solid #1F9997",
                        borderRadius: "5px",
                        width: "33px",
                        height: "33px"
                    }}
                />
            </div>
        </Box>
    ));
      
    return (
        <>
        {!isMobile && (
            <ContentTopbar
            dashboardSelection={dashboardSelection}
            setDashboardSelection={setDashboardSelection}
            classes={classes}
            rates={rates}
            groupName={groupName}
            checkedAll={checkedAll}
            checkedAllRates={checkedAllRates}
            handleChangeGroupRates={handleChangeGroupRates}
            value={value}
            setValue={setValue}
            selectedRates={selectedRates}
            handleSelectedRates={handleSelectedRates}
            groupsLists={groupsLists}
            handleChange={handleChange}
            handleChangeGroup={handleChangeGroup}
            ExampleCustomInput={ExampleCustomInput}
            topbarVisible={topbarVisible}
            setTopbarVisible={setTopbarVisible}
         />
        )}        
        
        <div style={isMobile ? {paddingBottom:'65px', position: "absolute", top: "0px", left: "10px", right:'10px'} : { position: "absolute", top: "0px", left: "100px", width: `calc(100% - 130px)` }}>
            <ContentHeader hidden={true}/>            
            <ContentKpis dashboardSelection={dashboardSelection} setDashboardSelection={setDashboardSelection}/>
            <ContentDetails 
                classes={classes}
                rates={rates}
                caseGroup={caseGroup}
                groupName={groupName}
                checkedAll={checkedAll}
                checkedAllRates={checkedAllRates}
                handleChangeGroupRates={handleChangeGroupRates}
                rate={rate}
                value={value}
                setValue={setValue}
                selectedRates={selectedRates}
                handleSelectedRates={handleSelectedRates}
                openGraph={openGraph}
                setOpenGraph={setOpenGraph}
                chooseIndex={chooseIndex}
                setChooseIndex={setChooseIndex}
                groupsLists={groupsLists}
                handleChange={handleChange}
                handleChangeGroup={handleChangeGroup}
                orderByGroup={orderByGroup}
                handleCloseGraph={handleCloseGraph}
                ExampleCustomInput={ExampleCustomInput}
                orderByCount={orderByCount}
                WARN={WARN}
                WARNCOLOR={WARNCOLOR}
                FAIL={FAIL}
                FAILCOLOR={FAILCOLOR}
                showGraph={showGraph}
                topbarVisible={topbarVisible}
            />
            <Version />
        </div>
        </>
    );
};

export default RightContent;
