import Navbar from "../../dashboard/Navbar";
import {Grid} from "@mui/material";
import LeftBar from "../../dashboard/LeftBar";
import React from "react";
import AddReportContent from "./AddReportContent";

const ReportAdd = () => {

    const defaultPage = [0, 1, 0, 0]
    return (
        <div>
            <Navbar/>
            <Grid container>

                <Grid item>
                    <LeftBar page={defaultPage}/>
                </Grid>
                <Grid item>
                    <AddReportContent/>
                </Grid>
            </Grid>
        </div>
    )
}

export default ReportAdd