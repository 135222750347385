import { LensActions } from "../actions";

export const reportReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LensActions.GetSettingsReports:
            return {
                ...state,
                getSettingsReports: action.payload
            }
        case LensActions.ClearSettingsReports:
            return {
                ...state,
                getSettingsReports: []
            }
        case LensActions.GetReports:
            return {
                ...state,
                getReports: action.payload
            }
        case LensActions.GetReportTypes:
            return {
                ...state,
                getReportTypes: action.payload
            }
        case LensActions.CreateReport:
            return {
                ...state,
                createReport: action.payload
            }
        case LensActions.GetOneReport:
            return {
                ...state,
                getOneReport: action.payload
            }
	case LensActions.GetMosReport:
            return {
                ...state,
                getMosReport: action.payload
            }
        case LensActions.EditReport:
            return {
                ...state,
                editReport: action.payload
            }
        case LensActions.GetReportTimeframe:
            return {
                ...state,
                getReportTimeframe: action.payload
            }
        case LensActions.ViewReport:
            return {
                ...state,
                viewReport: action.payload
            }
        case LensActions.CurrentPlayer:
            return {
                ...state,
                currentPlayer: action.payload
            }
        case LensActions.CurrentPlayerStatus:
            return {
                ...state,
                currentPlayerStatus: action.payload
            }
        case LensActions.SetReport:
            return {
                ...state,
                selectedReport: action.payload
            }
        case LensActions.ViewTestResult:
            return {
                ...state,
                viewTestResult: action.payload
            }
        case LensActions.GetIvrs:
            return {
                ...state,
                ivrs: action.payload
            }
        case LensActions.SetActiveGroup:
            return {
                ...state,
                currentGroup: action.payload
            }
        case LensActions.SetChannels:
            return {
                ...state,
                channels: action.payload
            }
         case LensActions.SetMos:
            return {
                ...state,
                mos: action.payload
            }
		case LensActions.SetMosRuns:
            return {
                ...state,
                mosRuns: action.payload
            }
        case LensActions.SetDistribution:
            return {
                ...state,
                currentDistribution: action.payload
            }
        case LensActions.SetTestCaseGroup:
            return {
                ...state,
                testCaseGroup: action.payload
            }
        case LensActions.SetAlertSetting:
            return {
                ...state,
                alertSetting: action.payload
            }
        case LensActions.SetDistributions:
            return {
                ...state,
                distributions: action.payload
            }
        case LensActions.SetAlertStatuses:
            return {
                ...state,
                alertStatuses: action.payload
            }
        case LensActions.GetTestRunDetails:
            return {
                ...state,
                testRunDetails: action.payload
            }
        case LensActions.GetRuns:
            return {
                ...state,
                runs: action.payload
            }
        case LensActions.SetAlerts:
            return {
                ...state,
                alerts: action.payload
            }
        case LensActions.SetAlertDetails:
            return {
                ...state,
                alertDetails: action.payload
            }
        case LensActions.ShowGlobalAlert:
            return {
                ...state,
                globalAlert: action.payload
            }
        case LensActions.ShowGlobalErrorAlert:
            return {
                ...state,
                globalErrorAlert: action.payload
            }
        case LensActions.SetLatency:
            return {
                ...state,
                latency: action.payload
            }
        case LensActions.SetLatencyTestRuns:
            return {
                ...state,
                latencyTestRuns: action.payload
            }
        case LensActions.SetUserInfo:
            return {
                ...state,
                userInfoDetail: action.payload
            }
        case LensActions.SetDistributionTypes:
            return {
                ...state,
                distributionTypes: action.payload
            }
        default:
            return state;
    }
};

const defaultState = {
    getReports: null,
    getReportTypes: null,
    createReport: null,
    getOneReport: null,
    getMosReport: null,
    editReport: null,
    getReportTimeframe: null,
    viewReport: null,
    viewTestResult: null,
    currentPlayer: 1,
    currentPlayerStatus: 'PAUSE',
};
