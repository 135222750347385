import { Box, Button, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import ContentHeader from "../../mui/ContentHeader";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import trashCan from "../../../images/Delete Action Icon Component.svg";
import plus from "../../../images/plus.svg";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmEmailPhone, getDistributionById, getDistributionTypes, toggleGlobalAlert, upsertDistribution } from "../../../actions";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
    listLabel: yup.string().required("Required").trim(),
    label: yup.string().required("Required").trim(),
    type: yup.string().required("Required").trim(),
    email: yup.string().required("Required").trim(),
});

const EditContent = () => {
    const dispatch = useDispatch();
    let history = useHistory()
    const currentDistribution = useSelector(state => state.reportReducer.currentDistribution);
    const distributionTypes = useSelector(state => state.reportReducer.distributionTypes);
    const { t } = useTranslation();

    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        watch,
        control,
        getValues,
    } = useForm({
        //resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: { listLabel: "", label: '' }
    });

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: "dist"
    });

    const watchFieldArray = watch("dist");

    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });


    const addRow = () => {
        append({ listLabel: '', type: '1', email: '', label: '' });
    }

    useEffect(() => {
        if (currentDistribution) {
            replace(sortBy(currentDistribution.contacts.map(c => ({
                listLabel: currentDistribution.name,
                type: c.type,
                email: c.info,
                label: c.name,
                typeName: distributionTypes.find(dt => dt.id === +c.type)?.name,
            })), 'typeName'))
        }
    }, [currentDistribution])

    useEffect(() => {
        getDistributionTypes()(dispatch)
        if (id > 0) {
            getDistributionById(id)(dispatch);
        } else {
            replace({ listLabel: '', type: '1', email: '', label: '' });
        }
    }, [id])

    const handleSaveSubmit = async (data) => {
        let model = {
            name: data.dist[0].listLabel,
            contacts: data.dist.map(c => ({
                name: c.label,
                type: c.type,
                info: c.email,
            })),
        }

        if (id > 0) {
            model = {
                ...model,
                id,
            }
        };
        const result = await upsertDistribution(model, +id)(dispatch);

        if (result) {
            history.push('/settings/distribution-list/3');
        }
    }

    const confirmEntry = async (index) => {
        const content = getValues(`dist.${index}.email`);
        const type = getValues(`dist.${index}.type`)
        if (!content) {
            return;
        }
        

        switch (+type) {
            case 1://email
                {
                    const result = await confirmEmailPhone(content, '')(dispatch);
                    if (result && result?.code === "success") {
                        toggleGlobalAlert(' Email has been confirmed.')(dispatch)
                    }
                    break;
                }
            case 2://pager
            case 4://text
                {
                    const result = await confirmEmailPhone('', content)(dispatch);
                    if (result && result?.code === "success") {
                        toggleGlobalAlert(' Text/SMS has been confirmed.')(dispatch)
                    }
                    break;
                }
        }

    }

    return (

        <div style={{ position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={`${t("settingsDistributionList")} / ${id > 0 ? t("edit.1") : t("create.1")} ${t("distributionList.1")}`} />

            <Box textAlign='left'
                sx={{
                    minWidth: "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '5px',
                    verticalAlign: 'middle'
                }}
            >
                <Typography type="body2" className="edit-title">
                    {id > 0 ? t("edit.0") : t("create.0")} {t("distributionList.0")}
                </Typography>

            </Box>
            <form onSubmit={handleSubmit(handleSaveSubmit)}
            >
                {controlledFields.map((item, index) => (
                    <Box style={{ display: "flex", flexDirection: "row", marginTop: 40 }}
                        key={item.id}>
                        <div style={{ width: "20%" }}>
                            {index === 0 && (
                                <Controller
                                    render={({ field }) => (
                                        <TextField size="small" label={t("distributionListLabel.1")}
                                            {...field} value={field.value}

                                        />
                                    )}
                                    control={control}
                                    name={`dist.${index}.listLabel`}
                                />

                            )}
                        </div>

                        <div style={{ width: "20%", marginLeft: '30px' }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField size="small" label={t("label.1")}
                                        {...field} value={field.value}

                                    />
                                )}
                                control={control}
                                name={`dist.${index}.label`}
                            />
                        </div>

                        <div style={{ width: "20%", marginLeft: '30px' }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField size="small" fullWidth label="Type*"
                                        select defaultValue={item.type}
                                        {...field} value={field.value}

                                    >
                                        {sortBy((distributionTypes || []), 'name').map(d => (
                                            <MenuItem key={`type_${d.id}`} value={d.id}>
                                                {d.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                control={control}
                                name={`dist.${index}.type`}
                            />
                        </div>

                        <div style={{ width: "20%", marginLeft: '30px' }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField type={+item.type === 1 ? "email" : "tel"} size="small" label={+item.type === 1 ? t("email*") : t("number*")}
                                        {...field} value={field.value}

                                    />
                                )}
                                control={control}
                                name={`dist.${index}.email`}
                            />
                        </div>
                        <div style={{ width: "20%", marginLeft: '30px' }}>
                            <Button sx={{ ml: 2 }} variant="contained" color="primary" className="round-button" onClick={() => confirmEntry(index)} >Confirm</Button>
                        </div>
                        {fields.length > 1 && (
                            <div className="delete-form">
                                <IconButton color="error" component="span" size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        remove(index)

                                    }}
                                >
                                    <img src={trashCan} alt="pic" width="30px" />
                                </IconButton>
                            </div>
                        )}
                    </Box>
                ))}
                <Box style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "270px" }}></div>
                    <div style={{ width: "270px", marginTop: '20px', display: 'flex' }}>
                        <img width={24} src={plus} alt="" className="pointer" onClick={addRow} /><a onClick={addRow} style={{
                            color: '#E56C35', textTransform: 'capitalize', fontSize: 17, fontWeight: 400,
                            letterSpacing: 0, cursor: 'pointer', paddingLeft: '4px'
                        }}>{t("add")}</a>

                    </div>
                </Box>
                <Box textAlign='left'
                    sx={{
                        minWidth: "500px",
                        borderRadius: '5px',
                        borderWidth: '0',
                        borderStyle: 'solid',
                        backgroundColor: '#E8F3F4',
                        marginTop: '160px',
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 1, mb: 1, ml: 2 }}
                        style={{
                            borderRadius: 7,
                            backgroundColor: "#1f9997",
                            fontSize: "14px",
                            width: "190px",
                            height: 40
                        }}
                    >
                        <Typography style={{ fontFamily: 'Poppins', fontSize: 14, textTransform: 'none', color: "white" }}>{t("save")}</Typography>
                    </Button>

                    <Button

                    >
                        <Typography style={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 600, textTransform: 'none', color: "#BDBDBD" }}
                            onClick={() => history.push('/settings/distribution-list/3')} >{t("cancel")}</Typography>
                    </Button>
                </Box>
            </form>


        </div >
    )
}

export default EditContent;
