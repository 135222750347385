import LanguageIcon from '@mui/icons-material/Language';
import fi_rs_phone_call from "../../../images/fi-rs-phone-call.svg";
import { CHANNELS } from '../../../helper/constants';

export const TestCaseGroupChannel = ({ group = null }) => {

    if (!group || !group.channel) {
        return null;
    }
    
    return (
        <>
            {group?.channel === CHANNELS.VOICE ? (
                <img src={fi_rs_phone_call} style={{marginRight: '8px' }}  width='18px'/>
            ) : (
                <LanguageIcon sx={{ fontSize: 18, mr: 1 }} />
            )}
        </>
    )
}