import {Alert, Box, Modal} from "@mui/material";
import React from "react";

const Popup = ({message, open}) => {
    return (
        <div>
            <Modal
                open={open}
                id="timeModal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <br/>
                    <br/>
                    <Alert severity="error">{message}</Alert>
                </Box>
            </Modal>
        </div>
    );
};

export default Popup;
